import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgClass } from '@angular/common';
import { mArtikel } from '../../../../../models/data/artikel';
import { mArtikelPrijs } from '../../../../../models/prijs/martikelprijs';
import { LoginService } from '../../../../../services/login.service';
import { ArtikelService } from '../../../../../services/artikel.service';
import { BasketService } from '../../../../../services/basket.service';
import { FavorietenService } from '../../../../../services/favorieten.service';
import { mFavoriet } from '../../../../../models/favoriet/mfavoriet';
import { LanguageService } from '../../../../../services/language.service';
import { Language } from '../../../../../models/enum';

@Component({
  selector: 'app-artikelcontent',
  templateUrl: './artikelcontent.component.html',
  styleUrls: ['./artikelcontent.component.css']
})
export class ArtikelcontentComponent implements OnInit {

  @Input() ArtikelPrijs: mArtikelPrijs;
  @Output() onClicked = new EventEmitter<mArtikelPrijs>();

  constructor(private loginService: LoginService, private artikelService: ArtikelService, private basketService: BasketService,
    private favorietenService: FavorietenService, private languageService: LanguageService) { }

  Aantal: number = 1;

  get Prijs(): string {
    if (this.ArtikelPrijs.Prijs != undefined)
      return this.ArtikelPrijs.Prijs.Prijs.toString();

    return "N/A";
  }
  get Korting1(): string {
    if (this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Korting1 != undefined && this.ArtikelPrijs.Prijs.Korting1 > 0)
      return this.ArtikelPrijs.Prijs.Korting1.toString();

    return "N/A";
  }
  get Korting2(): string {
    if (this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Korting2 != undefined && this.ArtikelPrijs.Prijs.Korting2 > 0)
      return this.ArtikelPrijs.Prijs.Korting2.toString();

    return "N/A";
  }
  get Aantal1(): string {
    if (this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Aantal1 != undefined && this.ArtikelPrijs.Prijs.Aantal1 > 0)
      return this.ArtikelPrijs.Prijs.Aantal1.toString();

    return "N/A";
  }
  get Aantal2(): string {
    if (this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Aantal2 != undefined && this.ArtikelPrijs.Prijs.Aantal2 > 0)
      return this.ArtikelPrijs.Prijs.Aantal2.toString();

    return "N/A";
  }
  get showprijs() {
    if (this.ArtikelPrijs.Prijs != undefined)
      return true;

    return false;
  }
  get showprijsopaanvraag(){
    if (this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Prijs == 0)
      return true;

    return false;
  }
  get showkorting1() {
    if (this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Korting1 != undefined && this.ArtikelPrijs.Prijs.Korting1 > 0)
      return true;

    return false;
  }
  get showkorting2() {
    if (this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Korting2 != undefined && this.ArtikelPrijs.Prijs.Korting2 > 0)
      return true;

    return false;
  }
  get loadingPrices() {
    if (this.loginService.loggedon && !this.artikelService.loaded)
      return true;

    return false;
  }
  ishiddenprice(){
    return this.showprijs || this.showprijsopaanvraag;
  }
  ngOnInit() {
  }
  onClick(Artikel: mArtikelPrijs) {
    this.onClicked.emit(Artikel);
  }
  promo() {
    if (this.ArtikelPrijs != undefined && this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Promo != undefined && this.ArtikelPrijs.Prijs.Promo == true)
      return false;
    return true;
  }
  getpromo() {
    if (this.ArtikelPrijs != undefined && this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Promo != undefined && this.ArtikelPrijs.Prijs.Promo == true)
      return "headerheight pricing-title background-red text-white";
    else
      return "headerheight pricing-title euhm";
  }
  getPrijsPromo() {
    if (this.ArtikelPrijs != undefined && this.ArtikelPrijs.Prijs != undefined && this.ArtikelPrijs.Prijs.Promo != undefined && this.ArtikelPrijs.Prijs.Promo == true)
      return "dnone";
    else
      return "pricing-price text-size-16 text-green";

  }

  onBasket() {
    this.basketService.AddToBasket(this.ArtikelPrijs, this.Aantal);
  }
  onFav() {
    if (this.ArtikelPrijs.Favoriet == undefined) {
      this.ArtikelPrijs.Favoriet = new mFavoriet(this.ArtikelPrijs.Artikel.Nr, 1);
      this.favorietenService.AddFavoriet(this.ArtikelPrijs);
    }
    else{
      this.ArtikelPrijs.Favoriet = undefined
      this.favorietenService.DeleteFavoriet(this.ArtikelPrijs);
    }
  }
  onInfo() {
    if (!this.loginService.loggedon)
    {
      document.getElementById('popuphover').style.display = 'inline';
      window.scrollTo(0, 0);      
    }
  }
  getClassFav() {
    if (this.ArtikelPrijs.Favoriet != undefined)
      return "button border-radius background-yellow text-blue margin-left icon-sli-star";
    else
      return "button border-radius background-green text-white margin-left icon-sli-star";

  }
  translateOMS(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.OmschrijvijngNL;
    else
      return menu.Artikel.OmschrijvijngFR;
  } 
  translateGroep(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.GroepNL;
    else
      return menu.Artikel.GroepFR;
  } 
  translateInhoud(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.Inhoud;
    else
      return menu.Artikel.InhoudFR;
  } 
  translateString(val: string)
  {
    return this.languageService.translate(val);
  }
}
