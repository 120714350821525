<!-- Midden -->
<main role="main">
  <section>
    <article>
      <header class="section background-green-hightlight background-transparent text-center background-blue"
        data-parallax="scroll" data-image-src="../../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-40 text-line-height-1 text-thin">
          Nouveau dans notre gamme !
        </h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <div class="line">
            <!-- Catalogus -->

            <div class="line">
              <p class="icon-home text-blue">
                <a routerLink="/FR/home" target="_self"> Home</a>
              </p>
              <h2 class="text-size-30 text-s-size-12">
                <em>Check this out!</em>
              </h2>
              <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
                <a routerLink="/FR/fabrikantlinks" target="_self">
                  <strong>
                    <em> Voici un aperçu de tous nos fabricants</em>
                  </strong>
                </a>
              </h4>
              <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
                <a routerLink="/FR/fabrikantecatalogus" target="_self">
                  <strong>
                    <em> e-catalogue / pdf</em>
                  </strong>
                </a>
              </h4>
              <hr class="break break-small background-primary" />
            </div>
          </div>
          <div class="line">
            <div class="margin">
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5><strong>Aligner Flow Lc&nbsp; </strong><br>VOCO<br></h5>
                      <p>Avec AlignerFlow LC, VOCO vous propose le composite nanohybride avec effet de fluorescence
                        conçu spécifiquement pour répondre à ces impératifs. Il est possible de contrôler les taquets à
                        la lumière UV-A ou d’identifier avec précision d’éventuels résidus lors de leur retrait et de
                        les éliminer tout en préservant l’émail. Disponible dans les teintes dentaires classiques,
                        AlignerFlow LC satisfait également aux exigences esthétiques liées au traitement par
                        alignement.&nbsp;</p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-01.jpg" alt="Aligner Flow LC de Voco"
                    title="Aligner Flow LC" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5><strong>Filtek Easy Match</strong><br>3M<br></h5>
                      <p>Est un système de choix de teinte simplifié conçu pour rationaliser les restaurations tout en
                        vous offrant la flexibilité dont vous avez besoin pour trouver la meilleure correspondance de
                        teinte.</p>
                      <p>- choix de teinte intuitif<br>
                        - une opacité qui s'adapte naturellement<br>
                        - réduction des stocks<br>
                        - Teintes: natural, bright ou warm<br>
                      </p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-02.jpg" alt="Filtek Easy Match de 3M"
                    title="Filtek Easy Match" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5><strong>O7 active
                        </strong><br>GUM<br></h5>
                      <p>- Le même familier Oxygène actif technologie<br>
                        Maintenant avec un léger goût de menthe</p>
                      <br>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-03.jpg" alt="O7 active de Gum" title="O7 active" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5>Bite &amp; White Shade correction<br>
                        Cavex<br>
                      </h5>
                      <p>Cavex Bite&amp;White Shade correction est un système rapide, sûr et efficace pour restaurer la
                        couleur naturelle des dents. Il s'agit d'un système In-Office = toute la procédure est effectuée
                        par le dentiste au cabinet. Le gel révolutionnaire du système permet de restaurer la couleur
                        d'origine des dents avec seulement 2 à 3 applications en 1 traitement.</p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-04.jpg" alt="White Shade correction de Cavex"
                    title="White Shade correction" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5>Instruments de Luxation <br>CARL MARTIN<br></h5>
                      <p><strong>&nbsp;</strong>&nbsp;TOUT EST BIEN EN MAIN ...<br>
                        ... avec la nouvelle technique de prise Pad3. Des creux spéciales pour les doigts permettent une
                        haptique optimisée, tactilité et direction sure de l'instrument à un effort réduit. UNE
                        MEILLEURE TECHNOLOGIE POUR UN MEILLEUR TRAVAIL.</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-05.jpg" alt="Instruments de Luxation de Carl Martin"
                    title="Instrumentens de Luxation" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5>Venus Pure Shades<br>KULZER<br></h5>
                      <p>Venus Pure Diamond propose une large gamme de teintes et peut être appliqué en monocouche ou
                        multicouche (stratification).<br></p>
                      <p>Les nouvelles teintes couvrent environ 90% des restaurations antérieures et postérieures avec
                        seulement 4 nuances.<br>
                        - Venus Pure Diamond: ferme, peu collant<br>
                        - Venus Pure Pearl: crémeux, peu collant&nbsp; &nbsp;&nbsp;</p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-06.jpg" alt="Venus Pure shades de Kulzer"
                    title="Venus Pure shades" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5><strong>Transcend</strong><br>ULTRADENT<br></h5>
                      <p>Le composite universel Transcend permet une correspondance des teintes sans précédent avec une
                        seule teinte Universal Body, grâce à sa technologie brevetée Resin Particle Match qui élimine la
                        nécessité d'une teinte bloquante ou d'un opaquer.</p>
                      <p>Si vous préférez la technique de reconstitution par stratification, Transcend propose également
                        4 teintes dentine et 2 teintes émail.&nbsp;</p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-07.jpg" alt="Transcend de Ultradent" title="Transcend" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5><strong>ValoX&nbsp; &nbsp;
                        </strong><br>ULTRADENT<br></h5>
                      <p>La lampe ValoX est la lampe à polymériser revisitée pour révolutionner le monde des lampes à
                        polymérisation. Reconstruite de zéro pour créer la lampe à polymériser la plus innovante sur le
                        marché</p>
                      <p>-Lentille plus grosse: 12.5 mm<br>
                        -Plusieurs configurations<br>
                        -Interface simplifiée
                      </p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-08.jpg" alt="ValoX de Ultradent" title="ValoX" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5><strong>Gum Soft-picks Pro</strong><br>GUM<br></h5>
                      <p><strong>Nettoyage +50% efficace.&nbsp;</strong></p>
                      <p>- Facile à insérer et à glisser entre les dents<br>
                        - Ultra doux et très facile à utiliser<br>
                        - Gum soft-picks Pro a maintenant une tête repensée avec 152 brins en caoutchouc pour éliminer
                        plus de résidus alimentaires et de plaque dentaire.&nbsp; </p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-09.jpg" alt="Soft-picks Pro de Gum"
                    title="Soft-picks Pro" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                      <h5><strong>Brilliant Bulk Fill Flow</strong><br>COLTENE<br></h5>
                      <p>Est le matériau d'obturation idéal pour des restaurations faciles et rapides. Resposant sur la
                        technologie de charges Brilliant, il est le partenaire parfait pour Brilliant Everglow et
                        Everglow Flow.<br>
                      </p>
                      <p>- pas de couche supérieure nécessaire</p>
                      <p>- profondeur de polymérisation fiable de 4 mm</p>
                      <p>- un matériau adaptatif Multi Shade &nbsp;</p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-10.jpg" alt="Brilliant Bulk Fill Flow de Coltene"
                    title="Brilliant Bulk Fill Flow" />
                </div>
              </div>
              <div class="s-12 m-6 l-4">
                <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                  <div class="image-hover-overlay background-primary">
                    <div class="image-hover-overlay-content text-center padding-2x">
                      <h5><strong>Brilliant Lumina&nbsp;</strong><br>COLTENE<br></h5>
                      <p>Avec BRILLIANT Lumina, vous disposez d'une solution en cabinet innovante pour le blanchiment
                        dentaire, la formule étant, pour la première fois, sans peroxyde d'hydrogène ni peroxyde de
                        carbamide. En effet, BRILLIANT Lumina est basé sur la substance active PAP (acide
                        phtalimidoperoxycaproïque).<br></p>
                      <p>- aucune sensibilité des dents ne se manifeste pendant ou après le traitement<br></p>
                      <p>- respect des tissus dentaires durs&nbsp;</p>
                    </div>
                  </div>
                  <img src="../../../assets/images/Gallery/thumb-11.jpg" alt="Brilliant Lumina de Coltene"
                    title="Brilliant Lumina" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</main>
