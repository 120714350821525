export enum TypeResult {
    Artikel,
    Groep,
    HoofdGroep,
    SuperGroep,
    None
}
export enum TypeKlant{
    Geen,
    Tandarts,
    Labo,
    Beide
}
export enum Language{
    NL,
    FR
}
export enum eMenuType{
  geen,
  artikel,
  groep,
  hoofdgroep,
  supergroep,
  singleartikel
}
