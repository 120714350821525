import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-kortingen',
  templateUrl: '../../../assets/html/kortingen.html',
  styleUrls: ['./kortingen.component.css']
})
export class KortingenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }

}
