import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../services/login.service';
import { BasketService } from '../../../../services/basket.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {

  constructor(private loginService: LoginService, private basketService: BasketService, private languageService: LanguageService) {
    basketService.BasketChanged.subscribe((newBool: boolean) => { this.TempVis(newBool); });
  }


  ngOnInit() {
  }
  get NotLogged(): boolean {
    return this.loginService.login == undefined || this.loginService.login.Succes == undefined || this.loginService.login.Succes == false;
  }

  get AantalItems() {
    if (this.basketService.BasketItems != undefined && this.basketService.BasketItems.length > 0)
      return this.basketService.BasketItems.length.toString();

    return "0";
  }

  TempVis(vis: boolean) {
    if (vis) {
      document.getElementById('tooltipwinkel')!.style.visibility = "visible";

      setTimeout(function () {
        document.getElementById('tooltipwinkel')!.style.visibility = "hidden";
      }, 1200);

      this.basketService.BasketChangeTrigger(false);
    }
  }

  translate(val: string) {
    return this.languageService.translate(val);
  }
}
