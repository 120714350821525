<div class="table padtop20" id="results">
  <div class='theader'>
    <div class='table_header dis'>{{translateString('Artikel')}}</div>
    <div class='table_header'>{{translateString('Omschrijving')}}</div>
    <div class='table_header'>{{translateString('Merk')}}</div>
    <div class='table_header dis'>{{translateString('Prod.Nr')}}</div>
    <div class='table_header dis'>{{translateString('Art.Nr')}}</div>
    <div class='table_header'>{{translateString('Inhoud')}}</div>
    <div class='table_header'>{{translateString('Prijs')}}</div>
    <div class='table_header'>{{translateString('Aantal')}}</div>
    <div class='table_header'>{{translateString('Bestel')}}</div>
    <div class='table_header'>{{translateString('Favoriet')}}</div>
  </div>
  <div *ngFor="let artikel of Artikels" [ngClass]="getClassHidden(artikel)">
    <div class='table_small dis' >
      <div class='table_cell'>{{translateString('Artikel')}}</div>
      <div class='table_cell'>
        <img class="mini" [src]="artikel.Artikel.FotoUrl" [alt]="artikel.Artikel.OmschrijvijngNL">
      </div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translateString('Omschrijving')}}</div>
      <div class='table_cell'>
        <a class="point" (click)="onArtikelZoom(artikel)">{{translateOMS(artikel)}}</a>
      </div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translateString('Merk')}}</div>
      <div class='table_cell'>{{translateGroep(artikel)}}</div>
    </div>
    <div class='table_small dis'>
      <div class='table_cell'>{{translateString('Productnr')}}.</div>
      <div class='table_cell'>{{artikel.Artikel.ProductNr}}</div>
    </div>
    <div class='table_small dis'>
      <div class='table_cell'>{{translateString('Art.Nr')}}.</div>
      <div class='table_cell'>{{artikel.Artikel.ProductCode}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translateString('Inhoud')}}</div>
      <div class='table_cell'>{{artikel.Artikel.Inhoud}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translateString('Prijs')}}</div>
      <div class='table_cell'>
        <div [hidden]="showprijs(artikel)">
          <li class="text-center text-green" (click)="onInfo()" style="list-style-type: none;">
            <span class="tooltip-container">
              <span class="tooltip-content tooltip-top" style="display: none; top: -38px; left: 382.797px;">
              </span>
              {{translateString('Prijsinformatie')}}
            </span>
          </li>
        </div>
        <div [hidden]="!showprijsopaanvraag(artikel)">
          <li class="text-center text-green" style="list-style-type: none;">
            <span class="tooltip-container">
              <span class="tooltip-content tooltip-top" style="display: none; top: -38px; left: 382.797px;">
              </span>
              <div>
              <a title="prijs op aanvraag">{{translateString('P.O.A.')}}</a>
            </div>
            </span>
          </li>
        </div>
        <div [hidden]="!showprijs(artikel) || showprijsopaanvraag(artikel)">
          <p [ngClass]="getPrijsPromo(artikel)"> {{translateString('Stukprijs')}}: €
            <span class="timer" data-from="0" data-to="52.90" data-speed="2000" data-decimals="2">{{GetPrijs(artikel)}}</span>
          </p>

          <div class="pricing-price text-size-16 text-strong text-red no-pad" [hidden]="!showkorting1(artikel)">{{translateString('Stukprijs per')}} {{GetAantal1(artikel)}}: €
            <span class="timer" data-from="4" data-to="38.00" data-speed="2000" data-decimals="2">{{GetKorting1(artikel)}}</span>
          </div>
          <div class="pricing-price text-size-16 text-strong text-red no-pad" [hidden]="!showkorting2(artikel)">{{translateString('Stukprijs per')}} {{GetAantal2(artikel)}}: €
            <span class="timer" data-from="4" data-to="38.00" data-speed="2000" data-decimals="2">{{GetKorting2(artikel)}}</span>
          </div>

        </div>
      </div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translateString('Aantal')}}</div>
      <div class='table_cell'>
        <input name="number" type="number" class="margin-left-10 inputlarge" id="number" [placeholder]="translateString('Aantal')" title="Aantal" [disabled]="!logged"
          [(ngModel)]="artikel.Aantal">
      </div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translateString('Bestel')}}</div>
      <div class='table_cell'>
        <a [ngClass]="getClass()" (click)="onBasket(artikel)"></a>
      </div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translateString('Favoriet')}}</div>
      <div class='table_cell'>
        <a [ngClass]="getClassFav(artikel)" (click)="onFav(artikel)"></a>
      </div>
    </div>
  </div>
</div>
