import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders } from '@angular/common/http';
import { observable, Observable, Subscription, throwError } from 'rxjs';
import { catchError, retry, subscribeOn } from 'rxjs/operators';
import { HandleError, ResponseService } from './response.service';
import { mSearchInput, mSearchOutput, mSearchResult, mSearchExact } from '../models/search/search';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class SearchService {

    private handleError: HandleError;
    private FoundResults: mSearchOutput[];
    private ExactResults: mSearchExact[];
    private apiSearch = this.responseService.apiString + "/search";

    constructor(private http: HttpClient, private responseService: ResponseService) {
      this.handleError = responseService.createHandleError('SearchService');
      this.FoundResults = [];
      this.ExactResults = [];
    }

    getResult() {
        if (this.FoundResults == undefined)
            return [];

        return this.FoundResults;
    }
    getExacteResult() {
        if (this.ExactResults == undefined)
            return [];

        return this.ExactResults;
    }
    onGetSearch(input: mSearchInput) {
        this.getSearch(input).subscribe((_Art) => { this.assignSearch(_Art); });
    }

    public getExactSearch(input: mSearchInput): Observable<mSearchResult> {
      var headerss = new Headers();
      headerss.append("Content-Type", 'application/json');

      return this.http.post<mSearchResult>(this.apiSearch, input, httpOptions);
  }

    private getSearch(input: mSearchInput): Observable<mSearchResult> {
        var headerss = new Headers();
        headerss.append("Content-Type", 'application/json');

        return this.http.post<mSearchResult>(this.apiSearch, input, httpOptions);
    }

    public AssignSearchResult(result: mSearchResult){
      this.assignSearch(result);
    }
    private assignSearch(result: mSearchResult) {
        while (this.FoundResults.length > 0) {
            this.FoundResults.pop();
        }
        result.OutputResultaten.forEach(element => {
            this.FoundResults.push(element);
        });

        while (this.ExactResults.length > 0) {
            this.ExactResults.pop();
        }
        result.ExacteResultaten.forEach(element => {
            this.ExactResults.push(element);

        });
    }
}
