<div class="table" id="results">
  <div class='theader'>
    <div class='table_header'>{{translate('Artikel')}}</div>
    <div class='table_header'>{{translate('Omschrijving')}}</div>
    <div class='table_header'>{{translate('Merk')}}</div>
    <div class='table_header'>{{translate('Artikelnummer')}}</div>
    <div class='table_header'>{{translate('Inhoud')}}</div>
    <div class='table_header'>{{translate('Aantal')}}</div>
    <div class='table_header'>{{translate('Prijs')}}</div>
    <div class='table_header'>{{translate('Totaal')}}</div>
  </div>

  <div class='table_row' *ngFor="let item of BasketItems">
    <div class='table_small'>
      <div class='table_cell'>&nbsp;{{translate('Artikel')}}</div>
      <div class='table_cell'>
        <img class="mini" [src]="item.Artikel!.Artikel.FotoUrl" [alt]="item.Artikel!.Artikel.OmschrijvijngNL">
      </div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Omschrijving')}}</div>
      <div class='table_cell'>{{translateOMS(item)}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Merk')}}</div>
      <div class='table_cell'>{{translateGroep(item)}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Artikelnummer')}}</div>
      <div class='table_cell'>{{item.Artikel!.Artikel.ProductCode}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Inhoud')}}</div>
      <div class='table_cell'>{{item.Artikel!.Artikel.Inhoud}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Aantal')}}</div>
      <div class='table_cell'>{{item.Aantal}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('EH. Prijs')}}</div>
      <div class='table_cell'>{{item.Artikel!.Prijs!.Prijs}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Totaal')}}</div>
      <div class='table_cell'>{{item.Totaal}}</div>
    </div>
  </div>
</div>
<div class="s-12 text-left">
  <br>
  <br>
</div>
<div class="s-12">
  <div class="m-8 s-12">
    <h3 class="text-size-20 text-s-size-8 text-green">
      <em>{{translate('Leverings/Afhaal gegevens')}}</em>
    </h3>
    <hr class="break break-small background-primary">
    <div>
      <input type="checkbox" name="levertype" [checked]="Leveren" (change)="Leveren = !Leveren">{{translate('Leveren')}}
      <br>
      <input type="checkbox" name="levertype" [checked]="!Leveren" (change)="Leveren = !Leveren">{{translate('Afhalen')}}
      <br>
    </div>
    <div [hidden]="!Leveren">
      <form class="customform" action="">
        <label for="adresdrop"> {{translate('Leveringsadres')}}: </label>
        <select id="adresdrop" form="select" #adres (change)="selectoption(adres.value)" [ngModel]="SelectedAdresid" [ngModelOptions]="{standalone: true}">
          <option value="-1">Geen</option>
          <option *ngFor="let c of AfleverAdressen" [value]="c.Nr">{{c.Adres}}</option>
        </select>
      </form>
    </div>
  </div>
  <div class="m-4 s-12">
    <div class="s-6 text-right text-s-size-12 text-m-size-12">
      <p>{{translate('Subtotaal')}}</p>
      <p>{{translate('Verzendkosten')}}</p>
      <p>{{translate('BTW')}}</p>
      <h5>
        <strong>{{translate('Totaal')}}</strong>
      </h5>
    </div>
    <div class="s-6 text-right text-s-size-12 text-m-size-12">
      <p>€ {{subtotal}}</p>
      <p>{{verzendkosten}}</p>
      <p>€ {{btw}}</p>
      <h5>
        <strong>€ {{total}}</strong>
      </h5>
    </div>
    <div class="s-6 text-right text-s-size-12 text-m-size-12">
      {{message}}
    </div>
  </div>
  <div class="s-12 text-right holder">
    <button class="submit-form button background-primary border-radius text-white" type="submit" (click)="onBestelClick()">{{translate('Bestelling Afronden')}}</button>
  </div>
</div>
