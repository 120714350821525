import { TypeKlant } from '../enum';

export class mRegister {
  public TypeKlant: TypeKlant;
  public Voornaam: String | undefined;
  public Naam: String | undefined;
  public Email: string | undefined;
  public Land: string | undefined;
  public Straat: string | undefined;
  public Huisnummer: string | undefined;
  public Postcode: string | undefined;
  public Gemeente: string | undefined;
  public TelefoonNummmer: string | undefined;
  public GSM: string | undefined;
  public BtwNr: string | undefined;
  constructor() {
    this.TypeKlant = TypeKlant.Tandarts;
  }
}
