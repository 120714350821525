import { Component, OnInit } from '@angular/core';
import { RouterparamService } from '../../services/routerparam.service';
import { LoginService } from '../../services/login.service';
import { TypeKlant, Language } from '../../models/enum';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private routerparamService: RouterparamService, private loginService: LoginService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  Reset() {
    this.routerparamService.Reset();
  }

  getlanguage() {
    return this.languageService.Taal;
  }

  getlanguagestring() {
    return this.languageService.TaalString;
  }
  onswitchtaal() {
    this.languageService.switchTaal();
    this.routerparamService.RouteerHome();
  }
  translate(val: string) {
    return this.languageService.translate(val);
  }
  getRouterLink(val: string) {
    if (this.languageService.Taal == Language.NL)
      return "/NL" + val;
    else
      return "/FR" + val;
  }
}
