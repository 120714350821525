<div class="table" id="results">
    <div class='theader'>
      <div class='table_header'>&nbsp;{{translate('Artikel')}}</div>
      <div class='table_header'>{{translate('Omschrijving')}}</div>
      <div class='table_header'>{{translate('Merk')}}</div>
      <div class='table_header'>{{translate('Artikelnummer')}}</div>
      <div class='table_header'>{{translate('Inhoud')}}</div>
      <div class='table_header'>{{translate('Aantal')}}</div>
      <div class='table_header'>{{translate('Prijs')}}</div>
      <div class='table_header'>{{translate('Totaal')}}</div>
      <div class='table_header'>&nbsp;{{translate('Del')}}&nbsp;</div>
    </div>

    <div class='table_row' *ngFor="let item of BasketItems">
      <div class='table_small'>
        <div class='table_cell'>{{translate('Artikel')}}</div>
        <div class='table_cell'>
          <img class="mini" [src]="item.Artikel!.Artikel.FotoUrl" [alt]="item.Artikel!.Artikel.OmschrijvijngNL">
        </div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Omschrijving')}}</div>
        <div class='table_cell'><a class="point" (click)="onArtikelZoom(item)">{{translateOMS(item)}}</a></div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Merk')}}</div>
        <div class='table_cell'>{{translateGroep(item)}}</div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Artikelnummer')}}</div>
        <div class='table_cell'>{{item.Artikel!.Artikel.ProductCode}}</div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Inhoud')}}</div>
        <div class='table_cell'>{{item.Artikel!.Artikel.Inhoud}}</div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Aantal')}}</div>
        <div class='table_cell'><i class="icon-minus text-red point" (click)="onremove(item)"></i>{{item.Aantal}}<i class="icon-plus text-green point" (click)="onadd(item)"></i></div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('EH. Prijs')}}</div>
        <div [ngClass]="getPromo(item)">{{getPrice(item)}}</div>
      </div>
      <div class='table_small'>
          <div class='table_cell'>{{translate('Totaal')}}</div>
          <div class='table_cell'>{{item.Totaal}}</div>
        </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Delete')}}</div>
        <div class='table_cell point'>
          <i class="lightcase-icon-close text-red" (click)="onDelete(item)"></i>
        </div>
      </div>
    </div>
  </div>
