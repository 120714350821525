import { mLogin } from "../login/login";
import { mArtikelPrijs } from "./martikelprijs";

export class mPrice
{

    public Login: mLogin|undefined;
    public Artikels: mArtikelPrijs[]= [];

    public mPrice() { }
}
