import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-tandartsFR-header',
  templateUrl: '../../../../../assets/html/webshoptandartsFR.html',
  styleUrls: ['./headerFR.component.css']
})
export class TandartsHeaderFRComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }

}
