import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { mSearchOutput, mSearchExact } from '../../../models/search/search';
import { RouterparamService } from '../../../services/routerparam.service';
import { TypeResult } from '../../../models/enum';
import { LanguageService } from '../../../services/language.service';


@Component({
  selector: 'app-searchbarpopup',
  templateUrl: './searchbarpopup.component.html',
  styleUrls: ['./searchbarpopup.component.css']
})
export class SearchbarpopupComponent implements OnInit {

  constructor(private RouterparamService: RouterparamService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  @Input() FoundResults: mSearchOutput[]=[];
  @Input() ExactResults: mSearchExact[]=[];
  @Output() onClicked = new EventEmitter();

  get ishidden(): boolean {
    if (this.ExactResults == undefined || this.ExactResults.length == 0)
      return true;
    return false;
  }

  click(){
    this.onClicked.emit();
  }
  onMeer()
  {
    var ar:string[] = [];
    this.FoundResults.forEach(element => {
      element.Children.forEach(a => {ar.push(a.ID)}
      )
    });
    this.ExactResults.forEach(element => {
      element.Children.forEach(a => {ar.push(a.ID)}
      )
    });
    this.RouterparamService.Routeer(ar, TypeResult.Artikel, "");
    this.onClicked.emit();

  }
  isnoresult(){
    if (this.ExactResults.length == 0 && this.FoundResults.length == 0)
    return false;
    else
    return true;
  }

  translate(val: string){
    return this.languageService.translate(val);
  }
}
