<nav class="background-white background-primary-hightlight padding noprint">
  <div class="line">
    <div class="s-12 m-3 l-3">
      <div class="top-bar-contact">
        <!-- Logo -->
        <a [routerLink]="getRouterLink('/home')" class="logo">
          <img src="../../../assets/images/logo providental.png" alt="" class="float-left">
        </a>
      </div>
    </div>
    <div class="top-nav s-12 m-9 l-9">
      <p class="nav-text"></p>
      <ul class="right chevron">
          <li  routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/webshop/tandarts')" target="_self">{{translate('Producten bestellen')}}</a>
            </li>
        <!--<li class="submenu">
          <a aria-haspopup="true">{{translate('Producten bestellen')}}
            <span class="count-number"> 2</span>
          </a>
          <ul>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/webshop/tandarts')" (click)="Reset()" target="_self">{{translate('Tandartsen')}}</a>
            </li>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/webshop/labo')" (click)="Reset()" >{{translate('Tandtechnische labo\'s')}}</a>
            </li>
          </ul>
        </li>-->
        <li class="submenu">
          <a aria-haspopup="true">{{translate('Over Providental')}}
            <span class="count-number"> 6</span>
          </a>
          <ul>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/betalingsvoorwaarden')" target="_self">{{translate('Betalingsvoorwaarden')}}</a>
            </li>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/leveringsvoorwaarden')" target="_self">{{translate('Leveringsvoorwaarden')}}</a>
            </li>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/hoebestellen')" target="_self">{{translate('Hoe bestellen')}}</a>
            </li>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/kortingen')" target="_self">{{translate('Kortingen')}}</a>
            </li>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/fabrikantlinks')" target="_self">{{translate('Links fabrikanten')}}</a>
            </li>
            <li routerLinkActive="active-item">
              <a [routerLink]="getRouterLink('/fabrikantecatalogus')" target="_self">{{translate('e-catalogus in pdf')}}</a>
            </li>
          </ul>
        </li>
        <li  routerLinkActive="active-item">
          <a [routerLink]="getRouterLink('/home')" target="_self">{{translate('Home')}}</a>
        </li>
        <li  routerLinkActive="active-item">
          <a [routerLink]="getRouterLink('/contact')" target="_self">{{translate('Contact')}}</a>
        </li>
        <li  routerLinkActive="active-item">
          <a (click)="onswitchtaal()">{{getlanguagestring()}}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>