<footer>
    <!-- Social -->      <!-- Main Footer -->
    
    <!-- Bottom Footer -->
    <section class="background-dark padding noprint">
      <div class="line">
        <div class="s-12 l-12">
          <p class="text-size-12 text-center"><strong>Providental</strong> / Muizenstraat 12A / 2060 Antwerpen / T: 03 226 00 81</p>
          <p class="text-size-12 text-center"><strong>{{translateString('Openingsuren')}}</strong>: {{translateString('maandag tot en met vrijdag: 8.30u tot 17.00')}}</p>
          <p class="text-size-12 text-center">&nbsp;</p>
          <p class="text-size-12 text-center"><a routerLink="/disclaimer" target="_self">Disclaimer</a> / <a routerLink="/privacystatement" target="_self">Privacy Statement</a> / {{translateString('Voorwaarden')}}</p>
          <p class="text-size-12 text-center">Copyright 2018 Providental / {{translateString('Alle rechten voorbehouden')}}</p>
        </div>
      </div>
    </section>
  </footer>