<!-- Midden -->
<main role="main">

  <section>
    <article>
      <header class="section background-transparent text-center text-blue" data-parallax="scroll" data-image-src="../../assets/images/parallax-01.jpg">
      </header>
      <div class="background-white padding">
        <div class="line">
          <h2 class="text-size-30 text-s-size-12">
            <em>Betalingsvoorwaarden</em>
          </h2>
          <hr class="break break-small background-primary">
          <p>
            <strong> Alle vermelde prijzen zijn exclusief BTW. </strong>
          </p>
          <p>Wij vragen een stipte betaling van de facturen binnen de 10 dagen na factuurdatum. Dit is een absolute voorwaarde
            om ons prijzenbestand in stand te kunnen houden. Voor niet betaalde of niet volledig betaalde facturen houden
            wij ons het recht voor 1% verwijlintresten per maand aan te rekenen, vermeerderd met een onaftrekbare en forfaitaire
            vergoeding van 10% met een minimum van € 25. In betwiste gevallen, om welke reden ook, zijn enkel de rechtbanken
            van Antwerpen en de vrederechter van het 1ste Kanton van Antwerpen bevoegd.</p>
          <p class="margin-bottom-30">
            <strong> De hierin vermelde prijzen zijn tot de volgende cataloguseditie van kracht. </strong>
            <br> Prijswijzigingen en zetfouten voorbehouden. Rekening houdend met de uitzonderlijk agressieve prijssetting van
            een beperkt aantal producten kunnen niettemin aanzienlijke marktschommelingen en/of uitzonderlijke prijsverhogingen
            van de producent aanleiding geven tot prijsherziening. Dit evenwel zonder dat aan de prijzen van het globale
            assortiment van de catalogus geraakt wordt. </p>
          <div class="line"> </div>
        </div>

        <!-- Icoontjes -->
        <div class="line">
          <hr class="break margin-bottom-70">
          <div class="margin">
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-pen background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">Hoe bestellen</h4>
                <p>Van 08.30u tot 17.00u (doorlopend)</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/hoebestellen">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-random background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">leveringsvoorwaarden
                  <br>
                </h4>
                <p>Alle goederen worden zonder verzendkosten geleverd voor bestellingen van 100 Euro (exclusief BTW).</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/leveringsvoorwaarden">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-star_empty background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">kortingen</h4>
                <p>U ontvangt 2% directe korting op uw factuur (niet cumuleerbaar met e-shopkorting)</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/kortingen">Lees meer</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="background-dark padding text-center"> </div>
    </article>


  </section>

</main>