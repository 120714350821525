import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from "@angular/router";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './shared/header/header.component';
import { ActionComponent } from './shared/action/action.component';
import { SearchComponent } from './shared/action/search/search.component';
import { ActionsComponent } from './shared/action/actions/actions.component';
import { LoginComponent } from './shared/action/actions/login/login.component';
import { FavoritesComponent } from './shared/action/actions/favorites/favorites.component';
import { BasketComponent } from './shared/action/actions/basket/basket.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './pages/home/home.component';
import { SharedComponent } from './shared/shared.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AanbiedingenComponent } from './pages/fabrikanten/aanbiedingen/aanbiedingen.component';
import { EcatalogusComponent } from './pages/fabrikanten/ecatalogus/ecatalogus.component';
import { LinksComponent } from './pages/fabrikanten/links/links.component';
import { NieuweproductenComponent } from './pages/fabrikanten/nieuweproducten/nieuweproducten.component';
import { BetalingsvoorwaardenComponent } from './pages/betalingsvoorwaarden/betalingsvoorwaarden.component';
import { LeveringsvoorwaardenComponent } from './pages/leveringsvoorwaarden/leveringsvoorwaarden.component';
import { KortingenComponent } from './pages/kortingen/kortingen.component';
import { HoebestellenComponent } from './pages/hoebestellen/hoebestellen.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LogorregisComponent } from './pages/logorregis/logorregis.component';
import { PrivacystatementComponent } from './pages/privacystatement/privacystatement.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { TandartsComponent } from './dynamic/webshop/tandarts/tandarts.component';
import { TandartsHeaderComponent } from './dynamic/webshop/tandarts/header/header.component';
import { TandartsContentComponent } from './dynamic/webshop/tandarts/content/content.component';
import { SidebarComponent } from './dynamic/webshop/content/sidebar/sidebar.component';
import { InhoudComponent } from './dynamic/webshop/content/inhoud/inhoud.component';
import { SupergroepcontentComponent } from './dynamic/webshop/content/inhoud/supergroepcontent/supergroepcontent.component';
import { HoofdgroepcontentComponent } from './dynamic/webshop/content/inhoud/hoofdgroepcontent/hoofdgroepcontent.component';
import { GroepcontentComponent } from './dynamic/webshop/content/inhoud/groepcontent/groepcontent.component';
import { ArtikelcontentComponent } from './dynamic/webshop/content/inhoud/artikelcontent/artikelcontent.component';
import { SearchbarComponent } from './controls/searchbar/searchbar.component';
import { SearchbarpopupComponent } from './controls/searchbar/searchbarpopup/searchbarpopup.component';
import { SearchbarpopupitemComponent } from './controls/searchbar/searchbarpopup/searchbarpopupitem/searchbarpopupitem.component';
import { SearchbarpopupexactComponent } from './controls/searchbar/searchbarpopup/searchbarpopupexact/searchbarpopupexact.component';
import { WebshopbasketComponent } from './dynamic/webshop/webshopbasket/webshopbasket.component';
import { WebshopbasketdetailComponent } from './dynamic/webshop/webshopbasket/webshopbasketdetail/webshopbasketdetail.component';

import { CookieService } from 'ngx-cookie-service';
import { SearchService } from './services/search.service';
import { RouterparamService } from './services/routerparam.service';
import { LoginService } from './services/login.service';
import { MenuService } from './services/menu.service';
import { ResponseService } from './services/response.service';
import { ArtikelService } from './services/artikel.service';
import { BasketService } from './services/basket.service';
import { LaboComponent } from './dynamic/webshop/labo/labo.component';
import { LaboHeaderComponent } from './dynamic/webshop/labo/header/header.component';
import { LaboContentComponent } from './dynamic/webshop/labo/content/content.component';
import { SingleartikelcontentComponent } from './dynamic/webshop/content/inhoud/singleartikelcontent/singleartikelcontent.component';
import { ArtikelfilterComponent } from './dynamic/webshop/content/inhoud/artikelfilter/artikelfilter.component';
import { FavorietenService } from './services/favorieten.service';
import { ArtikellinecontentComponent } from './dynamic/webshop/content/inhoud/artikellinecontent/artikellinecontent.component';
import { WebshopfavorietenComponent } from './dynamic/webshop/webshopfavorieten/webshopfavorieten.component';
import { WebshopfavorietenitemComponent } from './dynamic/webshop/webshopfavorieten/webshopfavorietenitem/webshopfavorietenitem.component';
import { WebshopbasketoverzichtComponent } from './dynamic/webshop/webshopbasket/webshopbasketoverzicht/webshopbasketoverzicht.component';
import { WebshopbasketfinishComponent } from './dynamic/webshop/webshopbasket/webshopbasketfinish/webshopbasketfinish.component';
import { LanguageService } from './services/language.service';
import { HomeFRComponent } from './pagesFR/homeFR/homeFR.component';
import { ContactFRComponent } from './pagesFR/contactFR/contactFR.component';
import { HoebestellenFRComponent } from './pagesFR/hoebestellenFR/hoebestellenFR.component';
import { KortingenFRComponent } from './pagesFR/kortingenFR/kortingenFR.component';
import { PrivacystatementFRComponent } from './pagesFR/privacystatementFR/privacystatementFR.component';
import { DisclaimerFRComponent } from './pagesFR/disclaimerFR/disclaimerFR.component';
import { LeveringsvoorwaardenFRComponent } from './pagesFR/leveringsvoorwaardenFR/leveringsvoorwaardenFR.component';
import { BetalingsvoorwaardenFRComponent } from './pagesFR/betalingsvoorwaardenFR/betalingsvoorwaardenFR.component';
import { AanbiedingenFRComponent } from './pagesFR/fabrikantenFR/aanbiedingen/aanbiedingenFR.component';
import { EcatalogusFRComponent } from './pagesFR/fabrikantenFR/ecatalogus/ecatalogusFR.component';
import { LinksFRComponent } from './pagesFR/fabrikantenFR/links/linksFR.component';
import { NieuweproductenFRComponent } from './pagesFR/fabrikantenFR/nieuweproducten/nieuweproductenFR.component';
import { TandartsFRComponent } from './dynamic/webshop/tandartsFR/tandartsFR.component';
import { LaboFRComponent } from './dynamic/webshop/laboFR/laboFR.component';
import { TandartsHeaderFRComponent } from './dynamic/webshop/tandartsFR/header/headerFR.component';
import { LaboHeaderFRComponent } from './dynamic/webshop/laboFR/header/headerFR.component';
import { BestellingenService } from './services/bestellingen.service';
import { WebshopbestellingenComponent } from './dynamic/webshop/webshopbestellingen/webshopbestellingen.component';
import { WebshopbestellingenitemComponent } from './dynamic/webshop/webshopbestellingen/webshopbestellingenitem/webshopbestellingenitem.component';
import { WebshopbestellingenitemdetailsComponent } from './dynamic/webshop/webshopbestellingen/webshopbestellingenitem/webshopbestellingenitemdetails/webshopbestellingenitemdetails.component';
import { SearchpageComponent } from './controls/searchbar/searchpage/searchpage.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LogorregisComponent },
  { path: 'NL/home', component: HomeComponent },
  { path: 'NL/contact', component: ContactComponent },
  { path: 'NL/hoebestellen', component: HoebestellenComponent },
  { path: 'NL/kortingen', component: KortingenComponent },
  { path: 'NL/privacystatement', component: PrivacystatementComponent },
  { path: 'NL/disclaimer', component: DisclaimerComponent },
  { path: 'NL/leveringsvoorwaarden', component: LeveringsvoorwaardenComponent },
  { path: 'NL/betalingsvoorwaarden', component: BetalingsvoorwaardenComponent },
  { path: 'NL/fabrikantaanbiedingen', component: AanbiedingenComponent },
  { path: 'NL/fabrikantecatalogus', component: EcatalogusComponent },
  { path: 'NL/fabrikantlinks', component: LinksComponent },
  { path: 'NL/fabrikantnieuweproducten', component: NieuweproductenComponent },
  { path: 'NL/webshop/tandarts', component: TandartsComponent },
  { path: 'NL/webshop/tandarts/:id', component: TandartsComponent },
  { path: 'NL/webshop/search/', component: SearchpageComponent },
  { path: 'NL/webshop/labo', component: LaboComponent },
  { path: 'webshop/basket', component: WebshopbasketComponent },
  { path: 'webshop/favorieten', component: WebshopfavorietenComponent },
  { path: 'webshop/bestellingen', component: WebshopbestellingenComponent },
  { path: 'FR/home', component: HomeFRComponent },
  { path: 'FR/contact', component: ContactFRComponent },
  { path: 'FR/hoebestellen', component: HoebestellenFRComponent },
  { path: 'FR/kortingen', component: KortingenFRComponent },
  { path: 'FR/privacystatement', component: PrivacystatementFRComponent },
  { path: 'FR/disclaimer', component: DisclaimerFRComponent },
  { path: 'FR/leveringsvoorwaarden', component: LeveringsvoorwaardenFRComponent },
  { path: 'FR/betalingsvoorwaarden', component: BetalingsvoorwaardenFRComponent },
  { path: 'FR/fabrikantaanbiedingen', component: AanbiedingenFRComponent },
  { path: 'FR/fabrikantecatalogus', component: EcatalogusFRComponent },
  { path: 'FR/fabrikantlinks', component: LinksFRComponent },
  { path: 'FR/fabrikantnieuweproducten', component: NieuweproductenFRComponent },
  { path: 'FR/webshop/tandarts', component: TandartsFRComponent },
  { path: 'FR/webshop/labo', component: LaboFRComponent },
  { path: '**', component: HomeComponent },
]

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ActionComponent,
    SearchComponent,
    LoginComponent,
    FavoritesComponent,
    MainComponent,
    HomeComponent,
    SharedComponent,
    BasketComponent,
    ActionsComponent,
    FooterComponent,
    AanbiedingenComponent,
    EcatalogusComponent,
    LinksComponent,
    NieuweproductenComponent,
    BetalingsvoorwaardenComponent,
    LeveringsvoorwaardenComponent,
    KortingenComponent,
    HoebestellenComponent,
    ContactComponent,
    LogorregisComponent,
    PrivacystatementComponent,
    DisclaimerComponent,
    TandartsComponent,
    TandartsHeaderComponent,
    TandartsContentComponent,
    SidebarComponent,
    InhoudComponent,
    SupergroepcontentComponent,
    HoofdgroepcontentComponent,
    GroepcontentComponent,
    ArtikelcontentComponent,
    SearchbarComponent,
    SearchbarpopupComponent,
    SearchbarpopupitemComponent,
    SearchbarpopupexactComponent,
    WebshopbasketComponent,
    WebshopbasketdetailComponent,
    LaboComponent,
    LaboHeaderComponent,
    LaboContentComponent,
    SingleartikelcontentComponent,
    ArtikelfilterComponent,
    ArtikellinecontentComponent,
    WebshopfavorietenComponent,
    WebshopfavorietenitemComponent,
    WebshopbasketoverzichtComponent,
    WebshopbasketfinishComponent,
    HomeFRComponent,
    ContactFRComponent,
    HoebestellenFRComponent,
    KortingenFRComponent,
    PrivacystatementFRComponent,
    DisclaimerFRComponent,
    LeveringsvoorwaardenFRComponent,
    BetalingsvoorwaardenFRComponent,
    AanbiedingenFRComponent,
    EcatalogusFRComponent,
    LinksFRComponent,
    NieuweproductenFRComponent,
    TandartsFRComponent,
    TandartsHeaderFRComponent,
    LaboFRComponent,
    LaboHeaderFRComponent,
    WebshopbestellingenComponent,
    WebshopbestellingenitemComponent,
    WebshopbestellingenitemdetailsComponent,
    SearchpageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
    CookieService,
    MenuService,
    ResponseService,
    SearchService,
    RouterparamService,
    LoginService,
    ArtikelService,
    BasketService,
    FavorietenService,
    LanguageService,
    BestellingenService],
  bootstrap: [AppComponent]
})
export class AppModule { }
