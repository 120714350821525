import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../services/language.service';
import { Language } from '../../../models/enum';

@Component({
  selector: 'app-webshopfavorieten',
  templateUrl: './webshopfavorieten.component.html',
  styleUrls: ['./webshopfavorieten.component.css']
})
export class WebshopfavorietenComponent implements OnInit {

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
  }

  translate(val: string) {
    return this.languageService.translate(val);
  }
  getrouterlink(val: string) {   
    if (this.languageService.Taal == Language.NL)
      return "/NL" + val;
    else
      return "/FR" + val; 
  }
}
