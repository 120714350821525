import { TypeResult, TypeKlant } from "../enum";
import { mLogin } from "../login/login";
import { mBasket, mBasketItem, mLeverenEncap } from "../basket/basket";
import { mArtikelPrijs } from "../prijs/martikelprijs";

export class mSearchInput {
    constructor(taal: string, input: string, typecode: TypeKlant) {
        this.Taal = taal;
        this.Input = input;
        this.TypeCode = typecode;
    }
    public Taal: String;
    public Input: String;
    public TypeCode: TypeKlant;
}
export class mSearchOutput {
    constructor(omschrijving: string, type: TypeResult, id: string, typeCode: string) {
        this.Omschrijving = omschrijving;
        this.Type = type;
        this.ID = id;
        this.TypeCode = typeCode;
    }
    public Omschrijving: string;
    public Type: TypeResult;
    public ID: string;
    public FotoUrl: string="";
    public TypeCode: string;
    public Children: mSearchOutput[] = [];
}
export class mSearchResult {
    constructor() {

    }
    public ExacteResultaten: mSearchExact[] = [];
    public OutputResultaten: mSearchOutput[] = [];
}
export class mSearchExact {
    constructor(omschrijving: string, type: TypeResult, iD: string) {
        this.Omschrijving = omschrijving;
        this.Type = type;
        this.ID = iD;
    }
    public Omschrijving: string;
    public Type: TypeResult;
    public ID: string;
    public Children: mSearchOutput[] = [];
}
export class mSearchBarcode {
    constructor(login: mLogin, basket: mBasket|undefined, input: string) {
        this.Login = login;
        this.Input = input;
        this.Basket = basket;
    }
    public Login: mLogin;
    public Basket?: mBasket;
    public Input: String;
    public Aantal: number =0;
}
export class mSearchDeleteBasketItem {
    constructor(login: mLogin, basket: mBasket|undefined, basketitem: mBasketItem) {
        this.Login = login;
        this.Basketitem = basketitem;
        this.Basket = basket;
    }
    public Login: mLogin;
    public Basket?: mBasket;
    public Basketitem: mBasketItem;
}
export class mSearchAddBasketItem {
    constructor(login: mLogin, basket: mBasket|undefined, ArtikelPrijs: mArtikelPrijs, Aantal: number) {
        this.Login = login;
        this.ArtikelPrijs = ArtikelPrijs;
        this.Basket = basket;
        this.Aantal = Aantal;
    }
    public Login: mLogin;
    public Basket?: mBasket;
    public ArtikelPrijs: mArtikelPrijs
    public Aantal: number;
}
export class mSearchAddBasketItemID {
    constructor(login: mLogin, basket: mBasket|undefined, artikelID: string, Aantal: number) {
        this.Login = login;
        this.ArtikelID = artikelID;
        this.Basket = basket;
        this.Aantal = Aantal;
    }
    public Login: mLogin;
    public Basket?: mBasket;
    public ArtikelID: string
    public Aantal: number;
}
export class mSearchAddFavoriet {
    constructor(login: mLogin, artikelPrijs: mArtikelPrijs, aantal: number) {
        this.Login = login;
        this.ArtikelPrijs = artikelPrijs;
        this.Aantal = aantal;
    }
    public ArtikelPrijs: mArtikelPrijs
    public Login: mLogin;
    public Aantal: number;
}
export class mSearchConfirmOrder{
    constructor(login: mLogin, leveringsgegevens: mLeverenEncap, img: any, isNL: boolean){
        this.Login = login;
        this.Leveringsgegevens = leveringsgegevens;
        this.Img = img;
        this.IsNL = isNL;
    }
    public Login: mLogin;
    public Leveringsgegevens: mLeverenEncap;
    public Message: string = '';
    public Img: any;
    public IsNL: boolean;
}
