<div class="parallax-mirror" style="
    visibility: visible;
    z-index: -100;
    position: fixed;
    top: 58.1676px;
    left: 0px;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px);
    height: 609px;
    width: 100%;
  ">
  <img class="parallax-slider" src="../../../assets/images/parallax-07.jpg" style="
      transform: translate3d(0px, 0px, 0px);
      position: absolute;
      height: 535px;
      width: 100%;
      max-width: none;
    " />
</div>

<!-- HEADER -->
<!-- MAIN -->
<main role="main">
  <!-- Section 1 -->
  <article>
    <header class="section background-green background-transparent text-center" data-parallax="scroll"
      data-image-src="../../../assets/images/parallax-07.jpg">
      <div class="line">
        <div class="margin-top-bottom-20">
          <!-- Title Strings -->
          <!-- <div id="typed-strings" style="display: none">
            <p>de petit équipement</p>
            <p>de nouveaux produits</p>
            <p>de matériels promotionnel</p>
          </div> -->
          <h1 class="text-white text-size-40 text-thin text-line-height-1">
            Le fournisseur de petit équipement
            <br />
            pour les professionnels du dentaire
          </h1>
          <p class="text-white text-size-16 margin-bottom-20">
            Des produits de qualité au prix les plus bas dans notre
            <strong>
              <a routerLink="/FR/webshop/tandarts" class="text-yellow-hover">webshop
              </a> </strong>!
          </p>
          <div class="line">
            <a class="button border-radius background-yellow text-size-14 text-dark text-strong text-primary-hover"
              href="https://view.publitas.com/providental/promo-web-fr-providental" target="_blank">Offres online !</a>
          </div>
        </div>
      </div>
    </header>
  </article>
  <!-- Section 2 -->

  <section class="section background-white">
    <div class="line">
      <div class="margin">
        <div class="full-width text-center">
          <a href="https://view.publitas.com/providental/promo-web-fr-providental" target="_blank">
            <img src="../../../assets/images/Covers/cover.png" alt="Offres d'hiver online !" class="center"
              style="margin-top: -210px" />
          </a>
          <div class="line">
            <h2 class="headline text-thin text-s-size-30">
              You <span class="text-primary">Name it</span>, We've got it !
            </h2>
            <p class="text-size-20 text-s-size-16 text-thin">
              Ne compromittez pas la qualité!
            </p>
            <p class="text-size-20 text-s-size-16 text-thin">
              Vous ne trouvez pas ce que vous cherchez ... <br />
              Informez-vous sur les conditons relatives aux produits non repris:
              03 226 00 81 / info&#64;providental.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section background-white padding">
    <div class="line">
      <div class="margin">
        <div class="s-12 m-12 l-4 margin-m-bottom">
          <a href="https://view.publitas.com/providental/promo-web-fr-providental" target="_blank">
            <img class="margin-bottom" src="../../assets/images/Aanbiedingen fabrikanten/PromoNL.jpg"
              alt="Promotion online FR" />
          </a>
          <h2 class="text-thin">
            <strong class="text-red">La brochure d'offres </strong>
          </h2>
          <p>
            <a href="https://view.publitas.com/providental/promo-web-fr-providental" target="_blank">- Promotion
            </a>
          </p>
          <p>
            <a routerLink="/FR/fabrikantaanbiedingen" target="_self">- La brochure d'offres frabricants ....</a>
          </p>
          <a class="text-more-info text-primary-hover"
            href="https://view.publitas.com/providental/promo-web-fr-providental" target="_blank">Plus d'info
          </a>
        </div>
        <div class="s-12 m-12 l-4 margin-m-bottom">
          <a routerLink="/FR/webshop/tandarts" target="_self">
            <img class="margin-bottom" src="../../../assets/images/Aanbiedingen fabrikanten/Webshop.jpg"
              alt="Dentistes en ligne" />
          </a>
          <h2 class="text-thin text-green">
            <strong>Dentistes en ligne</strong>
          </h2>
          <p>Expedie le jour de la commande !</p>
          <a class="text-more-info text-primary-hover" routerLink="/FR/webshop/tandarts" target="_self">Commande</a>
        </div>
        <div class="s-12 m-12 l-4 margin-m-bottom">
          <a href="Catalogus updates_nieuwe producten_midden_FR.html" routerLink="/FR/webshop/tandarts"
            target="_blank"><img class="margin-bottom" src="../../../assets/images/Aanbiedingen fabrikanten/Nieuws.jpg"
              alt="Ontdek hier nieuwe producten!" /></a>
          <h2 class="text-thin">
            <strong>Nouvelles !</strong>
          </h2>
          <p>Nouveau dans notre gamme de plus de 60.000 produits</p>
          <p>
            <a routerLink="/FR/fabrikantecatalogus" target="_self">- Une version papier de catalogue ...</a>
          </p>
          <p>
            <a routerLink="/FR/fabrikantlinks" target="_self">- Voici un aperçu de tous nos fabricants ...</a>
          </p>
          <a class="text-more-info text-primary-hover" routerLink="/FR/fabrikantnieuweproducten"
            target="_self">Découvrez le ici</a>
        </div>
      </div>
    </div>
  </section>

  <!-- Section 3 -->

  <!-- Section 4 -->

  <!-- Section 5 -->
  <section class="section background-white hide-s hide-m">
    <div class="line">
      <h2 class="text-thin headline text-center text-s-size-30 margin-bottom-50">Offre des Fabricants</h2>
      <div class="carousel-default owl-carousel carousel-wide-arrows">
        <div class="item">
          <div class="margin">
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius margin-m-bottom">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom">
                    <a class="image-hover-zoom"
                      href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promotie 2024 FR juli tot sept.pdf"
                      target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/coltene afb web fr.png"
                        alt="Coltene"></a>
                  </div>
                  <div class="s-12 m-12 l-8 margin-m-bottom">
                    <h3><a class="text-dark text-primary-hover"
                        href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promotie 2024 FR juli tot sept.pdf"
                        target="_blank">Offres de Coltene</a></h3>
                    <p>Jusqu'au 30/09/2024.</p>
                    <a class="text-more-info text-primary-hover"
                      href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promotie 2024 FR juli tot sept.pdf"
                      target="_blank">Plus d'info</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom">
                    <a href="../../../assets/images/Aanbiedingen fabrikanten/Voco Promo 2024 vanaf april FR.pdf"
                      target="_blank" class="image-hover-zoom"><img
                        src="../../../assets/images/Aanbiedingen fabrikanten/voco afb web fr.png" alt="Voco"></a>
                  </div>
                  <div class="s-12 m-12 l-8">
                    <h3><a class="text-dark text-primary-hover"
                        href="../../../assets/images/Aanbiedingen fabrikanten/Voco Promo 2024 vanaf april FR.pdf"
                        target="_blank">Offres de Voco</a></h3>
                    <p>Jusqu'au 30/09/2024</p>
                    <a class="text-more-info text-primary-hover"
                      href="../../../assets/images/Aanbiedingen fabrikanten/Voco Promo 2024 vanaf april FR.pdf"
                      target="_blank">Plus d'info</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="item">
          <div class="margin">
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius margin-m-bottom">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom"> <a class="image-hover-zoom"
                      href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 FR.pdf" target="_blank"><img
                        src="../../../assets/images/Aanbiedingen fabrikanten/Durr dental web fr.png" alt="GC"></a> </div>
                  <div class="s-12 m-12 l-8 margin-m-bottom">
                    <h3><a class="text-dark text-primary-hover"
                        href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 FR.pdf" target="_blank">Dürr
                        Dental</a></h3>
                    <p>Nouveau dans l'assortiment 2024.</p>
                    <a class="text-more-info text-primary-hover"
                      href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 FR.pdf" target="_blank">Plus
                      d'info</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="s-12 m-12 l-6">
              <div class="image-border-radius">
                <div class="margin">
                  <div class="s-12 m-12 l-4 margin-m-bottom"> <a
                      href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf"
                      target="_blank" class="image-hover-zoom"><img
                        src="../../../assets/images/Aanbiedingen fabrikanten/3m afb web engels.png" alt="3M"></a> </div>
                  <div class="s-12 m-12 l-8">
                    <h3><a class="text-dark text-primary-hover"
                        href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf"
                        target="_blank">3M</a></h3>
                    <p>Nouveau dans l'assortiment 2024</p>
                    <a class="text-more-info text-primary-hover"
                      href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf"
                      target="_blank">Plus d'info</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


</main>
