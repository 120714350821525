<!-- Midden -->
<main role="main">

  <section>
    <article>
      <header class="section background-green-hightlight background-transparent text-center background-blue" data-parallax="scroll"
        data-image-src="../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">e-catalogus / pdf </h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue">
              <a routerLink="/NL/home" target="_self"> Home</a>
            </p>
            <h2 class="text-size-30 text-s-size-12">
              <em>E-catalogus / pdf</em>
            </h2>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
              <a routerLink="/NL/fabrikantnieuweproducten" target="_self">
                <strong>
                  <em> Nieuw in ons assortiment</em>
                </strong>
              </a>
            </h4>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
              <a routerLink="/NL/fabrikantlinks" target="_self">
                <strong>
                  <em> Links naar fabrikanten</em>
                </strong>
              </a>
            </h4>
            <hr class="break break-small background-primary">
            <div class="box margin-bottom">
              <div class="margin">
                <!-- CONTENT -->

                <!-- ASIDE NAV -->

                <div class="s-12 m-12 l-12">
                  <div class="line">
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/afdruk-online-2022" target="_blank">
                        <img src="../../assets/images/iconen/afdrukMiddel 2.png" alt="Afdruk" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">Afdruk</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/amalgaam-online-2022" target="_blank">
                        <img src="../../assets/images/iconen/amalgaamMiddel 10.png" alt="amalgaam" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">amalgaam</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/anesthesie-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/anesthesieMiddel 4.png" alt="Anesthesie" width="75" height="75"
                          class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">anesthesie</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/apparatuur-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/apparatuurMiddel 5.png" alt="Apparatuur" width="75" height="75"
                          class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">apparatuur</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/articulatie-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/articulatieMiddel 6.png" alt="Articulatie" width="75" height="75"
                          class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">articulatie</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/bleaching-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/bleachingMiddel 7.png" alt="Bleaching" width="75" height="75"
                          class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">bleaching</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/boren-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/borenMiddel 8.png" alt="boren" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">boren</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/cementen-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/cementenMiddel 9.png" alt="cementen" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">cementen</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/chirurgie-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/chirurgie.png" alt="chirurgie" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">chirurgie</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/cofferdam-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/cofferdam.png" alt="cofferdam" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">cofferdam</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/compomeren-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/compomeren.png" alt="compomeren" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">compomeren</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/composieten-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/composietenMiddel 13.png" alt="composieten" width="75" height="75"
                          class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">composieten</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/disposables-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/disposables.png" alt="disposables" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">disposables</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/endo-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/endo.png" alt="endo" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">endo</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/hoekstukken-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/hoekstukken.png" alt="hoekstukken" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">hoekstukken</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/instrumenten-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/instrumentenMiddel 12.png" alt="instrumenten" width="75" height="75"
                          class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">instrumenten</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/kunstharsen-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/kunstharsen.png" alt="kunstharsen" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">kunstharsen</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/matrijzen-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/Matrijzen.png" alt="matrijzen" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">matrijzen</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/mondhygiene-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/mondhygieneMiddel 15.png" alt="mondhygiëne" width="75" height="75"
                          class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">mondhygiëne</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a href="https://view.publitas.com/providental/noodkronen-online-2023" target="_blank">
                        <img src="../../assets/images/iconen/noodkronen.png" alt="noodkronen" width="75" height="75" class="center">
                      </a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">noodkronen</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60"> <a href="https://view.publitas.com/providental/pinsystemen-online-2023" target="_blank"><img src="../../assets/images/iconen/pinsystemenMiddel 16.png" alt="pinsystemen" width="75" height="75" class="center"></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">pinsystemen</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60"> <a href="https://view.publitas.com/providental/polijsten-online-2023" target="_blank"><img src="../../assets/images/iconen/polijstenMiddel 11.png" alt="polijsten" width="75" height="75" class="center"></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">polijsten</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60"> <a href="https://view.publitas.com/providental/reiniging-online-2023" target="_blank"><img src="../../assets/images/iconen/reinigingMiddel 14.png" alt="reiniging" width="75" height="75" class="center"></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">reiniging</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60"> <a href="https://view.publitas.com/providental/rontgen-online-2023" target="_blank"><img src="../../assets/images/iconen/rontgen.png" alt="röntgen" width="75" height="75" class="center"></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">röntgen</h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60"> <a href="https://view.publitas.com/providental/wiggen-online-2023" target="_blank"><img src="../../assets/images/iconen/wiggen.png" alt="wiggen" width="92" height="92" class="center"></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">wiggen</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Kleurbalk -->
    </article>


  </section>

</main>
