import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { BasketService } from '../../../../services/basket.service';
import { LoginService } from '../../../../services/login.service';
import { mKlantAfleveradres, mBasketItem } from '../../../../models/basket/basket';
import { LanguageService } from '../../../../services/language.service';
import { Language } from '../../../../models/enum';

@Component({
  selector: 'app-webshopbasketoverzicht',
  templateUrl: './webshopbasketoverzicht.component.html',
  styleUrls: ['./webshopbasketoverzicht.component.css']
})
export class WebshopbasketoverzichtComponent implements OnInit {

  constructor(private basketService: BasketService, private loginService: LoginService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  @Output() onOk = new EventEmitter<string>();
  message: string = "";
  get SelectedAdresid() {
    if (this.basketService.LeverenEncap != undefined && this.basketService.LeverenEncap.SelectedAdres != undefined)
      return this.basketService.LeverenEncap.SelectedAdres.Nr;
      return "";
  }
  get SelectedAdres(): mKlantAfleveradres|undefined {
    if(this.basketService.LeverenEncap != undefined)
      return this.basketService.LeverenEncap.SelectedAdres;
    return undefined;
  }
  get Leveren() {
    if (this.basketService.LeverenEncap == undefined)
      return false;
    return this.basketService.LeverenEncap.Leveren;
  }
  set Leveren(val: boolean) {
    if (this.basketService.LeverenEncap != undefined)
    this.basketService.LeverenEncap.Leveren = val;
  }
  get subtotal() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.SubTotal;
    return "0.00";
  }
  get verzendkosten() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.Verzendkosten;
    return "0.00";
  }
  get btw() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.BTW;
    return "0.00";
  }
  get total() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.Total;
    return "0.00";
  }

  get BasketItems() {
    if (this.basketService.BasketItems != undefined)
      return this.basketService.BasketItems;

    return [];
  }
  get AfleverAdressen() {
    if (this.basketService.LeverenEncap == undefined)
      this.basketService.onGetAfleveradressen(this.loginService.login);

    return this.basketService.AfleverAdressen;
  }
  onBestelClick() {
    var a = this.SelectedAdres;
    if (this.Leveren && this.SelectedAdres == undefined)
      this.message = "Gelieve een afleveradres of afhalen te selecteren";
    else {
      this.message = "";
      this.onOk.emit();
    }
  }
  selectoption(adresNr: string) {
    var adres = undefined;
    this.basketService.AfleverAdressen.forEach(a => {
      if (a.Nr.trim() == adresNr.trim()) {
        adres = a;
      }
    });
    if (adres != undefined && this.basketService.LeverenEncap != undefined) {
      this.basketService.LeverenEncap.SelectedAdres = adres;
    }
  }
  translate(val: string) {
    return this.languageService.translate(val);
  }

  translateOMS(val: mBasketItem) {
    if (val == undefined || val.Artikel == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return val.Artikel.Artikel.OmschrijvijngNL;
    else
      return val.Artikel.Artikel.OmschrijvijngFR;
  }
  translateGroep(val: mBasketItem) {
    if (val == undefined || val.Artikel == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return val.Artikel.Artikel.GroepNL;
    else
      return val.Artikel.Artikel.GroepFR;
  }
}
