import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseService } from './response.service';
import { mArtikelPrijs } from '../models/prijs/martikelprijs';
import { mLogin } from '../models/login/login';
import { LoginService } from './login.service';
import { mSearchAddFavoriet } from '../models/search/search';
import { BasketService } from './basket.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class FavorietenService {

  login?: mLogin;
  favorieten: mArtikelPrijs[] = [];

  private apiUrlGetFav = this.responseService.apiString + "/menu/getfav";
  private apiUrlAddFav = this.responseService.apiString + "/menu/addfav";
  private apiUrlEditFav = this.responseService.apiString + "/menu/editfav";
  private apiUrlDelFav = this.responseService.apiString + "/menu/delfav";

  constructor(private http: HttpClient, private responseService: ResponseService, private basketService: BasketService) { }

    GetFavorieten() {
        if (this.login != undefined && this.login.Succes != undefined && this.login.Succes != false) {
            this.getFav(this.login).subscribe((favorieten) => {
                this.AssignOnReturn(favorieten);
                this.basketService.onGetBasket();
            });;
        }
    }
    AddFavoriet(newFav: mArtikelPrijs) {
        if (this.login != undefined && this.login.Succes != undefined && this.login.Succes != false && newFav != undefined) {
            var input = new mSearchAddFavoriet(this.login, newFav, 1);
            this.addFav(input).subscribe((favorieten) => {
                this.AssignOnReturn(favorieten);
            });;
        }
    }
    EditFavoriet(EditFav: mArtikelPrijs) {
        if (this.login != undefined && this.login.Succes != undefined && this.login.Succes != false && EditFav != undefined) {
            var input = new mSearchAddFavoriet(this.login, EditFav, EditFav.Favoriet!.Aantal);
            this.editFav(input).subscribe((favorieten) => {
                this.AssignOnReturn(favorieten);
            });;
        }
    }
    DeleteFavoriet(DelFav: mArtikelPrijs) {
        if (this.login != undefined && this.login.Succes != undefined && this.login.Succes != false && DelFav != undefined) {
            var input = new mSearchAddFavoriet(this.login, DelFav, 0);
            this.delFav(input).subscribe((favorieten) => {
                this.AssignOnReturn(favorieten);
            });;
        }
    }

    private getFav(oLogin: mLogin): Observable<mArtikelPrijs[]> {
        httpOptions.headers =
          httpOptions.headers.set('authorization', this.login!.Hash);

        return this.http.post<mArtikelPrijs[]>(this.apiUrlGetFav, oLogin, httpOptions);
    }
    private addFav(input: mSearchAddFavoriet): Observable<mArtikelPrijs[]> {
      httpOptions.headers =
          httpOptions.headers.set('authorization', this.login!.Hash);

        return this.http.post<mArtikelPrijs[]>(this.apiUrlAddFav, input, httpOptions);
    }
    private editFav(input: mSearchAddFavoriet): Observable<mArtikelPrijs[]> {
      httpOptions.headers =
          httpOptions.headers.set('authorization', this.login!.Hash);

        return this.http.post<mArtikelPrijs[]>(this.apiUrlEditFav, input, httpOptions);
    }
    private delFav(input: mSearchAddFavoriet): Observable<mArtikelPrijs[]> {
      httpOptions.headers =
          httpOptions.headers.set('authorization', this.login!.Hash);

        return this.http.post<mArtikelPrijs[]>(this.apiUrlDelFav, input, httpOptions);

    }

    AssignOnReturn(Favorieten: mArtikelPrijs[]) {
        if (this.favorieten == undefined)
            this.favorieten = [];
        while (this.favorieten.length > 0) {
            this.favorieten.pop();
        }
        Favorieten.forEach(element => {
            this.favorieten.push(element);

        });
    }
    EmptyCurrent() {
        if (this.favorieten != undefined) {
            var prices = this.favorieten.slice();
            if (this.favorieten == undefined)
                this.favorieten = [];

            while (this.favorieten.length > 0) {
                this.favorieten.pop();
            }
        }
    }
}
