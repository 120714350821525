import { Component, OnInit } from '@angular/core';
import { mRegister } from '../../models/login/register';
import { LoginService } from '../../services/login.service';
import { TypeKlant, Language } from '../../models/enum';
import { LanguageService } from '../../services/language.service';

declare var $: any;

@Component({
  selector: 'app-logorregis',
  templateUrl: './logorregis.component.html',
  styleUrls: ['./logorregis.component.css']
})
export class LogorregisComponent implements OnInit {

  constructor(private loginService: LoginService, private languageService: LanguageService) {
    this.mRegister = new mRegister();
    this.mRegister.TypeKlant = TypeKlant.Tandarts;
    this.bSend = false;
  }

  login: string = "";
  paswoord: string = "";
  remember: boolean = true;

  forgotemail: string = "";

  mRegister: mRegister;
  bSend: Boolean = false;
  bSendPW: Boolean = false;
  bReminderSend: Boolean = false;

  get Login() {
    return this.loginService.login;
  }
  get loggedin() {
    return this.loginService.login != undefined && this.loginService.login.Succes != undefined && this.loginService.login.Succes;
  }
  onLogin() {
    this.loginService.onLogin(this.login, this.paswoord, this.remember);
  }

  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }
  onSendRegister() {
    if (this.mRegister.Voornaam != undefined && this.mRegister.Naam != undefined &&
      this.mRegister.Email != undefined && this.mRegister.Straat != undefined &&
      this.mRegister.Huisnummer != undefined && this.mRegister.Postcode != undefined &&
      this.mRegister.Gemeente != undefined && this.mRegister.GSM != undefined) {
      var type = this.mRegister.TypeKlant;
      this.loginService.onRegister(this.mRegister);
      this.bSend = true;
      this.mRegister = new mRegister();
      this.mRegister.TypeKlant = type;
    }
  }
  onForgotEmail() {
    this.loginService.onForgotEmail(this.forgotemail, this.languageService.Taal == Language.NL);
    this.bReminderSend = true;
  }
  typeChange(type: string) {
    if (type = "T")
      this.mRegister.TypeKlant = TypeKlant.Labo;
    else
      this.mRegister.TypeKlant = TypeKlant.Tandarts;
  }

  translate(val: string) {
    return this.languageService.translate(val);
  }
}
