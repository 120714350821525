<main role="main">
  <section>
    <article>
      <div [hidden]="!isStap1">
        <div class="section background-white padding">
          <div class="line">
            <!-- Catalogus -->
            <div class="line">
              <h2 class="text-size-30 text-s-size-12 text-green">
                <em>{{translate('Inhoud winkelwagen')}}!</em>
              </h2>
              <hr class="break break-small background-primary">
              <div class="box margin-bottom">
                <div class="margin">
                  <div class="s-12">
                    <div class="line">
                      <div class="line">
                        <app-webshopbasketdetail></app-webshopbasketdetail>
                        <div class="line">
                          <div class="margin">
                            <hr class="break text-dark">
                            <div class="s-12 m-12 l-6 background-green block-bordered border-radius">
                              <form class="customform" action="">
                                <div class="s-9 m-10 l-10 margin-top-15">
                                  <input class="text-size-12 text-m-size-12 background-white text-dark" [(ngModel)]="Input" name="" [placeholder]="translate('Barcode inscannen of artikelnr ingeven')"
                                    title="Barcode inscannen" type="text" [ngModelOptions]="{standalone: true}" (keypress)="onEnter($event)">
                                </div>
                                <div class="s-3 m-2 l-2 margin-top-15 text-right">
                                  <button class="submit-form button background-primary border-radius text-white text-s-size-12 text-m-size-12" type="submit"
                                    (click)="onBarcode()">{{translate('Toevoegen')}}</button>
                                </div>
                              </form>
                            </div>
                            <div class="s-6 m-6 l-3 text-right text-s-size-12 text-m-size-12">
                              <p>{{translate('Subtotaal')}}</p>
                              <p>{{translate('Verzendkosten')}}</p>
                              <p>{{translate('BTW')}}</p>
                              <h5>
                                <strong>{{translate('Totaal')}}</strong>
                              </h5>
                            </div>
                            <div class="s-6 m-6 l-3 text-right text-s-size-12 text-m-size-12">
                              <p>€ {{subtotal}}</p>
                              <p>{{verzendkosten}}</p>
                              <p>€ {{btw}}</p>
                              <h5>
                                <strong>€ {{total}}</strong>
                              </h5>
                            </div>
                            <div class="s-12">{{message}}</div>
                          </div>
                        </div>
                        <div class="line">
                          <div class="margin">
                            <hr class="break text-dark">
                            <div class="s-3 m-3 l-3 text-left text-green">
                              <strong>
                                <img src="../../../../assets/images/Allerlei/levering winkelmand.png" alt="gratis levering">
                              </strong>
                            </div>
                            <div class="s-3 m-3 l-3">
                              <h6>
                                <strong>
                                  <em>{{translate('Gratis levering voor bestellingen vanaf €100,00 excl. BTW')}}</em>
                                </strong>
                              </h6>
                              <a class="text-more-info text-green-hover" [routerLink]="getrouterlink('/webshop/tandarts')">{{translate('Nog iets vergeten! Naar de webshop')}}</a>
                              <a class="text-more-info text-green-hover" routerLink="/webshop/bestellingen">{{translate('Zie hier uw vorige bestellingen')}}</a>
                            </div>
                            <div class="s-6 m-6 l-6 text-right">
                              <button class="submit-form button background-primary border-radius text-white" type="submit" (click)="onBestelClick()">{{translate('Bestellen')}}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="background-dark padding text-center"> </div>
      </div>
      <div [hidden]="!isStap2">
        <div class="section background-white padding">
          <div class="line">
            <!-- Catalogus -->
            <div class="line" #contentToSend id="cds">
              <h2 class="text-size-30 text-s-size-12 text-green">
                <em>{{translate('Bestel Overzicht')}}</em>
              </h2>
              <hr class="break break-small background-primary">
              <div class="box margin-bottom">
                <div class="margin">
                  <div class="s-12">
                    <div class="line">
                      <div class="line">
                        <div class="s-12 text-left holder">
                          <button class="submit-form button background-primary border-radius text-white" type="submit" (click)="onBack()">{{translate('Terug naar de winkelmand')}}</button>
                        </div>
                        <div class="s-12 text-left">
                          <br>
                        </div>
                        <app-webshopbasketoverzicht (onOk)="onOk()"></app-webshopbasketoverzicht>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="!isStap3">
        <div class="section background-white padding">
          <!-- Catalogus -->
          <div class="line">
            <hr class="break break-small background-primary">
            <div class="box margin-bottom">
              <div class="margin">
                <div class="s-12">
                  <div class="line">
                    <div class="line">
                      <div class="s-12 text-left noprint">
                        <button class="submit-form button background-primary border-radius text-white" type="submit" (click)="onBack()">{{translate('Terug naar de winkelmand')}}</button>
                      </div>
                      <div class="s-12 text-left noprint">
                        <br>
                      </div>
                      <div>
                        <app-webshopbasketfinish></app-webshopbasketfinish>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</main>
