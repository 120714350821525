import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private languageService: LanguageService) { }

  ngOnInit() {
  }

  translateString(val: string){
    return this.languageService.translate(val);
  }
}
