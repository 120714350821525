<!--<app-search></app-search>
<app-login></app-login>
<app-basket></app-basket>
<app-favorites></app-favorites>-->

<div class="top-bar background-dark sticky noprint">
    <div class="line">

        <app-search></app-search>
        <app-actions></app-actions>

    </div>
</div>