<main role="main">

    <section>
        <article>
            <header class="section background-blue background-transparent text-center" data-parallax="scroll" data-image-src="../../assets/images/parallax-06.jpg">
                <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1">{{translate('Login / Registreren')}}</h1>
            </header>
            <div class="section background-white padding">
                <div class="line">
                    <div class="margin">

                    </div>
                    <div class="line"> </div>
                    <div class="margin">

                        <div class="s-12 m-12 l-5">
                            <!-- If you want to make a field required, add the "required" class to the input. -->
                            <!-- If you want to change security answer, open the file validation.js an find line 25. -->
                            <h2 class="text-size-30">
                                <em>{{translate('Nieuwe klant')}} </em>
                            </h2>
                            <hr class="break break-small background-primary">
                            <div [hidden]="bSend">
                                <form name="contactForm" #myForm="ngForm" class="customform ajax-form" method="post">
                                    <div class="line">
                                        <div class="margin">
                                            <div class="s-12">
                                                <input name="type" value="" type="radio" checked (change)="typeChange('L')" />
                                                <label>{{translate('Tandarts')}}</label>

                                            </div>
                                            <div class="s-12">
                                                <input name="type" value="" type="radio" (change)="typeChange('T')" />
                                                <label>{{translate('Tandtechnisch labo')}}</label>

                                            </div>
                                            <div class="s-12 m-12 l-6">
                                                <input name="name" class="name border-radius" [placeholder]="translate('Voornaam')" title="Uw voornaam" type="text" [(ngModel)]="mRegister.Voornaam"
                                                />
                                                <p class="name-error form-error">{{translate('Vul hier uw voornaam in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-6">
                                                <input name="name" class="name border-radius required" [placeholder]="translate('Naam')" title="Uw naam" type="text" [(ngModel)]="mRegister.Naam"
                                                />
                                                <p class="name-error form-error">{{translate('Vul hier uw naam.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-12">
                                                <input name="email" class="required email border-radius" [placeholder]="translate('Uw e-mail')" title="Uw e-mail" type="text"
                                                    [(ngModel)]="mRegister.Email" />
                                                <p class="email-error form-error">{{translate('Plaats hier uw e-mail.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-12">
                                                <input name="name" class="name border-radius" [placeholder]="translate('België')" title="Uw land" type="text" [(ngModel)]="mRegister.Land"
                                                />
                                                <p class="name-error form-error">{{translate('Vul hier uw land in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-6">
                                                <input name="name" class="name border-radius required" [placeholder]="translate('Straat')" title="Uw straat" type="text"
                                                    [(ngModel)]="mRegister.Straat" />
                                                <p class="name-error form-error">{{translate('Vul hier uw straat in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-6">
                                                <input name="name" class="name border-radius required" [placeholder]="translate('Huisnummer')" title="Uw huisnummer" type="text"
                                                    [(ngModel)]="mRegister.Huisnummer" />
                                                <p class="name-error form-error">{{translate('Vul hier uw huisnummer in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-6">
                                                <input name="name" class="name border-radius required" [placeholder]="translate('Postcode')" title="Uw postcode" type="text"
                                                    [(ngModel)]="mRegister.Postcode" />
                                                <p class="name-error form-error">{{translate('Vul hier uw postcode in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-6">
                                                <input name="name" class="name border-radius required" [placeholder]="translate('Woonplaats')" title="Uw woonplaats" type="text"
                                                    [(ngModel)]="mRegister.Gemeente" />
                                                <p class="name-error form-error">{{translate('Vul hier uw woonplaats in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-12">
                                                <input name="name" class="name border-radius" [placeholder]="translate('Telefoonnummer')" title="Uw telefoonnummer" type="text"
                                                    [(ngModel)]="mRegister.TelefoonNummmer"/>
                                                <p class="name-error form-error">{{translate('Vul hier uw Telefoonnummer in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-12">
                                                <input name="name" class="name border-radius required" [placeholder]="translate('GSM')" title="Uw GSM" type="text" [(ngModel)]="mRegister.GSM"
                                                />
                                                <p class="name-error form-error">{{translate('Vul hier uw GSM in.')}}</p>
                                            </div>
                                            <div class="s-12 m-12 l-12">
                                                <input name="name" class="name border-radius" [placeholder]="translate('BTW Nummer')" title="Uw BTW nummer" type="text" [(ngModel)]="mRegister.BtwNr"
                                                />
                                                <p class="name-error form-error">{{translate('Vul hier uw BTW Nummer in.')}}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="s-12 m-12 l-4">
                                        <button class="submit-form button background-primary border-radius text-white" type="submit" [disabled]="!myForm.form.valid"
                                            (click)="onSendRegister()">{{translate('Verzenden')}}</button>
                                    </div>


                                </form>
                            </div>
                            <div [hidden]="!bSend">{{translate('Uw aanvraag is verzonden')}}.</div>
                            <div class="line">
                                <p class="mail-success form-success" [hidden]="!bSend">{{translate('Uw bericht werd succesvol verzonden.')}}</p>
                            </div>
                        </div>
                        <div class="s-12 m-12 l-2">&nbsp;</div>
                        <div class="s-12 m-12 l-5">
                            <div [hidden]="loggedin">
                                <h2 class="text-size-30">
                                    <em>{{translate('Reeds klant')}} </em>
                                </h2>
                                <hr class="break break-small background-primary">
                                <!-- If you want to make a field required, add the "required" class to the input. -->
                                <!-- If you want to change security answer, open the file validation.js an find line 25. -->
                                <form name="contactForm" class="customform ajax-form" method="post" action="email.php">
                                    <div class="line">
                                        <div class="margin">
                                            <div class="s-12 m-12 l-6">
                                                <input name="" class="required border-radius" [placeholder]="translate('Login')" title="Login" type="text" [(ngModel)]="login"
                                                    [ngModelOptions]="{standalone: true}" />
                                                <p class="message-error form-error">{{translate('Vul hier uw login in.')}}</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="s-12 m-12 l-6">
                                        <input name="" class="required border-radius" [placeholder]="translate('Paswoord')" title="Paswoord" type="text" [(ngModel)]="paswoord"
                                            [ngModelOptions]="{standalone: true}" />
                                        <p class="message-error form-error">{{translate('Vul hier uw paswoord in.')}}</p>
                                    </div>
                                    <div class="s-12">
                                        <input name="" value="" type="checkbox" [(ngModel)]="remember" [ngModelOptions]="{standalone: true}" />
                                        <label>{{translate('Onthou me')}}</label>

                                    </div>
                                    <div class="s-12 m-12 l-4">
                                        <button class="submit-form button background-primary border-radius text-white" type="submit" (click)="onLogin()">{{translate('Inloggen')}}</button>

                                    </div>
                                </form>
                            </div>
                            <div class="s-12 m-12 l-12">
                                <div [hidden]="bReminderSend">
                                    <h2 class="text-size-30">
                                        <em>{{translate('Paswoord vergeten:')}} </em>
                                    </h2>
                                    <hr class="break break-small background-primary">

                                    <form name="contactForm" class="customform ajax-form">
                                        <div class="line">
                                            <div class="margin">
                                                <div class="s-12 m-12 l-12">
                                                    <p class="text-size-14">{{translate('Ben je je')}}
                                                        <strong>{{translate('paswoord')}}</strong> {{translate('vergeten? Vul hieronder je')}}
                                                        <strong>{{translate('e-mailadres')}}</strong> {{translate('in.')}}
                                                        <br>
                                                        <br>
                                                    </p>
                                                    <input name="email" class="required email border-radius" [placeholder]="translate('Uw e-mail')" title="Uw e-mail" type="text"
                                                        [(ngModel)]="forgotemail" />
                                                    <p class="email-error form-error">{{translate('Plaats hier uw e-mail.')}}</p>
                                                    <div class="s-12 m-12 l-12">
                                                        <button class="submit-form button background-primary border-radius text-white" (click)="onForgotEmail()" type="submit">{{translate('Verzenden')}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="line">

                                        </div>
                                    </form>
                                </div>
                                <div [hidden]="!bReminderSend">
                                    <p>{{translate('Uw bericht werd succesvol verzonden.')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Icoontjes -->
            </div>
            <div class="background-dark padding text-center"> </div>
        </article>


    </section>

</main>
