<div class="s-12 m-4 l-4">
  <div class="table" id="results">
    <div class='theader'>
      <div class='table_header'>&nbsp;{{translate('Nummer')}}</div>
      <div class='table_header'>{{translate('Datum')}}</div>
      <div class='table_header'>{{translate('Geleverd')}}<input type="checkbox" [checked]="filtered" (change)="filtered = !filtered"></div>
    </div>
    <div [ngClass]="getClass(item)" *ngFor="let item of BestellingenItems" (click)="onclick(item)"   style="cursor: pointer;">
      <div class='table_small'>
        <div class='table_cell'>{{translate('Nummer')}}</div>
        <div class='table_cell'>{{item.BestelNummer}}</div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Datum')}}</div>
        <div class='table_cell'>{{item.Datum}}</div>
      </div>
      <div class='table_small'>
        <div class='table_cell'>{{translate('Geleverd')}}</div>
        <div class='table_cell'><input type="checkbox" [checked]="item.Ok" disabled></div>
      </div>
    </div>
  </div>
</div>
<div class="s-12 m-8 l-8">
  <app-webshopbestellingenitemdetails></app-webshopbestellingenitemdetails>
</div>