<main role="main">

  <section>
    <article>
      <header class="section background-green-hightlight background-transparent text-center background-blue" data-parallax="scroll"
        data-image-src="../../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1 text-s-size-16">Privacy Statement</h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue">
              <a routerLink="/NL/home" target="_self"> Home</a>
            </p>
            <hr class="break break-small background-primary">
            <p>Uw persoonlijke gegevens worden opgeslagen in een databestand van Providental.
              <br> Providental garandeert dat uw gegevens uitsluitend voor het toezenden van informatie zal worden gebruikt,
              en zal niet ter beschikking worden gesteld aan derden. </p>
            <div class="box margin-bottom">
              <div class="margin">
                <!-- CONTENT -->

                <!-- ASIDE NAV -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Kleurbalk -->
      <div class="background-dark padding text-center"> </div>

    </article>


  </section>

</main>