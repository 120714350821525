import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-homeFR',
  templateUrl: '../../../assets/html/homeFR.html',
  styleUrls: ['./homeFR.component.css']
})
export class HomeFRComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    /*$('[data-parallax="scroll"]').each(function () {
      var $this = $(this);
      $this.parallax({ dataImageSrc: $this.data('image-srcFR') });
    });
    let options = {
      strings: ["de petit équipement", "de nouveaux produits", "de matériels promotionnel"],
      typeSpeed: 100,
      backSpeed: 100,
      showCursor: true,
      cursorChar: "|",
      loop: true
    }

    let typed = new Typed("#typed", options);    */

    $(".carousel-default").owlCarousel({
   	 navigation : true,
   	 slideSpeed : 300,
   	 paginationSpeed : 400,
   	 autoPlay : true,
     addClassActive: true,
     navigationText: ["&#xf007","&#xf006"],
   	 singleItem:true
  });

  // Owl Carousel - Content Blocks
  $(".carousel-blocks").owlCarousel({
     slideSpeed: 300,
     autoPlay: false,
     navigation: true,
     navigationText: ["&#xf007","&#xf006"],
     pagination: false,
     addClassActive: true,
     items: 5,
     itemsDesktop: [768,3],
     itemsDesktopSmall: [480,1]
  });

  $(".carousel-fade-transition").owlCarousel({
   	 navigation : true,
   	 slideSpeed : 300,
   	 paginationSpeed : 400,
   	 autoPlay : true,
     addClassActive: true,
     navigationText: ["&#xf007","&#xf006"],
   	 singleItem:true,
     transitionStyle : "fade"
  });
  }
}

