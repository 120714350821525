import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../services/login.service';
import { FavorietenService } from '../../../../services/favorieten.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {

  constructor(private loginService: LoginService, private favorietenService: FavorietenService, private languageService: LanguageService) { }

  ngOnInit() {
  }
  get AantalItems() {
    if (this.favorietenService.favorieten != undefined && this.favorietenService.favorieten.length > 0)
      return this.favorietenService.favorieten.length.toString();

    return "0";
  }

  get NotLogged(): boolean {
    return this.loginService.login == undefined || this.loginService.login.Succes == undefined || this.loginService.login.Succes == false;
  }

  translate(val: string) {
    return this.languageService.translate(val);
  }
}
