import { mArtikelPrijs } from "../prijs/martikelprijs";
import { mLogin } from "../login/login";

export class Menu {
    public Nr: string = "";
    public OmschrijvingNL: string = "";
    public OmschrijvingFR: string = "";
    public FotoUrl: string = "";
    public SubMenu: Menu[] = [];
    public Artikels: mArtikelPrijs[] = [];

    constructor(_Nr: string, _OmschrijvingNL: string, _OmschrijvingFR: string, _FotoUrl: string) {
      this.Nr = _Nr;
      this.OmschrijvingNL = _OmschrijvingNL;
      this.OmschrijvingFR = _OmschrijvingFR;
      this.FotoUrl = _FotoUrl;
    }
  }
  export class mMenuType{
    public TypeCode: string;
    public Login: mLogin|undefined;
    constructor(_TypeCode: string, _Login?: mLogin){
      this.TypeCode = _TypeCode;
      this.Login = _Login;
    }
  }
