import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-laboFR-header',
  templateUrl: '../../../../../assets/html/webshoplaboFR.html',
  styleUrls: ['./headerFR.component.css']
})
export class LaboHeaderFRComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }

}
