<li class="text-left yyy">
  <a [hidden]="loggedin">
    <div id="logintrigger">
      <a routerLink="/login">
        <i class="icon-sli-user text-white"></i>
      </a>
      <div class="inlbl">
        <span [hidden]="loadingLogin" class=""> {{translateString('Inloggen')}}</span>
      </div>
      <div class="inlbl">
        <span [hidden]="!loadingLogin">
          <p class="saving">Loading
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </p>
        </span>
      </div>
    </div>
  </a>
  <a id="myBtn" [hidden]="!loggedin">
    <div>
      <i class="icon-sli-user text-white"></i>
      <span class="disflip"> {{getLogin}}</span>
    </div>
  </a>
  <div id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
      <span class="close">&times;</span>
      <div>
        <button class="submit-form button background-primary border-radius text-white" (click)="logout()" style="width:100%">{{translateString('Log Out')}}</button>
      </div>
    </div>

  </div>

  <!--<span class="hide-s hide-m">Inloggen</span>-->
  <span class="">
    <span id="popuphover" style="display: none; top: 119.56px; left: 767.352px;">
      <span class="line">
        <div>
          <span id="mpopupcontainer" class="margin popupcontainer" style="width:250px; margin-left:-75px; position:relative;">
            <span class="s-12">
              <br>

              <form name="contactForm" class="customform ajax-form">
                <div class="line">
                  <div class="margin">
                    <div class="s-12 m-12 l-12">
                      <input name="" class="required border-radius background-white" [placeholder]="translateString('Login')" title="Login" type="text"
                        [(ngModel)]="login" [ngModelOptions]="{standalone: true}">
                      <p class="message-error form-error">{{translateString('Vul hier uw login in.')}}</p>
                    </div>

                  </div>
                </div>
                <div class="s-12 m-12 l-12">
                  <input name="" class="required border-radius background-white" [placeholder]="translateString('Paswoord')" title="Paswoord"
                    type="password" [(ngModel)]="paswoord" [ngModelOptions]="{standalone: true}">
                  <p class="message-error form-error">{{translateString('Vul hier uw paswoord in.')}}</p>
                </div>
                <div class="s-12">
                  <input name="" value="" type="checkbox" [(ngModel)]="remember" [ngModelOptions]="{standalone: true}">
                  <label>{{translateString('Onthou me')}}</label>

                </div>

                <div class="s-12 m-12 l-12">
                  <button class="submit-form button background-primary border-radius text-white" (click)="onLogin()">{{translateString('Login')}}</button>
                  <p>
                    <a routerLink="/login" class="text-primary-hover">
                      <b>{{translateString('Registreer hier!')}}</b>
                    </a>
                  </p>
                  <div class="s-1" style="text-align:left">
                    <a class="closer" (click)="closepopup()">
                      <i class="icon-cross_mark"></i>
                    </a>
                  </div>
                  <div class="s-11">
                    <p>
                      <a routerLink="/login" class="text-primary-hover">
                        <b>{{translateString('Paswoord vergeten!')}}</b>
                      </a>
                    </p>
                  </div>
                </div>
              </form>

            </span>
            <br>
          </span>
        </div>
      </span>
    </span>
  </span>
</li>