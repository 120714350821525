import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { Observable,throwError, of  } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class ResponseService {

//public apiString = "http://localhost:51744/api";
    //OLD//public apiString = "http://provi.datasupport.biz/api";
    //public apiString = "http://providentalliveapi.datasupport.biz/api";
  public apiString = "https://api.providental.be/api";

  messages: string[] = [];

    constructor(private http: HttpClient) { }

    public extractData(res: Response) {
        if (res.status < 200 || res.status >= 300) {
            throw new Error('Bad response status: ' + res.status);
        }
        let body = res.json();
        return body || {};
    }


    createHandleError = (serviceName = '') =>
    <T>(operation = 'operation', result = {} as T) =>
      this.handleError(serviceName, operation, result);

      handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {

        return (error: HttpErrorResponse): Observable<T> => {
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead

          const message = (error.error instanceof ErrorEvent) ?
            error.error.message :
           `server returned code ${error.status} with body "${error.error}"`;

          // TODO: better job of transforming error for user consumption
          this.messages.push(`${serviceName}: ${operation} failed: ${message}`);

          // Let the app keep running by returning a safe result.
          return of( result );
        };

      }
}
