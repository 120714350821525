import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { mSearchOutput } from '../../../../models/search/search';
import { RouterparamService } from '../../../../services/routerparam.service';
import { TypeResult } from '../../../../models/enum';
import { BasketService } from '../../../../services/basket.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-searchbarpopupitem',
  templateUrl: './searchbarpopupitem.component.html',
  styleUrls: ['./searchbarpopupitem.component.css']
})
export class SearchbarpopupitemComponent implements OnInit {

  constructor(private RouterparamService: RouterparamService, private basketService: BasketService, private languageService: LanguageService) { }

  ngOnInit() {
  }
  @Input() Artikel: mSearchOutput;
  @Output() onClicked = new EventEmitter();

  ishidden(output: mSearchOutput) {
    if (output == undefined || output.FotoUrl == undefined || output.FotoUrl.trim() == "")
      return true;
    else
      return false;
  }

  onnavigatex(item: mSearchOutput) {
    var ar = [];
    ar.push(item.ID);
    this.RouterparamService.Reset();
    this.RouterparamService.Routeer(ar, item.Type, item.TypeCode);
    this.onClicked.emit();
  }
  onNavHead()
  {
    var ar = [];
    ar.push(this.Artikel.ID);
    this.RouterparamService.Reset();
    this.RouterparamService.Routeer(ar, this.Artikel.Type, this.Artikel.TypeCode);
    this.onClicked.emit();
  }
  onMeer(){
    var ar:string[] = [];
    this.Artikel.Children.forEach(element => {
      ar.push(element.ID);
    });
    this.RouterparamService.Routeer(ar, TypeResult.Artikel, "");
    this.onClicked.emit();
  }

  onadd(item: mSearchOutput){
    this.basketService.AddToBasketID(item.ID);
    this.RouterparamService.RouteerWinkelmand();
  }

  translate(val: string){
    return this.languageService.translate(val);
  }
}
