<main role="main">
      
        <section>
          <article>
          <header class="section background-green-hightlight background-transparent text-center background-blue" data-parallax="scroll" data-image-src="../../../assets/images/parallax-06.jpg">
            <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1 text-s-size-16">Privacy Statement</h1>
          </header>
            <div class="section background-white padding"> 
            <div class="line"> <!-- Catalogus -->	
              
              <div class="line">
                    <p class="icon-home text-blue">
                            <a routerLink="/FR/home" target="_self"> Home</a>
                          </p>
                <hr class="break break-small background-primary">
                  <p>Vos données personnelles sont stockées dans une base de données Providental. <br>
                    Providental garantit que vos données ne seront utilisées que pour l'envoi d'informations et ne seront pas mises à la disposition de tiers.  </p>		
              <div class="box margin-bottom">
                <div class="margin">
                    <!-- CONTENT -->
                     
                   <!-- ASIDE NAV --></div>
              </div>       
  </div>
  </div>
              </div>
                   <!-- Kleurbalk -->
        <div class="background-dark padding text-center"> </div>
              
          </article>
          
          
        </section>
        
        </main>