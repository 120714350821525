import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ResponseService } from './response.service';
import { mLogin } from '../models/login/login';
import { MenuService } from './menu.service';
import { ArtikelService } from './artikel.service';
import { BasketService } from './basket.service';
import { FavorietenService } from './favorieten.service';
import { RouterparamService } from './routerparam.service';
import { mRegister } from '../models/login/register';
import { BestellingenService } from './bestellingen.service';
import { mContact } from '../models/contact/contact';
import { mPaswoord } from '../models/contact/paswoord';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};

@Injectable()
export class LoginService {

    constructor(private http: HttpClient, private cookieService: CookieService, private responseService: ResponseService,
        private artikelService: ArtikelService, private basketService: BasketService, private favorietenService: FavorietenService,
        private bestellingenService: BestellingenService, private routerparamService: RouterparamService) {
        this.login = new mLogin();

        var oCookie1 = cookieService.get("dsPRLogin");
        var oCookie2 = cookieService.get("dsPRPass");

        if (oCookie1 != undefined && oCookie2 != undefined && oCookie1 != "" && oCookie2 != "") {
            this.onLogin(oCookie1, oCookie2, false);
        }
    }

    login?: mLogin;

    private apiUrl = this.responseService.apiString + "/login/login";
    private apiUrlRegis = this.responseService.apiString + "/login/register";
    private apiUrlContact = this.responseService.apiString + "/login/contact";
    private apiUrlForgot = this.responseService.apiString + "/login/forgot";
    get loggedon() {
        if (this.login != undefined && this.login.Succes)
            return true;

        return false;
    }
    onForgotEmail(email: string, isNL: boolean) {
        var pas = new mPaswoord();
        pas.Email = email;
        pas.isNL = isNL;
        this.forgotEmail(pas).subscribe(() => { });
    }
    onRegister(mRegister: mRegister) {
        this.register(mRegister).subscribe(() => { });;
    }
    onLogin(user: string, paswoord: string, remember: boolean) {
        if (this.login == undefined)
            this.login = new mLogin();

        this.login.Login = user;
        this.login.Password = paswoord;

        this.checkLogin(this.login).subscribe({
          next:(_login) => { this.login = _login; },
          complete:() => {
            if (remember) {
                this.writeCookie()
            }
            this.artikelService.login = this.login!;
            this.basketService.login = this.login;
            this.favorietenService.login = this.login;
            this.bestellingenService.login = this.login;

            if (this.artikelService.requestedPrices != undefined && this.artikelService.requestedPrices.length > 0)
                this.artikelService.GetArtikels(this.artikelService.requestedPrices.slice());
            else {
                this.favorietenService.GetFavorieten();
                this.bestellingenService.GetBestellingen();
            }
        }
        });
    }
    onContact(mContact: mContact) {
        this.contact(mContact).subscribe(() => { });;
    }
    onLogout() {
        this.login = undefined;
        this.favorietenService.login = undefined;
        this.favorietenService.EmptyCurrent();
        this.bestellingenService.login = undefined;
        this.bestellingenService.EmptyCurrent();
        this.artikelService.login = undefined;
        this.artikelService.EmptyCurrent();
        this.basketService.login = undefined;
        this.basketService.Basket = undefined;
        this.basketService.LeverenEncap = undefined;
        this.basketService.EmptyCurrent();
        this.routerparamService.RouteerHome();
    }
    writeCookie() {
        if (this.login!.Succes) {
            this.cookieService.set("dsPRLogin", this.login!.Login.toString(), 30);
            this.cookieService.set("dsPRPass", this.login!.Password.toString(), 30);
        }
    }
    clearCookie() {
        this.cookieService.set("dsPRLogin", "");
        this.cookieService.set("dsPRPass", "");

    }
    getLoggedStatus() {
        return this.login!.Succes;
    }

    getLoggedHash() {
        return this.login!.Hash;
    }
    private checkLogin(login: mLogin): Observable<mLogin> {
              return this.http.post<mLogin>(this.apiUrl, login, httpOptions);
    }
    private register(mRegister: mRegister): Observable<boolean> {
      return this.http.post<boolean>(this.apiUrlRegis, mRegister, httpOptions);
    }
    private forgotEmail(email: mPaswoord): Observable<boolean> {
      return this.http.post<boolean>(this.apiUrlForgot, email, httpOptions);
    }
    private contact(mcontact: mContact): Observable<boolean> {
      return this.http.post<boolean>(this.apiUrlContact, mcontact, httpOptions);
    }
}
