<div id="prin_t">
<main role="main">

  <section>
    <article>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <h2 class="text-size-30 text-s-size-12 text-green">
              <em>{{translate('Orderbevestiging Providental')}}</em>
            </h2>
            <p> {{translate('Hierbij bevestigen wij de ontvangst van uw bestelling')}}.</p>
            <p> {{translate('Hartelijk dank voor uw vertrouwen')}}!</p>
            <div class="s-12 m-12 l-12 margin-top-20">
              <div class="line">
                <div class="margin">
                  <div class="s-2 m-2 l-2">
                    <p>
                      <strong>{{translate('Leveringswijze')}}:</strong>
                    </p>
                    <p>
                      <strong>{{translate('Leveringsadres')}}:</strong>
                    </p>
                  </div>
                  <div class="s-6 m-6 l-6">
                    <p class="text-green">
                      <strong>{{getLeveringsType()}}</strong>
                    </p>
                    <p>{{translate('Klantnummer')}}:
                      <strong> {{getKlantNr()}}</strong>
                    </p>
                    <p>{{translate('Naam')}}:
                      <strong> {{getKlantNaam()}}</strong>
                    </p>
                    <p>{{translate('Adres')}}:
                      <strong> {{getAdres()}}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr class="break break-small background-primary">
            <div class="table" id="results">
              <div class='theader'>
                <div class='table_header'>Artikel</div>
                <div class='table_header'>Omschrijving</div>
                <div class='table_header'>Merk</div>
                <div class='table_header'>Artikelnummer</div>
                <div class='table_header'>Inhoud</div>
                <div class='table_header'>Aantal</div>
                <div class='table_header'>Prijs</div>
                <div class='table_header'>Totaal</div>
              </div>

              <div class='table_row' *ngFor="let item of BasketItems">
                <div class='table_small'>
                  <div class='table_cell'>&nbsp;Artikel</div>
                  <div class='table_cell'>
                    <img class="mini" [src]="item.Artikel!.Artikel.FotoUrl" [alt]="item.Artikel!.Artikel.OmschrijvijngNL">
                  </div>
                </div>
                <div class='table_small'>
                  <div class='table_cell'>Omschrijving</div>
                  <div class='table_cell'>{{item.Artikel!.Artikel.OmschrijvijngNL}}</div>
                </div>
                <div class='table_small'>
                  <div class='table_cell'>Merk</div>
                  <div class='table_cell'>{{item.Artikel!.Artikel.GroepNL}}</div>
                </div>
                <div class='table_small'>
                  <div class='table_cell'>Artikelnummer</div>
                  <div class='table_cell'>{{item.Artikel!.Artikel.ProductCode}}</div>
                </div>
                <div class='table_small'>
                  <div class='table_cell'>Inhoud</div>
                  <div class='table_cell'>{{item.Artikel!.Artikel.Inhoud}}</div>
                </div>
                <div class='table_small'>
                  <div class='table_cell'>Aantal</div>
                  <div class='table_cell'>{{item.Aantal}}</div>
                </div>
                <div class='table_small'>
                  <div class='table_cell'>EH. Prijs</div>
                  <div class='table_cell'>{{item.Artikel!.Prijs!.Prijs}}</div>
                </div>
                <div class='table_small'>
                  <div class='table_cell'>Totaal</div>
                  <div class='table_cell'>{{item.Totaal}}</div>
                </div>
              </div>
            </div>
            <div class="s-12 text-left">
              <br>
              <br>
            </div>
            <div class="s-12">
              <div class="s-8">
                <br>
              </div>
              <div class="s-4">
                <div class="s-6 text-right text-s-size-12 text-m-size-12">
                  <p>Subtotaal</p>
                  <p>Verzendkosten</p>
                  <p>BTW</p>
                  <h5>
                    <strong>Totaal</strong>
                  </h5>
                </div>
                <div class="s-6 text-right text-s-size-12 text-m-size-12">
                  <p>€ {{subtotal}}</p>
                  <p>{{verzendkosten}}</p>
                  <p>€ {{btw}}</p>
                  <h5>
                    <strong>€ {{total}}</strong>
                  </h5>
                </div>
                <div class="s-6 text-right text-s-size-12 text-m-size-12">
                </div>
              </div>
            </div>
            <div class="s-12 text-right noprint">
                <button class="submit-form button background-primary border-radius text-white" type="submit" onClick="window.print()">Print</button>
              </div>
          </div>
        </div>
      </div>
      <!-- Kleurbalk -->

    </article>


  </section>

</main>
</div>
