import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { mArtikelPrijs } from '../../../models/prijs/martikelprijs';
import { BasketService } from '../../../services/basket.service';
import html2canvas from "html2canvas"
import { LanguageService } from '../../../services/language.service';
import { Language } from '../../../models/enum';

@Component({
  selector: 'app-webshopbasket',
  templateUrl: './webshopbasket.component.html',
  styleUrls: ['./webshopbasket.component.css']
})
export class WebshopbasketComponent implements OnInit {

  constructor(private basketService: BasketService, private languageService: LanguageService) { }

  ngOnInit() {

  }

  @ViewChild('contentToSend',{static:true}) el!:ElementRef<HTMLImageElement>;

  Input: string ="";
  Aantal: number = 1;
  get message(): string {
    return this.basketService.message;
  }
  get subtotal() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.SubTotal;
    return "0.00";
  }
  get verzendkosten() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.Verzendkosten;
    return "0.00";
  }
  get btw() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.BTW;
    return "0.00";
  }
  get total() {
    if (this.basketService.Basket != undefined)
      return this.basketService.Basket.Total;
    return "0.00";
  }
  onBarcode() {
    this.basketService.onZoekBarcode(this.Input, this.Aantal);
    this.Input = "";
  }
  private show = true;
  onEnter(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.onBarcode();
    }
  }
  onBack() {
    this.isStap1 = true;
    this.isStap2 = false;
    this.basketService.OrderSucceeded = false;
  }
  onBestelClick() {
    this.isStap1 = false;
    this.isStap2 = true;
    this.basketService.OrderSucceeded = false;
  }
  isStap1: boolean = true;
  isStap2: boolean = false;
  get isStap3(): boolean {
    var ook = this.basketService.OrderSucceeded;
    if (ook) {
      this.isStap1 = false;
      this.isStap2 = false;
    }
    return ook;
  }
  onOk() {
    this.isStap1 = false;
    this.isStap2 = false;
    var basketService = this.basketService;
    //var element = document.getElementById("cds");
    var bool = this.languageService.Taal == Language.NL;
    //element.innerHTML = element.innerHTML.replace("holder", "nodisplay");

    html2canvas(this.el.nativeElement).then((canvas)=>{
      var img = canvas.toDataURL()
      basketService.onOrder(img, bool);
    })

    // html2canvas(element!).then(function (canvas:any) {
    //   //document.body.appendChild(canvas);
    //   var img = canvas.toDataURL()
    //   basketService.onOrder(img, bool);
    //   /*        var iframe = '<iframe src="' + img + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    //   var x = window.open();
    //   x.document.open();
    //   x.document.write(iframe);
    //   x.document.close();        */
    // });

  }
  translate(val: string) {
    return this.languageService.translate(val);
  }
  getrouterlink(val: string) {
    if (this.languageService.Taal == Language.NL)
      return "/NL" + val;
    else
      return "/FR" + val;
  }
}
