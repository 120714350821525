import { Component, OnInit, Input } from '@angular/core';
import { mArtikelPrijs } from '../../../../../models/prijs/martikelprijs';
import { BasketService } from '../../../../../services/basket.service';
import { mFavoriet } from '../../../../../models/favoriet/mfavoriet';
import { FavorietenService } from '../../../../../services/favorieten.service';
import { LoginService } from '../../../../../services/login.service';
import { ArtikelService } from '../../../../../services/artikel.service';
import { LanguageService } from '../../../../../services/language.service';
import { Language } from '../../../../../models/enum';

@Component({
  selector: 'app-singleartikelcontent',
  templateUrl: './singleartikelcontent.component.html',
  styleUrls: ['./singleartikelcontent.component.css']
})
export class SingleartikelcontentComponent implements OnInit {

  constructor(private basketService: BasketService, private favorietenService: FavorietenService, private loginService: LoginService,
     private artikelService: ArtikelService, private languageService: LanguageService) { }

  @Input() Artikel!: mArtikelPrijs;

  Aantal: number = 1;

  get Prijs(): string {
    if (this.Artikel.Prijs != undefined)
      return this.Artikel.Prijs.Prijs.toString();

    return "N/A";
  }
  get showprijs() {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Prijs > 0)
      return true;

    return false;
  }
  get showprijspoa() {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Prijs == 0)
      return false;

    return true;
  }
  get loadingPrices() {
    if (this.loginService.loggedon && !this.artikelService.loaded)
      return true;

    return false;
  }
  ngOnInit() {
  }
  onAddToBasket() {
    this.basketService.AddToBasket(this.Artikel, this.Aantal);
  }

  onFav() {
    if (this.Artikel.Favoriet == undefined) {
      this.Artikel.Favoriet = new mFavoriet(this.Artikel.Artikel.Nr, 1);
      this.favorietenService.AddFavoriet(this.Artikel);
    }
    else{
      this.Artikel.Favoriet = undefined
      this.favorietenService.DeleteFavoriet(this.Artikel);
    }
  }
  onInfo() {
    if (!this.loginService.loggedon)
    {
      document.getElementById('popuphover')!.style.display = 'inline';
      window.scrollTo(0, 0);
    }
  }
  getClassFav() {
    if (this.Artikel.Favoriet != undefined)
      return "button border-radius background-yellow text-blue margin-left icon-sli-star w100";
    else
      return "button border-radius background-green text-white margin-left icon-sli-star w100";

  }
  getPrijsPromo() {
    if (this.Artikel != undefined && this.Artikel.Prijs != undefined && this.Artikel.Prijs.Promo != undefined && this.Artikel.Prijs.Promo == true)
      return "dnone";
    else
      return "pricing-price text-size-16 text-green";
  }

  get showkorting1() {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Korting1 != undefined && this.Artikel.Prijs.Korting1 > 0)
      return true;

    return false;
  }
  get showkorting2() {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Korting2 != undefined && this.Artikel.Prijs.Korting2 > 0)
      return true;

    return false;
  }
  get Korting1(): string {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Korting1 != undefined && this.Artikel.Prijs.Korting1 > 0)
      return this.Artikel.Prijs.Korting1.toString();

    return "N/A";
  }
  get Korting2(): string {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Korting2 != undefined && this.Artikel.Prijs.Korting2 > 0)
      return this.Artikel.Prijs.Korting2.toString();

    return "N/A";
  }
  get Aantal1(): string {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Aantal1 != undefined && this.Artikel.Prijs.Aantal1 > 0)
      return this.Artikel.Prijs.Aantal1.toString();

    return "N/A";
  }
  get Aantal2(): string {
    if (this.Artikel.Prijs != undefined && this.Artikel.Prijs.Aantal2 != undefined && this.Artikel.Prijs.Aantal2 > 0)
      return this.Artikel.Prijs.Aantal2.toString();

    return "N/A";
  }
  translateOMS(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.OmschrijvijngNL;
    else
      return menu.Artikel.OmschrijvijngFR;
  }
  translateGroep(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.GroepNL;
    else
      return menu.Artikel.GroepFR;
  }
  translateBeschrijving(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.BeschrijvingNL;
    else
      return menu.Artikel.BeschrijvingFR;
  }
  translateString(val: string)
  {
    return this.languageService.translate(val);
  }
  translateInhoud(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.Inhoud;
    else
      return menu.Artikel.InhoudFR;
  }
}
