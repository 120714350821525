import { Component, OnInit } from '@angular/core';
import { BasketService } from '../../../../services/basket.service';
import { mOrder } from '../../../../models/order/order';
import { LoginService } from '../../../../services/login.service';
import { mLogin } from '../../../../models/login/login';
import { LanguageService } from '../../../../services/language.service';

declare var $: any;
declare var jquery: any;

@Component({
  selector: 'app-webshopbasketfinish',
  templateUrl: './webshopbasketfinish.component.html',
  styleUrls: ['./webshopbasketfinish.component.css']
})
export class WebshopbasketfinishComponent implements OnInit {

  constructor(private basketService: BasketService, private loginService: LoginService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  get Order(): mOrder {
    return this.basketService.Order;
  }
  get Login(): mLogin {
    return this.loginService.login;
  }
  get BasketItems() {
    if (this.basketService.Order != undefined && this.basketService.OrderItems != undefined)
      return this.basketService.OrderItems;
    else
      return [];
  }
  getLeveringsType() {
    if (this.basketService.Order == undefined)
      return "";
    if (this.basketService.Order.Leveringsgegevens.Leveren)
      return this.translate("Leveren");
    else
      return this.translate("Afhalen");
  }
  getKlantNr() {
    if (this.loginService.login == undefined)
      return "";

    return this.loginService.login.KlantNr;
  }
  getKlantNaam() {
    if (this.loginService.login == undefined)
      return "";

    return this.loginService.login.KlantNaam;
  }
  getAdres() {
    if (this.basketService.Order == undefined)
      return "";

    if (this.basketService.Order.Leveringsgegevens.Leveren)
      return this.basketService.Order.Leveringsgegevens.SelectedAdres.Adres;
    else
      return "";
  }
  get subtotal() {
    if (this.basketService.Order != undefined)
      return this.basketService.Order.Order.SubTotal;
    return "0.00";
  }
  get verzendkosten() {
    if (this.basketService.Order != undefined)
      return this.basketService.Order.Order.Verzendkosten;
    return "0.00";
  }
  get btw() {
    if (this.basketService.Order != undefined)
      return this.basketService.Order.Order.BTW;
    return "0.00";
  }
  get total() {
    if (this.basketService.Order != undefined)
      return this.basketService.Order.Order.Total;
    return "0.00";
  }

  onPrint() {
    $("prin_t").printElement();
  }

  translate(val: string) {
    return this.languageService.translate(val);
  }
}
