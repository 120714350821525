<div class="l1">
    <a (click)="onNavHead()">{{Artikel.Omschrijving}}</a>
</div>
<div>
    <div class="l2 s-12" *ngFor="let item of Artikel.Children| slice:0:3;">
        <div class="s-2">
            <img [hidden]="ishidden(item)" [src]="item.FotoUrl" alt="Afdruk" class="center" style="height:25px;">
        </div>
        <div class="s-9">
            <a (click)="onnavigatex(item)">{{item.Omschrijving}}</a>
        </div>
        <div class="s-1">
            <i class="icon-sli-basket right" (click)="onadd(item)"> </i>
        </div>
    </div>
    <div class="l2more" [hidden]="Artikel.Children.length<3" (click)="onMeer()">{{translate('Bekijk meer')}}...</div>
    <hr class="np">
</div>