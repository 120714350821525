<main role="main">

        <section>
          <article>
          <header class="section background-transparent text-center text-white" data-parallax="scroll" data-image-src="../../assets/images/parallax-04.jpg"> </header>
          <div class="section background-white padding">
            <div class="line">
              <h2 class="text-size-30"><em>Conditions de livraison</em></h2>
              <hr class="break break-small background-primary">
              <p><strong>Pour toute commande dépassant les € 100 Euro (hors TVA) nous livrons les articles franco à domicile. </strong><br>
                Les frais de port d'envoi par courrier express seront toujours à votre compte. Les produits spécialement commandés à votre demande ne sont pas repris. Nous n'acceptons les renvois que si les articles nous parviennent sans dommage et dans 10 jours.</p>
              <div class="line"> </div>
            </div>

            <!-- Icoontjes -->
            <div class="line">
              <hr class="break margin-bottom-70">
              <div class="margin">
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-pen background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">COMMENT PASSER COMMANDE</h4>
                    <p>De 08h30 à 17h00</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/hoebestellen">Plus d'info</a></div>
                </div>
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-discussion background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">conditions de vente<br>
                    </h4>
                    <p>Tous nos prix s'entendent hors TVA. <br>
                      Nous exigeons le paiement des factures dans les 10 jours, date facture.<br>
                    </p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/betalingsvoorwaarden">Plus d'info</a></div>
                </div>
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-star_empty background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">prix et ristournes</h4>
                    <p>Domiciliation 2% Vous recevez une réduction directe de 2% sur votre facture</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/kortingen">Plus d'info</a></div>
                </div>
  </div>
            </div>
  </div>
       <div class="background-dark padding text-center"> </div>
        </article>


        </section>

        </main>
