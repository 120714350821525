<div class="table" id="results">
  <div class='theader'>
    <div class='table_header'>&nbsp;{{translate('Artikel')}}</div>
    <div class='table_header'>{{translate('Omschrijving')}}</div>
    <div class='table_header'>{{translate('Merk')}}</div>
    <div class='table_header'>{{translate('Artikelnummer')}}</div>
    <div class='table_header'>{{translate('Aantal')}}</div>
    <div class='table_header'>{{translate('Geleverd')}}<input type="checkbox" [checked]="filtered" (change)="filtered = !filtered"></div>
  </div>

  <div class='table_row' *ngFor="let item of BestellingenDetailItems">
    <div class='table_small'>
      <div class='table_cell'>{{translate('Artikel')}}</div>
      <div class='table_cell'>
        <img class="mini" [src]="item.mArtikel.FotoUrl" [alt]="item.mArtikel.OmschrijvijngNL">
      </div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Omschrijving')}}</div>
      <div class='table_cell'><a class="point" (click)="onArtikelZoom(item)">{{translateOMS(item)}}</a></div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Merk')}}</div>
      <div class='table_cell'>{{translateGroep(item)}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Artikelnummer')}}</div>
      <div class='table_cell'>{{item.mArtikel.ProductCode}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Aantal')}}</div>
      <div class='table_cell'>{{item.Aantal}}</div>
    </div>
    <div class='table_small'>
      <div class='table_cell'>{{translate('Geleverd')}}</div>
      <div class='table_cell'>{{item.Geleverd}}</div>
    </div>
  </div>
</div>
