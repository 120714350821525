<header class="section background-transparent text-center disflip" data-parallax="scroll" data-image-src="../../../../assets/images/BANNER_TANDARTS.jpg">
    <h1 class="text-dark margin-bottom-0 text-size-50 text-thin text-line-height-1">Webshop</h1>
  </header>
  <div class="section background-white padding">
    <div class="line">
      <!--<div class="line">
        <div class="s-12 m-12 l-12">
          <h6>Producten bestellen /
            <a href="tandartsen2.html" target="_self">webshop tandartsen</a> /
            <a href="tandartsen2.html" target="_self">Hoofdgroepen</a>&nbsp;</h6>
        </div>
      </div>
      <div class="margin-bottom">
        <div class="s-12 m-4 l-4">
          <h2 class="text-size-30">Hoofdgroepen</h2>
          <hr class="break break-small background-primary">
        </div>
      </div>-->
    </div>
  </div>