import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../../services/menu.service';
import { Menu } from '../../../../models/menu/menu';

@Component({
  selector: 'app-labo-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class LaboContentComponent implements OnInit {

  constructor(private menuservice: MenuService) { }

  ngOnInit() {
  }

  get Menu(): Menu[] {
    if(this.menuservice.menuTandtechniek == undefined) return[];

      return this.menuservice.menuTandtechniek!;

  }
}
