import { Injectable } from '@angular/core';
import { ResponseService } from './response.service';
import { Router } from '@angular/router';
import { TypeResult, Language } from '../models/enum';
import { Menu } from '../models/menu/menu';
import { LanguageService } from './language.service';

@Injectable()
export class RouterparamService {

    constructor(private router: Router, private responseService: ResponseService, private languageService:LanguageService) { }

    public Filters: string[] = [];
    public TypeResult: TypeResult = TypeResult.None;

    public Reset() {
        this.TypeResult = TypeResult.None;
        this.Filters = [];
    }

    public Routeer(Keys: string[], Type: TypeResult, TypeCode: string) {
        this.Filters = Keys;
        this.TypeResult = Type;

        var taal = "NL";
        if (this.languageService.Taal != Language.NL)
            taal = "FR";

        if (TypeCode == "T")
            this.router.navigate(['/'+ taal + '/webshop/labo']);
        else
            this.router.navigate(['/'+ taal + '/webshop/tandarts']);
    }
    public RouteerWinkelmand() {
        var taal = "NL";
        if (this.languageService.Taal != Language.NL)
            taal = "FR";
        this.router.navigate(['/'+ taal + '/webshop/basket']);
    }
    public RouteerMenu(menu: Menu){
        var oArray = [];
        oArray.push(menu.Nr);
        this.Filters = oArray;

        this.TypeResult = TypeResult.Groep;
    }
    public RouteerHome()
    {
        var taal = "NL";
        if (this.languageService.Taal != Language.NL)
            taal = "FR";
        this.router.navigate(['/'+ taal + '/home']);
    }
}
