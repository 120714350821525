import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-kortingenFR',
  templateUrl: '../../../assets/html/kortingenFR.html',
  styleUrls: ['./kortingenFR.component.css']
})
export class KortingenFRComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }

}
