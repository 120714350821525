<br>
<div class="s-12 m-12 l-5">
  <input type="text" id="zoekinput" [placeholder]="translateString('Filter...')" title="Filter" [(ngModel)]="Input" (keyup)="onFilterDown()">
</div>
<div class="s-12 m-5 l-4">
  <select name="" class="background-white selecter" form="select" (change)="onChange($any($event.target).value)">
    <option value="" disabled selected hidden>{{translateString('Sortering')}}</option>
    <option value="TitelA">{{translateString('Titel A - Z')}}</option>
    <option value="TitelZ">{{translateString('Titel Z - A')}}</option>
    <option [hidden]="notloggedin" value="PrijsL">{{translateString('Prijs laag - hoog')}}</option>
    <option [hidden]="notloggedin" value="PrijsH">{{translateString('Prijs hoog - laag')}}</option>
    <option value="MerkA">{{translateString('Merk A - Z')}}</option>
    <option value="MerkZ">{{translateString('Merk Z - A')}}</option>
    <option value="ArtA">{{translateString('Art.nr A - Z')}}</option>
    <option value="ArtZ">{{translateString('Art.nr Z - A')}}</option>
  </select>
</div>
<div class="s-12 m-6 l-2 padtop6">
  <input name="" value="" type="checkbox" class="checker" (change)="onChecked()" [(ngModel)]="Checked">
  <label class="labeler">
    <strong class="text-red fonter">{{translateString('Promo')}}</strong>
  </label>
</div>
<div class="s-12 m-1 l-1">
  <i [ngClass]="getClass()" (click)="changeStyle()"></i>
</div>
<!--<div style="color:red">{{result}}</div>-->
