<!-- Midden -->
<main role="main">
  <section>
    <article>
      <header
        class="section background-green-hightlight background-transparent text-center background-blue"
        data-parallax="scroll"
        data-image-src="../../assets/images/parallax-06.jpg"
      >
        <h1
          class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1"
        >
          e-catalogue / pdf
        </h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue">
              <a routerLink="/FR/home" target="_self"> Home</a>
            </p>
            <h2 class="text-size-30 text-s-size-12">
              <em>E-catalogue / pdf</em>
            </h2>
            <h4
              class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye"
            >
              <a routerLink="/FR/fabrikantnieuweproducten" target="_self">
                <strong>
                  <em> Nouveau dans notre assortiment</em>
                </strong>
              </a>
            </h4>
            <h4
              class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye"
            >
              <a routerLink="/FR/fabrikantlinks" target="_self">
                <strong>
                  <em> Voici un aperçu de tous nos fabricants</em>
                </strong>
              </a>
            </h4>
            <hr class="break break-small background-primary" />
            <div class="box margin-bottom">
              <div class="margin">
                <!-- CONTENT -->

                <!-- ASIDE NAV -->

                <div class="s-12 m-12 l-12">
                  <div class="line">
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/afdruk-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/afdrukMiddel 2.png"
                          alt="Empreinte"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          empreinte
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/amalgaam-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/amalgaamMiddel 10.png"
                          alt="amalgame"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          amalgame
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/anesthesie-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/anesthesieMiddel 4.png"
                          alt="Anesthésie"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          anesthésie
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/apparatuur-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/apparatuurMiddel 5.png"
                          alt="Appareils"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          appareils
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/articulatie-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/articulatieMiddel 6.png"
                          alt="Articulation"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          articulation
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/bleaching-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/bleachingMiddel 7.png"
                          alt="Blanchiment"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          blanchiment
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/boren-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/borenMiddel 8.png"
                          alt="fraises"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          fraises
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/cementen-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/cementenMiddel 9.png"
                          alt="ciments"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          ciments
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/chirurgie-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/chirurgie.png"
                          alt="chirurgie"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          chirurgie
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/cofferdam-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/cofferdam.png"
                          alt="cofferdam"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          cofferdam
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/compomeren-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/compomeren.png"
                          alt="compomères"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          compomères
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/composieten-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/composietenMiddel 13.png"
                          alt="composites"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          composites
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/disposables-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/disposables.png"
                          alt="disposables"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          disposables
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/endo-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/endo.png"
                          alt="endo"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          endo
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/hoekstukken-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/hoekstukken.png"
                          alt="pièce à main"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          contre-angles
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/instrumenten-online-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/instrumentenMiddel 12.png"
                          alt="instruments"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          instruments
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/kunstharsen-online-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/kunstharsen.png"
                          alt="résines"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          résines
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/matrijzen-online-2023-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/Matrijzen.png"
                          alt="matrices"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          matrices
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/mondhygiene-online-2024-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/mondhygieneMiddel 15.png"
                          alt="prophylaxie"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          prophylaxie
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/noodkronen-online-2024-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/noodkronen.png"
                          alt="couronnes"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          couronnes
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/pinsystemen-online-2024-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/pinsystemenMiddel 16.png"
                          alt="pivots"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          pivots
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/polijsten-online-2024-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/polijstenMiddel 11.png"
                          alt="polissage"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          polissage
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/reiniging-online-2024-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/reinigingMiddel 14.png"
                          alt="produits de nettoyage"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          produits de nettoyage
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/rontgen-online-2024-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/rontgen.png"
                          alt="radiographie"
                          width="75"
                          height="75"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          radiographie
                        </h6>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-60">
                      <a
                        href="https://view.publitas.com/providental/wiggen-online-2024-fr"
                        target="_blank"
                        ><img
                          src="../../assets/images/iconen/wiggen.png"
                          alt="coins interdentaires"
                          width="92"
                          height="92"
                          class="center"
                      /></a>
                      <div>
                        <h6 class="text-uppercase text-strong text-center">
                          coins interdentaires
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Kleurbalk -->
    </article>
  </section>
</main>
