import { Component, OnInit } from '@angular/core';
import { TypeResult } from '../../../../models/enum';
import { MenuService } from '../../../../services/menu.service';
import { Menu } from '../../../../models/menu/menu';

@Component({
  selector: 'app-tandarts-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class TandartsContentComponent implements OnInit {

  constructor(private menuservice: MenuService) { }

  ngOnInit() {

  }

  get Menu(): Menu[] {
    if (this.menuservice.menuTandarts != undefined)
      return this.menuservice.menuTandarts;
    else
      return [];
  }

}
