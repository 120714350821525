import { Component, OnInit } from '@angular/core';
import { BestellingenService } from '../../../../services/bestellingen.service';
import { LanguageService } from '../../../../services/language.service';
import { mBestelling } from '../../../../models/bestelling/mbestelling';

@Component({
  selector: 'app-webshopbestellingenitem',
  templateUrl: './webshopbestellingenitem.component.html',
  styleUrls: ['./webshopbestellingenitem.component.css']
})
export class WebshopbestellingenitemComponent implements OnInit {

  constructor(private bestellingenService: BestellingenService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  filtered: boolean = false;
  get BestellingenItems() {
    if (this.bestellingenService.bestellingen != undefined)
      if (!this.filtered)
        return this.bestellingenService.bestellingen.filter(a => a.Ok == false);
      else
        return this.bestellingenService.bestellingen.filter(a => a.Ok == true);
    return [];
  }
  translate(val: string) {
    return this.languageService.translate(val);
  }
  onclick(mBestelling: mBestelling) {
    this.bestellingenService.select(mBestelling);
  }

  getClass(mBestelling: mBestelling) {
    if (mBestelling == this.bestellingenService.CurrentSelected)
      return "table_row selected";
    else
      return "table_row";
  }
}
