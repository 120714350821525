import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseService } from './response.service';
import { mLogin } from '../models/login/login';
import { mBestelling } from '../models/bestelling/mbestelling';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class BestellingenService {


    constructor(private http: HttpClient, private responseService: ResponseService) { }

    private apiUrlGetBestelling = this.responseService.apiString + "/bestelling/get";

    login?: mLogin;
    bestellingen: mBestelling[] = [];
    CurrentSelected?: mBestelling = undefined;

    GetBestellingen() {
        if (this.login != undefined && this.login.Succes != undefined && this.login.Succes != false) {
            this.getBestel(this.login).subscribe((bestellingen) => {
                this.AssignOnReturn(bestellingen);
            });;
        }
    }

    private getBestel(oLogin: mLogin): Observable<mBestelling[]> {
      httpOptions.headers =
          httpOptions.headers.set('authorization', this.login!.Hash);

      return this.http.post<mBestelling[]>(this.apiUrlGetBestelling, oLogin, httpOptions);
    }
    AssignOnReturn(Bestellingen: mBestelling[]) {
        if (this.bestellingen == undefined)
            this.bestellingen = [];
        while (this.bestellingen.length > 0) {
            this.bestellingen.pop();
        }
        Bestellingen.forEach(element => {
            this.bestellingen.push(element);

        });
        /*if (this.bestellingen != undefined && this.bestellingen.length > 0)
            this.CurrentSelected = this.bestellingen[0];*/
    }
    EmptyCurrent() {
        if (this.bestellingen != undefined) {
            var prices = this.bestellingen.slice();
            if (this.bestellingen == undefined)
                this.bestellingen = [];

            while (this.bestellingen.length > 0) {
                this.bestellingen.pop();
            }
        }
    }

    select(mBestelling: mBestelling)
    {
        this.CurrentSelected = mBestelling;
    }
}
