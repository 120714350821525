<main role="main">

  <section>
    <article>
      <header class="section background-transparent text-center text-white parallax-window" data-parallax="scroll" data-image-src="../../assets/images/parallax-04.jpg">
      </header>
      <div class="section background-white padding">
        <div class="line">
          <h2 class="text-size-30">
            <em>Leveringsvoorwaarden</em>
          </h2>
          <hr class="break break-small background-primary">
          <p>
            <strong>Alle goederen worden zonder verzendkosten geleverd voor bestellingen van 100 Euro (exclusief BTW).</strong>
            <br> Verzendingskosten voor spoedzendingen worden steeds aangerekend. Speciaal voor U bestelde producten worden niet
            teruggenomen. Retourzendingen worden slechts geaccepteerd indien deze ons in ongeschonden staat en binnen de
            10 dagen worden teruggestuurd. U mag dit doen op onze kosten met vermelding: "port betaald door bestemmeling".
            Pakken van 1 kg worden door de vervoerdienst bij U thuis opgehaald</p>
          <div class="line"> </div>
        </div>

        <!-- Icoontjes -->
        <div class="line">
          <hr class="break margin-bottom-70">
          <div class="margin">
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-pen background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">Hoe bestellen</h4>
                <p>Van 08.30u tot 17.00u (doorlopend)</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/hoebestellen">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-discussion background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">Betalingsvoorwaarden
                  <br>
                </h4>
                <p>
                  <strong>Alle vermelde prijzen zijn exclusief BTW</strong>. Wij vragen een stipte betaling van de facturen binnen
                  de 10 dagen na factuurdatum.</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/betalingsvoorwaarden">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-star_empty background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">kortingen</h4>
                <p>U ontvangt 2% directe korting op uw factuur (niet cumuleerbaar met e-shopkorting)</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/kortingen">Lees meer</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="background-dark padding text-center"> </div>
    </article>


  </section>

</main>
