import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Menu } from '../../../../models/menu/menu';
import { MenuService } from '../../../../services/menu.service';
import { RouterparamService } from '../../../../services/routerparam.service';
import { TypeResult, Language, eMenuType } from '../../../../models/enum';
import { mArtikelPrijs } from '../../../../models/prijs/martikelprijs';
import { ArtikelService } from '../../../../services/artikel.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-inhoud',
  templateUrl: './inhoud.component.html',
  styleUrls: ['./inhoud.component.css'],
})
export class InhoudComponent implements OnInit {
  constructor(
    private menuservice: MenuService,
    private artikelService: ArtikelService,
    private routerparamService: RouterparamService,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,

  ) {}

  ngOnInit() {}

  localMenu: Menu[] = [];
  localArtikels: mArtikelPrijs[] = [];

  lastType: TypeResult | undefined;
  lastFilter: string[] = [];

  private xMenu: Menu[] = [];

  Squared: boolean = true;

  @Input() set MenuSwitch(menu: Menu[]) {
    while (this.BackHelpers.length > 0) this.BackHelpers.pop();
    this.xMenu = menu;
  }
  get MenuSwitch(): Menu[] {
    return this.menuservice.menuTandarts;
  }

  get Menu(): Menu[] {
    if (
      this.MenuSwitch != undefined &&
      (this.lastType != this.Type || this.lastFilter != this.Filter)
    ) {
      this.filterinput = '';
      this.filterpromo = false;
      this.lastFilter = this.Filter;
      this.lastType = this.Type;
      this.localMenu = this.MenuSwitch;
      if (this.Type != TypeResult.None) {
        var menuf = this.MenuSwitch;
        if (this.Type == TypeResult.SuperGroep) {
          let sMenu: Menu[] = [];
          menuf.forEach((element) => {
            var check = false;
            if (this.Filter.indexOf(element.Nr) > -1) sMenu.push(element);
          });
          if (sMenu.length == 1) {
            while (this.BackHelpers.length > 0) this.BackHelpers.pop();

            this.onclicksupergroepitem(sMenu[0]);
          } else {
            this.type = eMenuType.supergroep;
            this.localMenu = sMenu;
          }
        } else if (this.Type == TypeResult.HoofdGroep) {
          let sSGMenu: Menu[] = [];
          let sHGMenu: Menu[] = [];
          menuf.forEach((x) => {
            x.SubMenu.forEach((element) => {
              var check = false;
              if (this.Filter.indexOf(element.Nr) > -1) {
                sHGMenu.push(element);
                sSGMenu.push(x);
              }
            });
          });
          if (sHGMenu.length == 1) {
            while (this.BackHelpers.length > 0) this.BackHelpers.pop();

            this.onclicksupergroepitem(sSGMenu[0]);
            this.onclickhoofdgroepitem(sHGMenu[0]);
          } else {
            this.type = eMenuType.hoofdgroep;
            this.localMenu = sHGMenu;
          }
        } else if (this.Type == TypeResult.Groep) {
          let sSGMenu: Menu[] = [];
          let sHGMenu: Menu[] = [];
          let sGmenu: Menu[] = [];
          menuf.forEach((x) => {
            x.SubMenu.forEach((y) => {
              y.SubMenu.forEach((element) => {
                var check = false;
                if (this.Filter.indexOf(element.Nr) > -1) {
                  sGmenu.push(element);
                  sHGMenu.push(y);
                  sSGMenu.push(x);
                }
              });
            });
          });
          if (sGmenu.length == 1) {
            while (this.BackHelpers.length > 0) this.BackHelpers.pop();

            this.onclicksupergroepitem(sSGMenu[0]);
            this.onclickhoofdgroepitem(sHGMenu[0]);
            this.onclickgroepitem(sGmenu[0], false);
          } else {
            this.type = eMenuType.groep;
            this.localMenu = sGmenu;
          }
        } else if (this.Type == TypeResult.Artikel) {
          let sSGMenu: Menu[] = [];
          let sHGMenu: Menu[] = [];
          let sGmenu: Menu[] = [];
          let sMenu: mArtikelPrijs[] = [];
          var countdif = false;
          let lastmenu: Menu | undefined;
          var secondmenu: Menu | undefined;
          menuf.forEach((x) => {
            x.SubMenu.forEach((y) => {
              y.SubMenu.forEach((z) => {
                if (lastmenu != undefined) secondmenu = z;

                lastmenu = z;
                z.Artikels.forEach((element) => {
                  var check = false;
                  if (this.Filter.indexOf(element.Artikel.Nr) > -1) {
                    if (secondmenu != undefined) countdif = true;
                    sMenu.push(element);
                    sGmenu.push(z);
                    sHGMenu.push(y);
                    sSGMenu.push(x);
                  }
                });
              });
            });
          });
          if (sMenu.length == 1) {
            while (this.BackHelpers.length > 0) this.BackHelpers.pop();
            this.onclicksupergroepitem(sSGMenu[0]);
            this.onclickhoofdgroepitem(sHGMenu[0]);
            this.onclickgroepitem(sGmenu[0], true);
            this.onclickartikelitem(sMenu[0]);
          } else {
            if (countdif) {
              while (this.BackHelpers.length > 0) this.BackHelpers.pop();
            }
            this.type = eMenuType.artikel;
            this.artikelService.GetArtikels(sMenu);
            this.Artikels = this.artikelService.requestedPrices;
          }
        }
      } else {
        this.localMenu = this.MenuSwitch;
      }
    }

    if (this.localMenu == undefined) this.localMenu = this.MenuSwitch;
    return this.localMenu;
  }
  set Menu(_menu: Menu[]) {
    this.localMenu = _menu;
  }

  get Artikels() {
    return this.localArtikels;
  }
  set Artikels(value) {
    this.localArtikels = value;
  }
  get Type() {
    return this.routerparamService.TypeResult;
  }
  get Filter() {
    return this.routerparamService.Filters;
  }

  private bHelper: BackHelper[] = [];
  get BackHelpers(): BackHelper[] {
    return this.bHelper;
  }
  set BackHelpers(value) {
    this.bHelper = value;
  }
  type: eMenuType = eMenuType.supergroep;

  onclicksupergroepitem(_menu: Menu) {
    if (this.languageService.Taal == Language.NL)
      this.BackHelpers.push(
        new BackHelper(
          'Categoriën / ' + _menu.OmschrijvingNL,
          this.Menu,
          eMenuType.supergroep
        )
      );
    else
      this.BackHelpers.push(
        new BackHelper(
          'Catégories / ' + _menu.OmschrijvingFR,
          this.Menu,
          eMenuType.supergroep
        )
      );

    this.cdr.detectChanges();

    this.type = eMenuType.hoofdgroep;
    this.Menu = _menu.SubMenu.slice();
    this.Artikels = [];
  }
  onclickhoofdgroepitem(_menu: Menu) {
    if (this.languageService.Taal == Language.NL)
      this.BackHelpers.push(
        new BackHelper(_menu.OmschrijvingNL, this.Menu, eMenuType.hoofdgroep)
      );
    else
      this.BackHelpers.push(
        new BackHelper(_menu.OmschrijvingFR, this.Menu, eMenuType.hoofdgroep)
      );

    this.cdr.detectChanges();

    this.type = eMenuType.groep;
    this.Menu = _menu.SubMenu.slice();
    this.Artikels = [];
  }
  onclickgroepitem(_menu: Menu, viaArtikelzoek: boolean) {
    if (this.languageService.Taal == Language.NL)
      this.BackHelpers.push(
        new BackHelper(_menu.OmschrijvingNL, this.Menu, eMenuType.groep)
      );
    else
      this.BackHelpers.push(
        new BackHelper(_menu.OmschrijvingFR, this.Menu, eMenuType.groep)
      );

    this.cdr.detectChanges();

    this.type = eMenuType.artikel;
    if (!viaArtikelzoek) {
      this.artikelService.GetArtikels(_menu.Artikels);
      this.Artikels = this.artikelService.requestedPrices;
    }
  }
  onclickartikelitem(_artikel: mArtikelPrijs) {
    var men = this.Menu;
    var sGmenu: Menu[] = [];
    this.localMenu.forEach((x) => {
      x.SubMenu.forEach((y) => {
        y.SubMenu.forEach((z) => {
          z.Artikels.forEach((element) => {
            var check = false;
            if (_artikel.Artikel.Nr == element.Artikel.Nr) {
              sGmenu.push(z);
            }
          });
        });
      });
    });
    if (sGmenu.length == 0) {
      this.localMenu.forEach((x) => {
        x.SubMenu.forEach((z) => {
          z.Artikels.forEach((element) => {
            var check = false;
            if (_artikel.Artikel.Nr == element.Artikel.Nr) {
              sGmenu.push(z);
            }
          });
        });
      });
    }
    if (sGmenu.length == 0) {
      this.localMenu.forEach((z) => {
        z.Artikels.forEach((element) => {
          var check = false;
          if (_artikel.Artikel.Nr == element.Artikel.Nr) {
            sGmenu.push(z);
          }
        });
      });
    }
    if (sGmenu.length > 0) men = sGmenu;

    var bh;
    if (this.languageService.Taal == Language.NL)
      bh = new BackHelper(_artikel.Artikel.OmschrijvijngNL, men, eMenuType.artikel);
    else bh = new BackHelper(_artikel.Artikel.OmschrijvijngFR, men, eMenuType.artikel);
    bh.Artikels = men[0].Artikels;
    this.BackHelpers.push(bh);

    this.cdr.detectChanges();

    var oa = [];
    oa.push(_artikel);
    this.artikelService.GetArtikels(oa);
    this.Artikels = this.artikelService.requestedPrices;
    /*this.Artikels = [];
    this.Artikels.push(_artikel);
    /*this.artikelService.GetArtikels(this.Artikels);
    this.Artikels = this.artikelService.requestedPrices;*/
    this.type = eMenuType.singleartikel;
  }
  checkstringtype(type: string) {
    if (type != eMenuType[this.type].toString()) return true;
    else return false;
  }
  checktype(type: eMenuType) {
    if (type != this.type) return true;
    else return false;
  }
  checktypes() {
    if (
      this.type == eMenuType.hoofdgroep ||
      this.type == eMenuType.supergroep ||
      this.type == eMenuType.groep
    )
      return true;
    return false;
  }
  onBack(BackHelper: BackHelper) {
    this.filterinput = '';
    var helper = undefined;
    while (helper != BackHelper) helper = this.BackHelpers.pop();

    if (helper.Artikels != undefined && helper.Artikels.length > 0) {
      this.artikelService.GetArtikels(helper.Artikels);
      this.Artikels = this.artikelService.requestedPrices;
    }
    this.Menu = helper.MenuItems.slice();
    this.type = helper.Type;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  filterinput: string = '';
  filterpromo: boolean = false;
  onFilter(input: string) {
    this.filterinput = input;
  }

  checkvisible(a: mArtikelPrijs) {
    if (
      (this.filterinput == undefined || this.filterinput.trim() == '') &&
      this.filterpromo == false
    )
      return true;
    else {
      if (this.filterpromo) {
        if (a.Prijs != undefined && a.Prijs.Promo == false) return false;
        else if (this.filterinput == '') return true;
      }

      var input = this.filterinput.toLowerCase();

      if (this.languageService.Taal == Language.NL) {
        if (
          a.Artikel.OmschrijvijngNL.toLowerCase().indexOf(input) >= 0 ||
          a.Artikel.GroepNL.toLowerCase().indexOf(input) >= 0 ||
          a.Artikel.Code.toLowerCase().indexOf(input) >= 0 ||
          a.Artikel.Nr.toLowerCase().indexOf(input) >= 0
        )
          return true;
        else return false;
      } else {
        if (
          a.Artikel.OmschrijvijngFR.toLowerCase().indexOf(input) >= 0 ||
          a.Artikel.GroepFR.toLowerCase().indexOf(input) >= 0 ||
          a.Artikel.Code.toLowerCase().indexOf(input) >= 0 ||
          a.Artikel.Nr.toLowerCase().indexOf(input) >= 0
        )
          return true;
        else return false;
      }
    }
  }
  onSort(type: string) {
    this.artikelService.OrderArtikels(type);
  }
  onPromo(isPromo: boolean) {
    this.filterpromo = isPromo;
  }
  onStyle(isblocked: boolean) {
    this.Squared = isblocked;
  }

  GetSquared() {
    return this.Squared;
  }
}
export class BackHelper {
  constructor(_Naam: string, _MenuItems: Menu[], menuType: eMenuType) {
    this.Naam = _Naam;
    this.MenuItems = _MenuItems;
    this.Type = menuType;
  }
  Naam: string = '';
  Type: eMenuType = eMenuType.geen;
  MenuItems: Menu[] = [];
  Artikels: mArtikelPrijs[] = [];
}
