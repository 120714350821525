import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { RouterparamService } from 'src/app/services/routerparam.service';
import { SearchService } from 'src/app/services/search.service';
import { TypeKlant, TypeResult } from 'src/app/models/enum';
import { mSearchExact, mSearchInput, mSearchResult } from 'src/app/models/search/search';

@Component({
  selector: 'app-searchpage',
  templateUrl: './searchpage.component.html',
  styleUrls: ['./searchpage.component.css']
})
export class SearchpageComponent implements OnInit {
  id: string = '';
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private languageService: LanguageService,private routerparamService: RouterparamService
  ) {}

  ngOnInit() {

  }
  onTest(){
    this.routerparamService.Routeer(["200-1759"],TypeResult.Artikel, "L");
  }
}
