import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { mSearchExact, mSearchOutput } from '../../../../models/search/search';
import { TypeResult } from '../../../../models/enum';
import { RouterparamService } from '../../../../services/routerparam.service';
import { BasketService } from '../../../../services/basket.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-searchbarpopupexact',
  templateUrl: './searchbarpopupexact.component.html',
  styleUrls: ['./searchbarpopupexact.component.css']
})
export class SearchbarpopupexactComponent implements OnInit {

  constructor(private RouterparamService: RouterparamService, private basketService: BasketService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  @Input() Artikel: mSearchExact|undefined;
  @Output() onClicked = new EventEmitter();

  onclick(item: mSearchOutput) {
    var ar = [];
    ar.push(item.ID);
    this.RouterparamService.Routeer(ar, item.Type, item.TypeCode);
    this.onClicked.emit();
  }
  onMeer() {
    var ar:string[] = [];
    this.Artikel!.Children.forEach(element => {
      ar.push(element.ID);
    });
    this.RouterparamService.Routeer(ar, TypeResult.Artikel, "");
    this.onClicked.emit();
  }
  onadd(item: mSearchOutput) {
    this.basketService.AddToBasketID(item.ID);
    this.RouterparamService.RouteerWinkelmand();
  }

  ishidden(item: mSearchOutput) {
    if (item.Type == TypeResult.Artikel)
      return false;

    return true;
  }

  translate(val: string){
    return this.languageService.translate(val);
  }
}
