import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../services/login.service';
import { MenuService } from '../../../../services/menu.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private loginService: LoginService, private menuService: MenuService, private languageService: LanguageService) { }

  login: string = "";
  paswoord: string = "";
  remember: boolean = true;
  loadingLogin: boolean = false;
  get getLogin() {
    if (this.Login == undefined)
      return "";
    else {
      return this.Login.KlantNaam;
    }
  }

  get loggedin() {
    if (this.loginService.login != undefined && this.loginService.login.Succes != undefined && this.loginService.login.Succes)
      if (this.loadingLogin == true)
        this.loadingLogin = false;
    return this.loginService.login != undefined && this.loginService.login.Succes != undefined && this.loginService.login.Succes;
  }
  get Login() {
    return this.loginService.login;
  }
  ngOnInit() {
    document.getElementById('logintrigger')!.onmouseover = function () {
      document.getElementById('popuphover')!.style.display = 'inline';
    }
    document.getElementById('logintrigger')!.onmouseleave = function () {
      document.getElementById('popuphover')!.style.display = 'none';
    }
    document.getElementById('mpopupcontainer')!.onmouseover = function () {
      document.getElementById('popuphover')!.style.display = 'inline';
    }
    /*document.getElementById('mpopupcontainer').onmouseleave = function () {
      document.getElementById('popuphover').style.display = 'none';
    }*/

    // Get the modal
    var modal = document.getElementById('myModal');

    // Get the button that opens the modal
    var btn = document.getElementById("myBtn");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on the button, open the modal
    btn!.onclick = function () {
      modal!.style.display = "block";
    }

    // When the user clicks on <span> (x), close the modal
    //span.onclick = function () {
    //modal.style.display = "none";
    //}

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        modal!.style.display = "none";
      }
    }
  }

  onLogin() {
    this.loadingLogin = true;
    this.loginService.onLogin(this.login, this.paswoord, this.remember);
    document.getElementById('popuphover')!.style.display = 'none';
  }
  logout() {
    this.loginService.clearCookie();
    this.loginService.onLogout();

    document.getElementById('myModal')!.style.display = "none";
  }
  closepopup() {
    document.getElementById('mpopupcontainer')!.onmouseleave = function () {
      document.getElementById('popuphover')!.style.display = 'none';
    }
  }
  onIconClick() {
    document.getElementById('popuphover')!.style.display = 'inline';
  }

  translateString(val: string) {
    return this.languageService.translate(val);
  }
}
