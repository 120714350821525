<div class="section background-white padding">
  <div class="line">
    <div class="margin">
      <div class="s-12 m-12 l-12">
        <div class="line">
          <div class="margin">
            <div class="s-12 m-4 l-4 margin-top-30">
              <div class="margin-bottom">
                <img [src]="Artikel.Artikel.FotoUrl" [alt]="Artikel.Artikel.OmschrijvijngNL" width="400" class="center block-bordered">
              </div>
            </div>
            <div class="s-12 m-8 l-8 margin-top-30">
              <h3 class="text-s-size-12 text-m-size-16 text-l-size-16">
                <strong>{{translateOMS(Artikel)}}</strong>
              </h3>
              <pre class=" text-s-size-12 text-size-14 margin-bottom fonter">{{translateBeschrijving(Artikel)}}</pre>
              <div class="pricing-table margin-bottom count-to s-12 m-12 l-12">
                <p class="margin-left-10 text-s-size-12">
                  <strong>- {{translateString('Merk')}}</strong>: {{translateGroep(Artikel)}}</p>
                <p class="margin-left-10 text-s-size-12">
                  <strong>- {{translateString('Productnr')}}.</strong>: {{Artikel.Artikel.ProductNr}}</p>
                <p class="margin-left-10 text-s-size-12">
                  <strong>- {{translateString('Art.nr')}}.</strong>: {{Artikel.Artikel.ProductCode}}</p>
                <p class="margin-left-10 text-s-size-12">
                  <strong>- {{translateString('Inhoud')}}</strong>: {{translateInhoud(Artikel)}}</p>
                <div [hidden]="!showprijs">
                  <div class="line">
                    <div class="margin">
                      <div class="s-12 m-12 l-12">
                        <div class="s-6 m-6 l-6">
                          <p class="pricing-price text-s-size-12 text-size-16 text-green">€
                            <span class="timer" data-from="0" data-to="38.00" data-speed="2000" data-decimals="2">{{Prijs}}</span>
                          </p>
                        </div>
                        <div class="s-6 m-6 l-6">
                          <div class="pricing-price text-size-16 text-strong text-red no-pad" [hidden]="!showkorting1">{{translateString('Stukprijs per')}} {{Aantal1}}: €
                            <span class="timer" data-from="4" data-to="38.00" data-speed="2000" data-decimals="2">{{Korting1}}</span>
                          </div>
                          <div class="pricing-price text-size-16 text-strong text-red no-pad" [hidden]="!showkorting2">{{translateString('Stukprijs per')}} {{Aantal2}}: €
                            <span class="timer" data-from="4" data-to="38.00" data-speed="2000" data-decimals="2">{{Korting2}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="line">
                    <div class="margin">
                      <div class="s-12 m-12 l-12 margin-bottom-10">
                        <div class="s-12 m-12 l-12 margin-top-10 text-m-size-12">
                          <div class="s-10 m-10 l-6">
                            <input name="number3" type="number" class="s-4 margin-left-10 larger w100" id="number2" placeholder="Aantal" title="Aantal"
                              [(ngModel)]="Aantal">
                          </div>
                          <div class="s-5 m-5 l-2">
                            <a class="button border-radius background-green text-white margin-left icon-sli-basket w100" (click)="onAddToBasket()"></a>
                          </div>
                          <div class="s-5 m-5 l-2 pl10">
                            <a [ngClass]="getClassFav()" (click)="onFav()"></a>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div [hidden]="showprijspoa">
                  <li class="text-center text-green" style="list-style-type: none;">
                    <span class="tooltip-container">
                      <span class="tooltip-content tooltip-top" style="display: none; top: -38px; left: 382.797px;">
                      </span>
                      <div>
                      <a title="prijs op aanvraag">{{translateString('P.O.A.')}}</a>
                    </div>
                    </span>
                  </li>
                </div>
                <div [hidden]="loadingPrices">
                  <div [hidden]="showprijs">
                      <li class="text-center text-green" (click)="onInfo()">
                          <span class="tooltip-container">
                              <span class="tooltip-content tooltip-top" style="display: none; top: -38px; left: 382.797px;">
                              </span>
                              {{translateString('Prijsinformatie')}}
                          </span>
                      </li>
                  </div>                  
              </div>
              <div [hidden]="!loadingPrices">
                  <div class="loader"></div>
                  <div class="loadertext s-12">Loading prices</div>
              </div>
                <div class="margin" style="display:none">
                  <div class="s-12 m-12 l-12">
                    <p class="margin-left-10 text-s-size-12 s-3">
                      <strong>Varianten:</strong>
                    </p>
                    <select name="" class="background-white s-2" form="select" placeholder="Kleur" title="Kleur">
                      <option value="AT">AT</option>
                      <option value="A1B">A1B</option>
                      <option value="A1D">A1D</option>
                      <option value="A1E">A1E</option>
                      <option value="A2B">A2B</option>
                      <option value="A2D">A2D</option>
                      <option value="A2E">A2E</option>
                      <option value="A35B">A35B</option>
                      <option value="A3B">A3B</option>
                      <option value="A3D">A3D</option>
                      <option value="A3E">A3E</option>
                      <option value="A4B">A4B</option>
                      <option value="A4D">A4D</option>
                      <option value="A6B">A6B</option>
                      <option value="BT">BT</option>
                      <option value="B1B">B1B</option>
                      <option value="B1E">B1E</option>
                      <option value="B2B">B2B</option>
                      <option value="B2E">B2E</option>
                      <option value="B3B">B3B</option>
                      <option value="B3D">B3D</option>
                      <option value="B5B">B5B</option>
                      <option value="C1B">C1B</option>
                      <option value="C2B">C2B</option>
                      <option value="C3B">C3B</option>
                      <option value="C4D">C4D</option>
                      <option value="CT">CT</option>
                      <option value="D2B">D2B</option>
                      <option value="D2E">D2E</option>
                      <option value="D3B">D3B</option>
                      <option value="GT">GT</option>
                      <option value="WB">WB</option>
                      <option value="WD">WD</option>
                      <option value="WE">WE</option>
                      <option value="XWB">XWB</option>
                      <option value="XWE">XWE</option>
                    </select>
                    <select name="" class="background-white s-2 margin-left" form="select" placeholder="Kleur" title="Kleur">
                      <option value="3g">3g</option>
                      <option value="10 x 0.2g">10 x0.2g</option>
                      <option value="20 x 0.2g">20 x0.2g</option>
                    </select>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>



</div>