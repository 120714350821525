import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../../../../services/menu.service';
import { Menu } from '../../../../models/menu/menu';
import { RouterparamService } from '../../../../services/routerparam.service';
import { LanguageService } from '../../../../services/language.service';
import { Language } from '../../../../models/enum';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private routerparamService: RouterparamService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }
  @Input() Menu: Menu[]=[];

  bfirst = true;
  showitem() {
    if (this.bfirst) {
      this.bfirst = false;
      //Responsee eside nav
      $('.aside-nav > ul > li ul').each(function (index:any, element:any) {
        var count = $(element).find('li').length;
        var content = '<span class="count-number"> ' + count + '</span>';
        $(element).closest('li').children('a').append(content);
      });
      $('.aside-nav > ul > li:has(ul)').addClass('aside-submenu');
      $('.aside-nav > ul ul > li:has(ul)').addClass('aside-sub-submenu');
      $('.aside-nav > ul > li.aside-submenu > a').attr('aria-haspopup', 'true').click(function () {
        //Close other open sub menus
        $('.aside-nav ul li.aside-submenu > ul').removeClass('show-aside-ul', 'slow');
        $('.aside-nav ul li.aside-submenu:hover > ul').toggleClass('show-aside-ul', 'slow');
      });
      $('.aside-nav > ul ul > li.aside-sub-submenu > a').attr('aria-haspopup', 'true').click(function () {
        //Close other open sub menus
        $('.aside-nav ul ul li > ul').removeClass('show-aside-ul', 'slow');
        $('.aside-nav ul ul li:hover > ul').toggleClass('show-aside-ul', 'slow');
      });
      //Mobile aside navigation
      $('.aside-nav-text').each(function (index:any, element:any) {
        $(element).click(function () {
          $('.aside-nav > ul').toggleClass('show-menu', 'slow');
        });
      });
    }
  }

  onMenuClick(menu: Menu) {
    this.routerparamService.RouteerMenu(menu);
    window.scrollTo(0,0);
  }

  translate(menu: Menu) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.OmschrijvingNL;
    else
      return menu.OmschrijvingFR;
  }
  translateString(val: string)
  {
    return this.languageService.translate(val);
  }
}
