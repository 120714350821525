import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { mContact } from '../../models/contact/contact';

declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: '../../../assets/html/contact.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private loginService: LoginService) {
    this.mContact = new mContact();
  }

  send: Boolean = false;
  mContact: mContact;
  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }

  click() {
    //conditie
    this.loginService.onContact(this.mContact);
    this.mContact = new mContact();
    this.send = true;
  }

}
