<main role="main">

    <section>
      <article>
        <header class="section background-green-hightlight background-transparent text-center background-blue" data-parallax="scroll"
          data-image-src="../../../assets/images/parallax-06.jpg">
          <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1 text-s-size-16">Disclaimer</h1>
        </header>
        <div class="section background-white padding">
          <div class="line">
            <!-- Catalogus -->
  
            <div class="line">
              <p class="icon-home text-blue">
                <a routerLink="/FR/home"> Home</a>
              </p>
              <hr class="break break-small background-primary">
              <p>La présente notice a pour objet de définir les modalités selon lesquelles Providental met à la 
                  disposition des internautes son site www.Providental.be et les conditions selon lesquelles 
                  les internautes accèdent et utilisent ce site internet. Aucun des documents provenant du site 
                  de Providental ne peut-être copié, reproduit, publié, téléchargé, posté, transmis ou distribué 
                  de quelque manière que ce soit sans l’autorisation écrite de Providental. Providental s’efforce
                  de maintenir accessible le site, sans pour autant être tenue à aucune obligation d’y parvenir. 
                  Il est précisé qu’à des fins de maintenance, de mise à jour, et pour toute autre raison notamment 
                  d’ordre technique, l’accès au site pourra être interrompu. Providental n’est en aucun cas responsable
                  de ces interruptions et des conséquences qui peuvent en dérouler. Providental se réserve le droit 
                  de modifier ou de corriger le contenu de son site à tout moment, sans préavis. Providental ne pourra 
                  être tenu pour responsable en cas de contamination des matériels informatiques des internautes 
                  résultant de la propagation d’un virus ou d’autres infections informatiques. Le site de Providental 
                  peut contenir des liens de sites partenaires ou de tiers. Providental n’exerce aucun controle sur 
                  ces sites, leur contenu, publicité, produits et/ou services disponibles sur ou à partir de ces sites. 
                  Ainsi, Providental ne sera aucunement responsable des dommages directs ou indirects pouvant survenir 
                  à l’occasion de l’accès de l’internaute de ces sites.
            </p>
              <div class="box margin-bottom">
                <div class="margin">
                  <!-- CONTENT -->
  
                  <!-- ASIDE NAV -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Kleurbalk -->
        <div class="background-dark padding text-center"> </div>
  
      </article>
  
  
    </section>
  
  </main>