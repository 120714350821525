import { Component, OnInit } from '@angular/core';
import { mBasket, mBasketItem } from '../../../../models/basket/basket';
import { BasketService } from '../../../../services/basket.service';
import { RouterparamService } from '../../../../services/routerparam.service';
import { TypeResult, Language } from '../../../../models/enum';
import { ArtikelService } from '../../../../services/artikel.service';
import { LanguageService } from '../../../../services/language.service';
import { mArtikelPrijs } from 'src/app/models/prijs/martikelprijs';

@Component({
  selector: 'app-webshopbasketdetail',
  templateUrl: './webshopbasketdetail.component.html',
  styleUrls: ['./webshopbasketdetail.component.css']
})
export class WebshopbasketdetailComponent implements OnInit {

  constructor(private basketService: BasketService, private routerparamService: RouterparamService, private artikelService: ArtikelService, private languageService: LanguageService) { }

  ngOnInit() {
    //this.basketService.onGetBasket();
  }
  get BasketItems() {
    if (this.basketService.BasketItems != undefined)
      return this.basketService.BasketItems;

    return [];
  }
  onDelete(mBasketItem: mBasketItem) {
    this.basketService.onDeleteBasketItem(mBasketItem);
  }

  onadd(mBasketItem: mBasketItem) {
    mBasketItem.Aantal += 1;
    this.basketService.onEditBasketItem(mBasketItem);
  }
  onremove(mBasketItem: mBasketItem) {
    mBasketItem.Aantal -= 1;
    if (mBasketItem.Aantal > 0)
      this.basketService.onEditBasketItem(mBasketItem);
    else
      this.basketService.onDeleteBasketItem(mBasketItem);
  }
  onArtikelZoom(mBasketItem: mBasketItem) {


    var oArrayArt =[];
    if(mBasketItem.Artikel != undefined)
    oArrayArt.push(mBasketItem.Artikel);
    this.artikelService.GetArtikels(oArrayArt);

    var oArraykeys:string[] = [];
    this.artikelService.requestedPrices.forEach(element => {
      oArraykeys.push(element.Artikel.Nr);
    });

    this.routerparamService.Routeer(oArraykeys, TypeResult.Artikel, mBasketItem.Artikel!.Artikel.TypeCode.toString());
  }
  getPrice(mBasketItem: mBasketItem) {
    var prijs = 0;
    var aantal = 0;
    if(mBasketItem.Artikel == undefined || mBasketItem.Artikel.Prijs == undefined) return prijs;

    if (mBasketItem.Aantal >= mBasketItem.Artikel.Prijs.Aantal1 && aantal < mBasketItem.Artikel.Prijs.Aantal1)
      prijs = mBasketItem.Artikel.Prijs.Korting1;
    if (mBasketItem.Aantal >= mBasketItem.Artikel.Prijs.Aantal2 && aantal < mBasketItem.Artikel.Prijs.Aantal2)
      prijs = mBasketItem.Artikel.Prijs.Korting2;

    if (prijs == 0)
      return mBasketItem.Artikel.Prijs.Prijs;
    else
      return prijs;
  }
  getPromo(mBasketItem: mBasketItem) {
    var bool = this.isPromo(mBasketItem);
    if (bool)
      return "table_cell red";
    else
      return "table_cell";
  }
  isPromo(mBasketItem: mBasketItem) {
    var prijs = 0;
    var aantal = 0
    if(mBasketItem.Artikel == undefined ||mBasketItem.Artikel.Prijs == undefined) return prijs;

    if (mBasketItem.Aantal >= mBasketItem.Artikel.Prijs.Aantal1 && aantal < mBasketItem.Artikel.Prijs.Aantal1)
      prijs = mBasketItem.Artikel.Prijs.Korting1;
    if (mBasketItem.Aantal >= mBasketItem.Artikel.Prijs.Aantal2 && aantal < mBasketItem.Artikel.Prijs.Aantal2)
      prijs = mBasketItem.Artikel.Prijs.Korting2;

    if (prijs == 0 || prijs == mBasketItem.Artikel.Prijs.Prijs)
      return false;
    else
      return true;
  }

  translate(val: string) {
    return this.languageService.translate(val);
  }

  translateOMS(val: mBasketItem) {
    if(val.Artikel ==undefined)return "";
    if (this.languageService.Taal == Language.NL)
      return val.Artikel.Artikel.OmschrijvijngNL;
    else
      return val.Artikel.Artikel.OmschrijvijngFR;
  }
  translateGroep(val: mBasketItem) {
    if(val.Artikel ==undefined)return "";
    if (this.languageService.Taal == Language.NL)
      return val.Artikel.Artikel.GroepNL;
    else
      return val.Artikel.Artikel.GroepFR;
  }
}
