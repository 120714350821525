<!-- Midden -->
<main role="main">
  <section>
    <article>
      <header class="section background-green-hightlight background-transparent text-center background-blue"
        data-parallax="scroll" data-image-src="../../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-40 text-line-height-1 text-thin">
          Nieuw in ons assortiment !
        </h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue">
              <a routerLink="/NL/home" target="_self"> Home</a>
            </p>
            <h2 class="text-size-30 text-s-size-12">
              <em>Check this out!</em>
            </h2>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
              <a routerLink="/NL/fabrikantlinks" target="_self">
                <strong>
                  <em> Links naar fabrikanten</em>
                </strong>
              </a>
            </h4>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
              <a routerLink="/NL/fabrikantecatalogus" target="_self">
                <strong>
                  <em> e-catalogus / pdf</em>
                </strong>
              </a>
            </h4>
            <hr class="break break-small background-primary" />
          </div>
        </div>
        <div class="line">
          <div class="margin">
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5><strong>AlignerFlow LC</strong><br>Voco<br></h5>
                    <p>Nano hybride composiet met fluorescentie-effect. Onder de UV-A licht zijn de attachments te
                      controleren en zijn eventuele resten bij verwijdering exact te identificeren en zonder
                      beschadiging van het glazuur te verwijderen.&nbsp; &nbsp;</p>
                    <p>-Veilig slijpen en verwijderen onder UV-A licht <br>
                      -Nauwkeurige applicatie met een perfecte flow <br>
                      - Lichtuitharding door de transparante template</p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-01.jpg" alt="Aligner Flow LC - Voco"
                  title="Aligner Flow" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5><strong>Filtek Easy Match</strong><br>3M<br></h5>
                    <p>Universeel restauratiemateriaal ontwikkeld om single-shade restauraties te stroomlijnen vanaf
                      het begin van de procedure</p>
                    <p>- eenvoudige kleurbepaling, slechts 3 kleurtinten<br>
                      - natuurlijke opaciteit die zich aanpast<br>
                      - efficiënter voorraadbeheer<br>
                      - kies uit de kleuren: natural, bright of warm<br>
                    </p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-02.jpg" alt="Filtek Easy Match - 3M"
                  title="Filtek Easy Match" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5><strong>O7 active
                      </strong><br>GUM<br></h5>
                    <p>- Dezelfde vertrouwde actieve zuurstof-technologie<br>
                      Nu met een mild minty smaak</p>
                    <br>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-03.jpg" alt="O7 active van Gum" title="O7 active" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5>Bite &amp; White Shade Correction<br>
                      CAVEX<br>
                    </h5>
                    <p>Shade correction is een snel, veilig en effectief systeem voor het herstellen van de
                      natuurlijke tandkleur bij de patiënt. Het is een In-Office systeem, dus de volledige procedure
                      wordt uitgevoerd door de tandarts in de praktijk. De revolutionaire gel in het systeem maakt het
                      mogelijk om met slechts 2 à 3 applicaties in 1 behandeling de originele tandkleer te
                      herstellen.&nbsp;&nbsp;</p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-04.jpg" alt="Bite & White Shade correction van Cavex"
                  title="Bite&White shade correction" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5>Luxatie instrumenten set<br>CARL MARTIN<br></h5>
                    <p><strong>&nbsp;</strong>&nbsp;ALLES IS GOED IN DE HAND ...<br>
                      ... met de nieuwe Pad3 griptechniek. Door de speciale vingeruitsparingen kan men het instrument
                      makkelijker hanteren voor een geoptimaliseerde haptechniek. BETERE TECHNOLOGIE VOOR BETER WERK.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-05.jpg" alt="Luxatie instrumenten van Carl Martin"
                  title="Luxatie instrumenten" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5>Venus Pure Shades<br>KULZER<br></h5>
                    <p>Venus Pure Diamond heeft een uitgebreid kleurenpalet en kan als enkele kleur of in meerdere
                      kleuren in de laagtechniek worden toepast.<br></p>
                    <p>De nieuwe Venus Pure Pearl shades dekken ongeveer 90% van de anterieure &amp; posterieure
                      restauraties met slechts 4 kleuren.<br>
                      - Venus Pure Diamond: stevige, niet plakkende consistentie<br>
                      - Venus Pure Pearl: soepel, niet plakkende consistentie&nbsp; &nbsp;&nbsp;</p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-06.jpg" alt="Venus Pure shades van Kulzer"
                  title="Venus Pure shades" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5><strong>Transcend</strong><br>ULTRADENT<br></h5>
                    <p>Universeel en innovatief tandheelkundig composiet dat geschikt is voor directe en indirecte
                      restauraties in het front en de zijdelingse delen.</p>
                    <p>- Kleur "Universal Body" - geen opaker nodig</p>
                    <p>- Gepatenteerde Resin Paticle Match-technologie</p>
                    <p>- Aanvullend kleursysteem voor esthetische casussen in het front&nbsp;</p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-07.jpg" alt="Transcend van Ultradent" title="Transcend" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5><strong>ValoX&nbsp; &nbsp;
                      </strong><br>ULTRADENT<br></h5>
                    <p>ValoX polymerisatielamp is gemaakt om de wereld radicaal te veranderen. Van de grotere lens van
                      12,5 mm tot de adapter waarmee u de polymerisatielamp zowel met snoer als zonder kan gebruiken.
                    </p>
                    <p>-Krachtig<br>
                      -Veelzijdig<br>
                      -Eenvoudig
                    </p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-08.jpg" alt="ValoX van Ultradent" title="ValoX" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5><strong>Gum Soft-picks Pro</strong><br>GUM<br></h5>
                    <p><strong>Reinigt 50% effectiever.&nbsp;</strong></p>
                    <p>- Vangt en verwijdert meer tandplak<br>
                      - Ultra Soft<br>
                      - Dubbele rijen en een dichte massa van 152 flexibele en wendbare rubberen borstelharen&nbsp;
                    </p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-09.jpg" alt="Soft-picks Pro van Gum"
                  title="Soft-picks Pro" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x text-s-size-12">
                    <h5><strong>Brilliant Bulk Fill Flow</strong><br>COLTENE<br></h5>
                    <p>Is het ideale vulmateriaal voor snelle en eenvoudige restauraties. Gebaseerd op de Brilliant
                      vulstof-tecnologie is het de perfecte partner voor Brilliant Everglow en Everglow Flow.<br>
                    </p>
                    <p>- geen bovenlaag nodig</p>
                    <p>- tot 4 mm betrouwbare uithardingsdiepte</p>
                    <p>- een adaptief Multi Shade materiaal&nbsp;</p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-10.jpg" alt="Brilliant Bulk Fill Flow van Coltene"
                  title="Brilliant Bulk Fill Flow" />
              </div>
            </div>
            <div class="s-12 m-6 l-4">
              <div class="image-with-hover-overlay image-hover-zoom margin-bottom">
                <div class="image-hover-overlay background-primary">
                  <div class="image-hover-overlay-content text-center padding-2x">
                    <h5><strong>Brilliant Lumina&nbsp;</strong><br>COLTENE<br></h5>
                    <p>Een vooruitstrevend in-office bleeksysteem, dat als eerste geen waterstofperoxide of
                      carbamideperoxide bevat. Brilliant Lumina werkt met het actief bestanddeel PAP
                      (phthalimidoperoxycaproic acid).<br></p>
                    <p>- noch tijdens of na behandeling treedt enige vorm van gevoeligheid op<br></p>
                    <p>- respect voor het harde tandweefsel - geen tanderosie&nbsp;</p>
                  </div>
                </div>
                <img src="../../../assets/images/Gallery/thumb-11.jpg" alt="Brilliant Lumina van Coltene"
                  title="Brilliant Lumina" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</main>
