<main role="main">

  <section>
    <article>
      <header class="section background-transparent text-center text-dark" data-parallax="scroll" data-image-src="../../assets/images/parallax-03.jpg">
      </header>
      <div class="section background-white padding">
        <div class="line">
          <h2 class="text-size-30">
            <em>Kortingen</em>
          </h2>
          <hr class="break break-small background-primary">
          <p>
            <strong> Domiciliëringen 2% U ontvangt 2% directe korting op uw factuur</strong>
          </p>
          <blockquote>
            <p>Administratieve -en tijdsbesparing </p>
          </blockquote>
          <p>U kan ten allen tijde elk betalingsverkeer wijzigen of stopzetten indien een factuur naar uw aanvoelen niet betaald
            moet worden.(Gratis verrichtingen (geen bankkosten))
            <br>
          </p>
          <div class="line"> </div>
        </div>

        <!-- Icoontjes -->
        <div class="line">
          <hr class="break margin-bottom-70">
          <div class="margin">
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-discussion background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">Betalingsvoorwaarden</h4>
                <p>Alle vermelde prijzen zijn exclusief BTW. Wij vragen een stipte betaling van de facturen binnen de 10 dagen
                  na factuurdatum</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/betalingsvoorwaarden">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-random background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">leveringsvoorwaarden
                  <br>
                </h4>
                <p>Alle goederen worden zonder verzendkosten geleverd voor bestellingen van 100 Euro (exclusief BTW).</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/leveringsvoorwaarden">Lees meer</a>
              </div>
            </div>
            <div class="s-12 m-6 l-4 margin-bottom-60">
              <div class="float-left">
                <i class="icon-pen background-primary icon-circle-small"></i>
              </div>
              <div class="margin-left-80">
                <h4 class="text-uppercase text-strong">hoe bestellen</h4>
                <p>Van 08.30u tot 17.00u (doorlopend)</p>
                <a class="text-more-info text-aqua-hover" routerLink="/NL/hoebestellen">Lees meer</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="background-dark padding text-center"> </div>
    </article>


  </section>

</main>