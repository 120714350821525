import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Menu, mMenuType } from '../models/menu/menu';
import { Observable } from 'rxjs';
import { ResponseService } from './response.service';
import { LoginService } from './login.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class MenuService {

  menuTandarts: Menu[];
  menuTandtechniek: Menu[];
  private apiUrlMenu = this.responseService.apiString + "/menu/menu";

    constructor(private http: HttpClient, private responseService: ResponseService, private loginService: LoginService) {
        this.onGetMenuTandarts();
        this.onGetMenuTandtechnieker();
        this.menuTandarts = [];
        this.menuTandtechniek = [];
    }

    onGetMenuTandarts() {
        var oType = new mMenuType("L");
        if (this.loginService.loggedon)
            oType.Login = this.loginService.login!;
        this.getMenu(oType).subscribe((_menu) => { this.assignMenuTandarts(_menu); });
    }
    onGetMenuTandtechnieker() {
        var oType = new mMenuType("T");
        if (this.loginService.loggedon)
            oType.Login = this.loginService.login!;
        this.getMenu(oType).subscribe((_menu) => { this.assignMenuTandtechnieker(_menu); });
    }

    private getMenu(mMenuType: mMenuType): Observable<Menu[]> {
       return this.http.post<Menu[]>(this.apiUrlMenu, mMenuType, httpOptions);
    }

    private assignMenuTandarts(menu: Menu[]) {
        if (this.menuTandarts == undefined)
            this.menuTandarts = [];
        while (this.menuTandarts.length > 0) {
            this.menuTandarts.pop();
        }

        menu.forEach(element => {
            this.menuTandarts.push(element);

        });
    }
    private assignMenuTandtechnieker(menu: Menu[]) {
        if (this.menuTandtechniek == undefined)
            this.menuTandtechniek = [];
        while (this.menuTandtechniek.length > 0) {
            this.menuTandtechniek.pop();
        }

        menu.forEach(element => {
            this.menuTandtechniek.push(element);

        });
    }
}
