import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { mArtikelPrijs } from '../../../../../models/prijs/martikelprijs';
import { LoginService } from '../../../../../services/login.service';
import { ArtikelService } from '../../../../../services/artikel.service';
import { BasketService } from '../../../../../services/basket.service';
import { FavorietenService } from '../../../../../services/favorieten.service';
import { mFavoriet } from '../../../../../models/favoriet/mfavoriet';
import { LanguageService } from '../../../../../services/language.service';
import { Language, TypeResult } from '../../../../../models/enum';
import { RouterparamService } from 'src/app/services/routerparam.service';

@Component({
  selector: 'app-artikellinecontent',
  templateUrl: './artikellinecontent.component.html',
  styleUrls: ['./artikellinecontent.component.css']
})
export class ArtikellinecontentComponent implements OnInit {

  constructor(private loginService: LoginService, private artikelService: ArtikelService, private basketService: BasketService,
    private favorietenService: FavorietenService, private languageService: LanguageService,private routerparamService: RouterparamService) { }


  @Input() Artikels: mArtikelPrijs[]=[];

  @Input() filterinput: string = "";
  @Input() filterpromo: boolean = false;

  ngOnInit() {
  }

  get logged() {
    return this.loginService.loggedon;
  }

  onArtikelZoom(mFavoriet:mArtikelPrijs){


    var oArrayArt = [];
    oArrayArt.push(mFavoriet);
    this.artikelService.GetArtikels(oArrayArt);

    var oArraykeys:any = [];
    this.artikelService.requestedPrices.forEach(element => {
      oArraykeys.push(element.Artikel.Nr);
    });

    this.routerparamService.Routeer(oArraykeys, TypeResult.Artikel, mFavoriet.Artikel.TypeCode.toString());
  }
  protected showprijs(ArtikelPrijs: mArtikelPrijs) {
    if (ArtikelPrijs.Prijs != undefined)
      return true;

    return false;
  }
  protected showprijsopaanvraag(ArtikelPrijs: mArtikelPrijs) {
    if (ArtikelPrijs.Prijs != undefined && ArtikelPrijs.Prijs.Prijs == 0)
      return true;

    return false;
  }
  protected showkorting1(ArtikelPrijs: mArtikelPrijs) {
    if (ArtikelPrijs.Prijs != undefined && ArtikelPrijs.Prijs.Korting1 != undefined && ArtikelPrijs.Prijs.Korting1 > 0)
      return true;

    return false;
  }
  protected showkorting2(ArtikelPrijs: mArtikelPrijs) {
    if (ArtikelPrijs.Prijs != undefined && ArtikelPrijs.Prijs.Korting2 != undefined && ArtikelPrijs.Prijs.Korting2 > 0)
      return true;

    return false;
  }
  private loadingPrices(ArtikelPrijs: mArtikelPrijs) {
    if (this.loginService.loggedon && !this.artikelService.loaded)
      return true;

    return false;
  }

  onBasket(Artikel: mArtikelPrijs) {
    if (Artikel.Aantal != undefined && Artikel.Aantal > 0)
      this.basketService.AddToBasket(Artikel, Artikel.Aantal);
  }
  onInfo() {
    if (!this.loginService.loggedon) {
      document.getElementById('popuphover')!.style.display = 'inline';
      window.scrollTo(0, 0);
    }

  }
  getClass() {
    if (this.logged)
      return "button border-radius background-green text-white margin-left icon-sli-basket";
    else
      return "button border-radius background-grey text-black margin-left icon-sli-basket"
  }
  getPrijsPromo(Artikel: mArtikelPrijs) {
    if (Artikel != undefined && Artikel.Prijs != undefined && Artikel.Prijs.Promo != undefined && Artikel.Prijs.Promo == true)
      return "dnone";
    else
      return "pricing-price text-size-16 text-green";

  }
  GetPrijs(Artikel: mArtikelPrijs) {
    if (Artikel.Prijs == undefined) return 0;
      return Artikel.Prijs.Prijs;
  }
  GetAantal1(Artikel: mArtikelPrijs) {
    if (Artikel.Prijs == undefined) return 0;
      return Artikel.Prijs.Aantal1;
  }
  GetAantal2(Artikel: mArtikelPrijs) {
    if (Artikel.Prijs == undefined) return 0;
      return Artikel.Prijs.Aantal2;
  }
  GetKorting1(Artikel: mArtikelPrijs) {
    if (Artikel.Prijs == undefined) return 0;
      return Artikel.Prijs.Korting1;
  }
  GetKorting2(Artikel: mArtikelPrijs) {
    if (Artikel.Prijs == undefined)return 0;
      return Artikel.Prijs.Korting2;
  }

  getaantal(Artikel: mArtikelPrijs) {
    return Artikel.Aantal;
  }
  getClassFav(Artikel: mArtikelPrijs) {
    if (Artikel.Favoriet != undefined)
      return "button border-radius background-yellow text-blue margin-left icon-sli-star";
    else
      return "button border-radius background-green text-white margin-left icon-sli-star";

  }
  onFav(ArtikelPrijs: mArtikelPrijs) {
    if (ArtikelPrijs.Favoriet == undefined) {
      ArtikelPrijs.Favoriet = new mFavoriet(ArtikelPrijs.Artikel.Nr, 1);
      this.favorietenService.AddFavoriet(ArtikelPrijs);
    }
    else {
      ArtikelPrijs.Favoriet = undefined
      this.favorietenService.DeleteFavoriet(ArtikelPrijs);
    }
  }
  checkvisible(a: mArtikelPrijs) {
    if ((this.filterinput == undefined || this.filterinput.trim() == "") && this.filterpromo == false)
      return true;
    else {
      if (this.filterpromo) {
        if (a.Prijs != undefined && a.Prijs.Promo == false)
          return false;
        else
          if (this.filterinput == "")
            return true;
      }

      var input = this.filterinput.toLowerCase();
      if (this.languageService.Taal == Language.NL) {
        if ((a.Artikel.OmschrijvijngNL.toLowerCase().indexOf(input) >= 0 || a.Artikel.GroepNL.toLowerCase().indexOf(input) >= 0
          || a.Artikel.Code.toLowerCase().indexOf(input) >= 0 || a.Artikel.Nr.toLowerCase().indexOf(input) >= 0))
          return true;
        else
          return false;
      }
      else {
        if ((a.Artikel.OmschrijvijngFR.toLowerCase().indexOf(input) >= 0 || a.Artikel.GroepFR.toLowerCase().indexOf(input) >= 0
          || a.Artikel.Code.toLowerCase().indexOf(input) >= 0 || a.Artikel.Nr.toLowerCase().indexOf(input) >= 0))
          return true;
        else
          return false;
      }

    }
  }
  getClassHidden(artikel: mArtikelPrijs) {
    if (this.checkvisible(artikel))
      return 'table_row h50';
    else
      return 'table_row h50 dnone';
  }

  translateOMS(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.OmschrijvijngNL;
    else
      return menu.Artikel.OmschrijvijngFR;
  }
  translateGroep(menu: mArtikelPrijs) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.Artikel.GroepNL;
    else
      return menu.Artikel.GroepFR;
  }
  translateString(val: string) {
    return this.languageService.translate(val);
  }
}
