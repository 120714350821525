import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-betalingsvoorwaarden',
  /*template:
  '<div [innerHtml]="myTemplate"></div>',*/
  templateUrl: '../../../assets/html/betalingsvoorwaarden.html',
  styleUrls: ['./betalingsvoorwaarden.component.css']
})
export class BetalingsvoorwaardenComponent implements OnInit {

  constructor(){}
  /*constructor(http: Http) {
    var htmlx = http.get("../../../assets/html/betalingsvoorwaarden.html");
    htmlx.map((html:any) => {
      this.myTemplate = html
    });
}

  private myTemplate: any = "";  */


  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }

}
