import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { LoginService } from '../../../../../services/login.service';
import { ArtikelService } from '../../../../../services/artikel.service';
import { LanguageService } from '../../../../../services/language.service';

@Component({
  selector: 'app-artikelfilter',
  templateUrl: './artikelfilter.component.html',
  styleUrls: ['./artikelfilter.component.css']
})
export class ArtikelfilterComponent implements OnInit {

  constructor(private loginService: LoginService, private artikelService: ArtikelService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  Input: string = "";
  Checked: boolean =false;

  @Output() onFilter = new EventEmitter<string>();
  @Output() onSort = new EventEmitter<string>();
  @Output() onPromo = new EventEmitter<boolean>();
  @Output() onStyle = new EventEmitter<boolean>();


  onChange(waytofilter: string) {
    this.onSort.emit(waytofilter);
  }
  onFilterDown() {
    this.onFilter.emit(this.Input);
  }
  onChecked() {
    this.onPromo.emit(this.Checked);
  }

  get notloggedin(): boolean {
    if (this.loginService.loggedon)
      return false;
    else
      return true;
  }
  get result(): string {
    if (this.artikelService.requestedPrices != undefined && this.artikelService.requestedPrices.length > 0)
      return "{ " + this.artikelService.requestedPrices.length.toString() + "} Results";
    else
      return "";
  }
  blocked: boolean = true;
  getClass() {
    if (this.blocked)
      return "icon-sli-menu text-black icon2x pointer";
    else
      return "icon-sli-grid text-black icon2x pointer";
  }
  changeStyle() {
    this.blocked = !this.blocked;
    this.onStyle.emit(this.blocked);
  }

  translateString(val: string)
  {
    return this.languageService.translate(val);
  }
}
