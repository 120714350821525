import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  DoCheck,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, TitleStrategy } from '@angular/router';
import { TypeKlant, TypeResult } from 'src/app/models/enum';
import {
  mSearchExact,
  mSearchInput,
  mSearchResult,
} from 'src/app/models/search/search';
import { LanguageService } from 'src/app/services/language.service';
import { RouterparamService } from 'src/app/services/routerparam.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-tandarts',
  templateUrl: './tandarts.component.html',
  styleUrls: ['./tandarts.component.css'],
})
export class TandartsComponent implements OnInit,AfterViewInit {
  id: string = '';
  private sub: any;
  private first: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private languageService: LanguageService,
    private routerparamService: RouterparamService
  ) {}
  ngAfterViewChecked(): void {
    if (this.first) {
      this.first = false;
      this.onLoadArtikel(this.id);
    }
  }
  ngAfterContentInit(): void {
    if (this.first) {
      this.first = false;
      this.onLoadArtikel(this.id);
    }
  }

  ngAfterViewInit(): void {
    if (this.first) {
      this.first = false;
      this.onLoadArtikel(this.id);
    }
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id']; // (+) converts string 'id' to a number

    });
  }
  private onLoadArtikel(id: string) {
    if (this.id == '' || this.id == undefined) {
      return;
    }



    if (this.id != undefined && this.id != '') {
      this.searchService
        .getExactSearch(
          new mSearchInput(
            this.languageService.TaalEnumString,
            this.id,
            TypeKlant.Beide
          )
        )
        .subscribe((a) => {
          var ar = [];
          ar.push(this.id);
          this.searchService.AssignSearchResult(a);
          this.routerparamService.Routeer(ar,TypeResult.Artikel,"A");

        });
      // In a real app: dispatch action to load the details here.
    }
  }
  private navigate(a: mSearchResult) {
    if (a.ExacteResultaten.length > 0) {
      if (a.ExacteResultaten[0].Children.length > 0) {
        let artikel: mSearchExact = a.ExacteResultaten[0].Children[0];
        var ar = [];
        ar.push(artikel.ID);
        this.routerparamService.Reset();
        this.routerparamService.Filters = ar;
        this.routerparamService.TypeResult = TypeResult.Artikel;
        //this.routerparamService.Routeer(ar, artikel.Type, 'L');
      }
    }
  }
}
