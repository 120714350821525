import { Component, OnInit } from '@angular/core';
import { FavorietenService } from '../../../../services/favorieten.service';
import { mArtikelPrijs } from '../../../../models/prijs/martikelprijs';
import { ArtikelService } from '../../../../services/artikel.service';
import { RouterparamService } from '../../../../services/routerparam.service';
import { TypeResult, Language } from '../../../../models/enum';
import { BasketService } from '../../../../services/basket.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'app-webshopfavorietenitem',
  templateUrl: './webshopfavorietenitem.component.html',
  styleUrls: ['./webshopfavorietenitem.component.css']
})
export class WebshopfavorietenitemComponent implements OnInit {

  constructor(private favorietenService: FavorietenService, private artikelService: ArtikelService, private routerparamService: RouterparamService,
    private basketService: BasketService, private languageService: LanguageService) { }

  ngOnInit() {
  }

  get FavorietenItems() {
    if (this.favorietenService.favorieten != undefined)
      return this.favorietenService.favorieten;

    return [];
  }
  onDelete(mFavoriet: mArtikelPrijs) {
    this.favorietenService.DeleteFavoriet(mFavoriet);
  }
  onadd(mFavoriet: mArtikelPrijs) {
    if (mFavoriet.Favoriet?.Aantal == undefined)
      mFavoriet.Favoriet!.Aantal = 1;
    else
      mFavoriet.Favoriet.Aantal += 1;
    this.favorietenService.EditFavoriet(mFavoriet);
  }
  onremove(mFavoriet: mArtikelPrijs) {
    if(mFavoriet.Favoriet == undefined)
      this.favorietenService.DeleteFavoriet(mFavoriet);

    if (mFavoriet.Favoriet!.Aantal > 0)
      mFavoriet.Favoriet!.Aantal -= 1;

    if (mFavoriet.Favoriet?.Aantal == undefined || mFavoriet.Favoriet.Aantal == 0)
      this.favorietenService.DeleteFavoriet(mFavoriet);
    else
      this.favorietenService.EditFavoriet(mFavoriet);
  }

  onArtikelZoom(mFavoriet:mArtikelPrijs){


    var oArrayArt = [];
    oArrayArt.push(mFavoriet);
    this.artikelService.GetArtikels(oArrayArt);

    var oArraykeys:any = [];
    this.artikelService.requestedPrices.forEach(element => {
      oArraykeys.push(element.Artikel.Nr);
    });

    this.routerparamService.Routeer(oArraykeys, TypeResult.Artikel, mFavoriet.Artikel.TypeCode.toString());
  }
  getClass() {
      return "button border-radius background-green text-white margin-left icon-sli-basket";
  }
  onBasket(Artikel: mArtikelPrijs) {
    if (Artikel.Favoriet != undefined && Artikel.Favoriet.Aantal > 0)
      this.basketService.AddToBasket(Artikel, Artikel.Favoriet.Aantal);
  }
  getPrice(Artikel: mArtikelPrijs) {
    var prijs = 0;
    var aantal = 0;
    if(Artikel.Favoriet == undefined) return prijs;
    if(Artikel.Prijs == undefined)return prijs;

    if (Artikel.Favoriet.Aantal >= Artikel.Prijs.Aantal1 && aantal < Artikel.Prijs.Aantal1)
      prijs = Artikel.Prijs.Korting1;
    if (Artikel.Favoriet.Aantal >= Artikel.Prijs.Aantal2 && aantal < Artikel.Prijs.Aantal2)
      prijs = Artikel.Prijs.Korting2;

    if (prijs == 0)
      return Artikel.Prijs.Prijs;
    else
      return prijs;
  }
  translate(val: string){
    return this.languageService.translate(val);
  }
  translateOMS(val: mArtikelPrijs) {
    if (this.languageService.Taal == Language.NL)
      return val.Artikel.OmschrijvijngNL;
    else
      return val.Artikel.OmschrijvijngFR;
  }
  translateGroep(val: mArtikelPrijs) {
    if (this.languageService.Taal == Language.NL)
      return val.Artikel.GroepNL;
    else
      return val.Artikel.GroepFR;
  }
}
