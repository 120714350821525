<div class="s-12 m-4 l-3 hide-m hide-s">
    <aside>
        <h5 class="text-uppercase margin-top-30 margin-bottom-20 text-strong">{{translateString('Categorieën')}}</h5>
        <div class="line">
            <div class="aside-nav background-primary-hightlight">
                <ul class="chevron aside-item">
                    <!--SuperGroep-->
                    <li *ngFor="let menuitem of Menu" class="aside-submenu" (click)="showitem()">
                        <a aria-haspopup="true">{{translate(menuitem)}}
                            <span class="count-number">{{menuitem.SubMenu.length}}</span>
                        </a>
                        <ul>
                            <li *ngFor="let menuitemhoofd of menuitem.SubMenu" class="aside-sub-submenu" (click)="showitem()">
                                <!--hoofdgroep-->
                                <a aria-haspopup="true">{{translate(menuitemhoofd)}}
                                    <span class="count-number">{{menuitemhoofd.SubMenu.length}}</span>
                                </a>
                                <ul>
                                    <li>
                                        <a *ngFor="let menuitemgroep of menuitemhoofd.SubMenu" (click)="onMenuClick(menuitemgroep)">{{translate(menuitemgroep)}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="line">
            <div class="margin">
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
                <div class="s-6 m-12 l-6"> </div>
            </div>
        </div>
    </aside>
</div>