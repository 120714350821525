<main role="main">
      
        <section>
          <article>
          <header class="section background-transparent text-center text-dark" data-parallax="scroll" data-image-src="../../assets/images/parallax-03.jpg"> </header>
          <div class="section background-white padding"> 
            <div class="line">
              <h2 class="text-size-30"><em>Prix et ristournes</em></h2>
              <hr class="break break-small background-primary">
              <p><strong> Domiciliation: Vous recevez une réduction directe de 2% sur votre facture</strong></p>
              <blockquote>
                <p>Gain administratif et de temps</p>
              </blockquote>
              <p>Vous pouvez à tout moment modifier ou arrêter chaque paiement si à votre sens, une facture ne doit pas être payée. Transactions gratuites (aucun frais bancaire)<br>
              </p>
  <div class="line"> </div>
            </div>
            
            <!-- Icoontjes --> 
            <div class="line">
              <hr class="break margin-bottom-70">
              <div class="margin">
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-discussion background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">CONDITIONS DE VENTE</h4>
                    <p>Tous nos prix s'entendent hors TVA. Nous exigeons le paiement des factures dans les 10 jours, date facture.</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/betalingsvoorwaarden">Plus d'info</a></div>
                </div>
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-random background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">CONDITIONS DE LIVRAISON<br>
                    </h4>
                    <p>Pour toute commande dépassant les € 100 Euro (hors TVA)</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/leveringsvoorwaarden">Plus d'info</a></div>
                </div>
                <div class="s-12 m-6 l-4 margin-bottom-60">
                  <div class="float-left">
                    <i class="icon-pen background-primary icon-circle-small"></i>
                  </div>
                  <div class="margin-left-80">
                    <h4 class="text-uppercase text-strong">COMMENT PASSER COMMANDE</h4>
                    <p>De 08h30 à 17h00</p>
                    <a class="text-more-info text-aqua-hover" routerLink="/FR/hoebestellen">Plus d'info </a></div>
                </div>
  </div>
            </div>
  </div> 
       <div class="background-dark padding text-center"> </div>
        </article>
          
          
        </section>
        
        </main>
      