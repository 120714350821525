import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mArtikelPrijs } from '../models/prijs/martikelprijs';
import { mLogin } from '../models/login/login';
import { mPrice } from '../models/prijs/mprice';
import { ResponseService } from './response.service';
import { FavorietenService } from './favorieten.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable()
export class ArtikelService {
    requestedPrices: mArtikelPrijs[]=[];
    loaded: boolean = true;
    login?: mLogin;

    private apiUrlPrices = this.responseService.apiString + "/menu/prices";

    constructor(private http: HttpClient, private responseService: ResponseService, private favorietenService: FavorietenService) { }

    GetArtikels(Prices: mArtikelPrijs[]) {
        this.AssignPrices(Prices);

        var mLogin = this.login;
        if (mLogin != undefined && mLogin.Succes != undefined && mLogin.Succes != false && Prices != undefined && Prices.length > 0) {
            this.onGetArtikelPrices(Prices, mLogin);
        }
    }

    private onGetArtikelPrices(Prices: mArtikelPrijs[], mLogin: mLogin) {
        var Price = new mPrice();
        Price.Artikels = Prices;
        Price.Login = mLogin;
        this.loaded = false;
        this.getArtikelPrices(Price).subscribe((_requestedPrices) => {
            this.AssignOnReturn(_requestedPrices);
            this.favorietenService.GetFavorieten();
        });
    }

    private getArtikelPrices(Price: mPrice): Observable<mPrice> {
      httpOptions.headers =
          httpOptions.headers.set('authorization', this.login!.Hash);

        return this.http.post<mPrice>(this.apiUrlPrices, Price, httpOptions);
    }

    AssignOnReturn(input: mPrice)
    {
        this.AssignPrices(input.Artikels);
        this.loaded = true;
    }
    AssignPrices(prices: mArtikelPrijs[]) {
        if (this.requestedPrices == undefined)
            this.requestedPrices = [];
        while (this.requestedPrices.length > 0) {
            this.requestedPrices.pop();
        }

        prices.forEach(element => {
            this.requestedPrices.push(element);

        });
    }
    EmptyCurrent() {
        if (this.requestedPrices != undefined) {
            var prices = this.requestedPrices.slice();
            if (this.requestedPrices == undefined)
                this.requestedPrices = [];

            while (this.requestedPrices.length > 0) {
                this.requestedPrices.pop();
            }
            prices.forEach(element => {
                element.Prijs = undefined;
                this.requestedPrices.push(element);
            });
        }
    }
    OrderArtikels(type: string) {
        switch (type) {
            case "TitelA":
              this.requestedPrices.sort((a,b)=>a.Artikel.OmschrijvijngNL.localeCompare(b.Artikel.OmschrijvijngNL));
                //this.requestedPrices.sort(this.dynamicSort("OmschrijvijngNL"));
                break;
            case "TitelZ":
              this.requestedPrices.sort((a,b)=>a.Artikel.OmschrijvijngNL>b.Artikel.OmschrijvijngNL?-1:1);
                //this.requestedPrices.sort(this.dynamicSort("-OmschrijvijngNL"));
                break;
            case "PrijsL":
              this.requestedPrices.sort((a,b)=>a.Prijs!.Prijs>a.Prijs!.Prijs ?1:-1);
                //this.requestedPrices.sort(this.dynamicSortPrijs("Prijs"));
                break;
            case "PrijsH":
              this.requestedPrices.sort((a,b)=>a.Prijs!.Prijs>a.Prijs!.Prijs ?-1:1);
                //this.requestedPrices.sort(this.dynamicSortPrijs("-Prijs"));
                break;
            case "MerkA":
              this.requestedPrices.sort((a,b)=>a.Artikel.GroepNL>b.Artikel.GroepNL?1:-1);
                //this.requestedPrices.sort(this.dynamicSort("GroepNL"));
                break;
            case "MerkZ":
              this.requestedPrices.sort((a,b)=>a.Artikel.GroepNL>b.Artikel.GroepNL?-1:1);
                //this.requestedPrices.sort(this.dynamicSort("-GroepNL"));
                break;
            case "ArtA":
              this.requestedPrices.sort((a,b)=>a.Artikel.ProductCode>b.Artikel.ProductCode?1:-1);
                //this.requestedPrices.sort(this.dynamicSort("ProductCode"));
                break;
            case "ArtZ":
              this.requestedPrices.sort((a,b)=>a.Artikel.ProductCode>b.Artikel.ProductCode?-1:1);
                //this.requestedPrices.sort(this.dynamicSort("-ProductCode"));
                break;
            default:
                break;
        }
    }

    /*dynamicSort(property:any) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a: mArtikelPrijs, b: mArtikelPrijs) {
            var result = (a.Artikel[property] < b.Artikel[property]) ? -1 : (a.Artikel[property] > b.Artikel[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    dynamicSortPrijs(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a: mArtikelPrijs, b: mArtikelPrijs) {
            var result = ((a.Prijs?[property]) < (b.Prijs?[property])) ? -1 : (a.Prijs[property] > b.Prijs[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }*/
}
