<main role="main">

  <section>
    <article>
      <header class="section background-green-hightlight background-transparent text-center background-blue" data-parallax="scroll"
        data-image-src="../../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1 text-s-size-16">Disclaimer</h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue">
              <a routerLink="/NL/home"> Home</a>
            </p>
            <hr class="break break-small background-primary">
            <p>Voor alle duidelijkheid wijst Providental BVBA, ten aanzien van de website www.Providental.be u op het volgende:
              De website en alle onderdelen daarvan, met uitzondering van bepaalde (hyper)links, zijn eigendom van Providental.
              Het is niet toegestaan om de site of gedeelten daarvan openbaar te maken, te kopiëren of op te slaan zonder
              uitdrukkelijke schriftelijke toestemming van Providental. De op deze website getoonde informatie wordt door
              Providental met constante zorg en aandacht samengesteld. Desalniettemin is het mogelijk dat informatie die
              op de site wordt gepubliceerd onvolledig en/of onjuist is. De informatie op de site wordt geregeld aangevuld
              en/of aangepast. Providental behoudt zich het recht voor om eventuele wijzigingen met onmiddellijke ingang
              en zonder enige kennisgeving door te voeren. Alle informatie, producten en diensten worden aangeboden in de
              staat waarin zij zich feitelijk bevinden en zonder enige (impliciete) garantie of waarborg ten aanzien van
              hun verkrijgbaarheid, deugdelijkheid, geschiktheid voor een bepaald doel of anderszins. Hoewel Providental
              alles in het werk stelt om misbruik te voorkomen, is Providental niet aansprakelijk voor informatie en/of berichten
              die door gebruikers van de website via Internet verzonden worden. Bepaalde (hyper)links in deze site leiden
              naar websites buiten het domein van Providental, welke geen eigendom zijn van Providental, maar zijn louter
              ter informatie van de bezoeker opgenomen. Indien deze links geactiveerd worden, verlaat men de website van
              Providental. Hoewel Providental uiterst selectief is ten aanzien van de sites waarnaar verwezen wordt, kan
              zij niet instaan voor de inhoud en het functioneren daarvan, noch voor de kwaliteit van eventuele producten
              of diensten die daarop worden aangeboden. Elke aansprakelijkheid voor de inhoud van websites die niet door
              Providental worden onderhouden wordt afgewezen. Providental sluit alle aansprakelijkheid uit voor enigerlei
              directe of indirecte schade, van welke aard dan ook, die voortvloeit uit of in enig opzicht verband houdt met
              de website. </p>
            <div class="box margin-bottom">
              <div class="margin">
                <!-- CONTENT -->

                <!-- ASIDE NAV -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Kleurbalk -->
      <div class="background-dark padding text-center"> </div>

    </article>


  </section>

</main>