<div class="s-12 m-6 l-4 margin-top-30 artikelfiche">
    <div class="maybe"></div>
    <a (click)="onClick(ArtikelPrijs)">
        <div [ngClass]="getpromo()" class="onhover">
            <h3 class="pricing-title text-center text-s-size-12 text-m-size-16 text-l-size-16 pheader">

                <strong>{{translateOMS(ArtikelPrijs)}}</strong>

            </h3>
        </div>
    </a>
    <div class="pricing-table margin-bottom count-to">
        <div class="whiter block-bordered">
            <img [src]="ArtikelPrijs.Artikel.FotoUrl" [alt]="ArtikelPrijs.Artikel.OmschrijvijngNL" width="400" class="center block imager"
                (click)="onClick(ArtikelPrijs)">
        </div>
        <p class="margin-left-10">
            <strong>- {{translateString('Merk')}}</strong>: {{translateGroep(ArtikelPrijs)}}</p>
        <p class="margin-left-10">
            <strong>- {{translateString('Productnr')}}.</strong>: {{ArtikelPrijs.Artikel.ProductNr}}</p>
        <p class="margin-left-10">
            <strong>- {{translateString('Art.nr')}}.</strong>: {{ArtikelPrijs.Artikel.ProductCode}}</p>
        <p class="margin-left-10">
            <strong>- {{translateString('Inhoud')}}</strong>: {{translateInhoud(ArtikelPrijs)}}</p>
        <div [hidden]="!showprijs  || showprijsopaanvraag">
            <p [ngClass]="getPrijsPromo()"> {{translateString('Stukprijs')}}: €
                <span class="timer" data-from="0" data-to="52.90" data-speed="2000" data-decimals="2">{{Prijs}}</span>
            </p>

            <div class="pricing-price text-size-16 text-strong text-red no-pad" [hidden]="!showkorting1">{{translateString('Stukprijs per')}} {{Aantal1}}: €
                <span class="timer" data-from="4" data-to="38.00" data-speed="2000" data-decimals="2">{{Korting1}}</span>
            </div>
            <div class="pricing-price text-size-16 text-strong text-red no-pad" [hidden]="!showkorting2">{{translateString('Stukprijs per')}} {{Aantal2}}: €
                <span class="timer" data-from="4" data-to="38.00" data-speed="2000" data-decimals="2">{{Korting2}}</span>
            </div>

            <div class="line">
                <div class="margin">
                    <div class="s-12 m-12 l-12 margin-bottom-10 text-m-size-12">
                        <div class="s-12 m-12 l-5 margin-top-10 text-m-size-12">
                            <input name="number" type="number" class="margin-left-10 s-10 inputlarge" id="number" [placeholder]="translateString('Aantal')" title="Aantal"
                                [(ngModel)]="Aantal">
                        </div>
                        <div class="s-6 m-6 l-3 margin-top-10 text-m-size-12">
                            <a class="button border-radius background-green text-white margin-left icon-sli-basket" (click)="onBasket()"></a>
                        </div>
                        <div class="s-6 m-6 l-3 margin-top-10 text-m-size-12">
                            <a [ngClass]="getClassFav()" (click)="onFav()"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="loadingPrices">
            <div [hidden]="showprijs">
                <li class="text-center text-green" (click)="onInfo()">
                    <span class="tooltip-container">
                        <span class="tooltip-content tooltip-top" style="display: none; top: -38px; left: 382.797px;">
                        </span>
                        {{translateString('Prijsinformatie')}}
                    </span>
                </li>
            </div>
            <div [hidden]="!showprijsopaanvraag">
                <li class="text-center text-green">
                    <span class="tooltip-container">
                        <span class="tooltip-content tooltip-top" style="display: none; top: -38px; left: 382.797px;">
                        </span>
                        <a title="prijs op aanvraag">{{translateString('P.O.A.')}}</a>
                    </span>
                </li>
            </div>
        </div>
        <div [hidden]="!loadingPrices">
            <div class="loader"></div>
            <div class="loadertext s-12">{{translateString('Loading prices')}}</div>
        </div>
    </div>
</div>