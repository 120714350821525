import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-laboFR',
  templateUrl: './laboFR.component.html',
  styleUrls: ['./laboFR.component.css']
})
export class LaboFRComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
