import { Component, OnInit } from '@angular/core';
import { mBestellingDetail } from '../../../../../models/bestelling/mbestellingdetail';
import { BestellingenService } from '../../../../../services/bestellingen.service';
import { LanguageService } from '../../../../../services/language.service';
import { Language, TypeResult } from '../../../../../models/enum';
import { ArtikelService } from 'src/app/services/artikel.service';
import { mArtikelPrijs } from 'src/app/models/prijs/martikelprijs';
import { RouterparamService } from 'src/app/services/routerparam.service';

@Component({
  selector: 'app-webshopbestellingenitemdetails',
  templateUrl: './webshopbestellingenitemdetails.component.html',
  styleUrls: ['./webshopbestellingenitemdetails.component.css']
})
export class WebshopbestellingenitemdetailsComponent implements OnInit {

  constructor(private bestellingenService: BestellingenService,private routerparamService: RouterparamService, private languageService: LanguageService, private artikelService: ArtikelService,) { }

  filtered: boolean = false;
  ngOnInit() {
  }
  get BestellingenDetailItems() {
    if (this.bestellingenService.CurrentSelected != undefined)
    {
      if (this.filtered)
      {
        return this.bestellingenService.CurrentSelected.Details.filter(a => a.Geleverd != a.Aantal);
      }
      else
        return this.bestellingenService.CurrentSelected.Details;
    }
    else
      return [];
  }
  translate(val: string){
    return this.languageService.translate(val);
  }
  translateOMS(val: mBestellingDetail) {
    if (this.languageService.Taal == Language.NL)
      return val.mArtikel.OmschrijvijngNL;
    else
      return val.mArtikel.OmschrijvijngFR;
  }
  translateGroep(val: mBestellingDetail) {
    if (this.languageService.Taal == Language.NL)
      return val.mArtikel.GroepNL;
    else
      return val.mArtikel.GroepFR;
  }
  onArtikelZoom(bestellingdDetail:mBestellingDetail){


    var oArrayArt = [];
    oArrayArt.push(bestellingdDetail.mArtikel);
    //this.artikelService.GetArtikels(oArrayArt);

    var oArraykeys:any = [];
    this.artikelService.requestedPrices.forEach(element => {
      oArraykeys.push(element.Artikel.Nr);
    });

    this.routerparamService.Routeer(oArraykeys, TypeResult.Artikel, bestellingdDetail.mArtikel.TypeCode.toString());
  }
}
