import { TypeKlant } from "../enum";

export class mLogin {
    public Hash!: string;
    public Succes!: Boolean;
    public Login!: String;
    public Password!: String;
    public KlantNr!: string;
    public KlantNaam!: string;
    public TypeKlant!: TypeKlant;

    constructor() {
    }
  }
