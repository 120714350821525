import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Menu } from '../../../../../models/menu/menu';
import { LanguageService } from '../../../../../services/language.service';
import { Language } from '../../../../../models/enum';

@Component({
  selector: 'app-hoofdgroepcontent',
  templateUrl: './hoofdgroepcontent.component.html',
  styleUrls: ['./hoofdgroepcontent.component.css']
})
export class HoofdgroepcontentComponent implements OnInit {

  @Input() Menu: Menu;
  @Output() onClicked = new EventEmitter<Menu>();

  constructor(private languageService: LanguageService) { }



  ngOnInit() {
  }
  onclick(_menu: Menu) {
    this.onClicked.emit(_menu);
  }
  translate(menu: Menu) {
    if (menu == undefined)
      return "";
    if (this.languageService.Taal == Language.NL)
      return menu.OmschrijvingNL;
    else
      return menu.OmschrijvingFR;
  }
  translateString(val: string)
  {
    return this.languageService.translate(val);
  }
}
