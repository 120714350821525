import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseService } from './response.service';
import { LoginService } from './login.service';
import { mLogin } from '../models/login/login';
import {
  mBasket,
  mBasketItem,
  mLeverenEncap,
  mKlantAfleveradres,
} from '../models/basket/basket';
import {
  mSearchBarcode,
  mSearchDeleteBasketItem,
  mSearchAddBasketItem,
  mSearchAddBasketItemID,
  mSearchConfirmOrder,
} from '../models/search/search';
import { mArtikelPrijs } from '../models/prijs/martikelprijs';
import { mOrder } from '../models/order/order';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable()
export class BasketService {
  constructor(
    private http: HttpClient,
    private responseService: ResponseService
  ) {
    this.BasketChangedSubject = new Subject<boolean>();
    this.BasketChanged = this.BasketChangedSubject.asObservable();
  }

  private apiUrlBasket_GET = this.responseService.apiString + '/basket/get';
  private apiUrlBasket_ADD = this.responseService.apiString + '/basket/add';
  private apiUrlBasket_IDADD = this.responseService.apiString + '/basket/addid';
  private apiUrlBasket_EDIT = this.responseService.apiString + '/basket/edit';
  private apiUrlBasket_DEL = this.responseService.apiString + '/basket/delete';
  private apiUrlBasket_BARCODE = this.responseService.apiString + '/basket/barcode';
  private apiUrlBasket_AFL =  this.responseService.apiString + '/basket/afleveradressen';
  private apiUrlBasket_ORDER = this.responseService.apiString + '/basket/createorder';

  Basket?: mBasket;
  Order!: mOrder;
  BasketItems!: mBasketItem[];
  OrderItems!: mBasketItem[];
  LeverenEncap?: mLeverenEncap;
  AfleverAdressen!: mKlantAfleveradres[];
  login?: mLogin;

  OrderSucceeded = false;

  private BasketChangedSubject: Subject<boolean>;
  BasketChanged: Observable<boolean>;

  get message(): string {
    if (this.Basket != undefined) return this.Basket.Message;

    return '';
  }
  onGetBasket() {
    var mLogin = this.login;
    if (
      mLogin != undefined &&
      mLogin.Succes != undefined &&
      mLogin.Succes == true
    ) {
      this.APIgetBasket(mLogin).subscribe((_basket) => {
        this.Basket = _basket;
        this.AssignCurrentBasketItems(_basket.Artikels);
      });
    }
  }
  onEditBasketItem(mBasketItem: mBasketItem) {
    this.OrderSucceeded = false;
    var mLogin = this.login;
    if (
      mLogin != undefined &&
      mLogin.Succes != undefined &&
      mLogin.Succes == true
    ) {
      var SearchDeleteBasketItem = new mSearchDeleteBasketItem(
        mLogin,
        this.Basket,
        mBasketItem
      );
      this.APIeditBasket(SearchDeleteBasketItem).subscribe((_basket) => {
        this.Basket = _basket;
        this.AssignCurrentBasketItems(_basket.Artikels);
      });
    }
  }
  onDeleteBasketItem(mBasketItem: mBasketItem) {
    this.OrderSucceeded = false;
    var mLogin = this.login;
    if (
      mLogin != undefined &&
      mLogin.Succes != undefined &&
      mLogin.Succes == true
    ) {
      var SearchDeleteBasketItem = new mSearchDeleteBasketItem(
        mLogin,
        this.Basket,
        mBasketItem
      );
      this.APIdeleteBasket(SearchDeleteBasketItem).subscribe((_basket) => {
        this.Basket = _basket;
        this.AssignCurrentBasketItems(_basket.Artikels);
      });
    }
  }
  onZoekBarcode(input: string, aantal: number) {
    this.OrderSucceeded = false;
    if (input != undefined && input != '') {
      var mLogin = this.login;
      console.log(mLogin);
      if (
        mLogin != undefined &&
        mLogin.Succes != undefined &&
        mLogin.Succes == true
      ) {
        var mSearchBasket = new mSearchBarcode(mLogin, this.Basket, input);
        mSearchBasket.Aantal = aantal;
        if (mSearchBasket.Aantal == 0) mSearchBasket.Aantal = 1;

        this.APIzoekBarcode(mSearchBasket).subscribe((_basket) => {
          console.log(_basket);
          this.Basket = _basket;
          this.AssignCurrentBasketItems(_basket.Artikels);
        });
      }
    }
  }
  AddToBasket(Artikel: mArtikelPrijs, Aantal: number) {
    var mLogin = this.login;
    if (
      mLogin != undefined &&
      mLogin.Succes != undefined &&
      mLogin.Succes == true
    ) {
      var SearchAddBasketItem = new mSearchAddBasketItem(
        mLogin,
        this.Basket,
        Artikel,
        Aantal
      );
      this.APIaddBasket(SearchAddBasketItem).subscribe((_basket) => {
        this.Basket = _basket;
        this.AssignCurrentBasketItems(_basket.Artikels);
        this.BasketChangeTrigger(true);
      });
    }
  }
  AddToBasketID(ArtikelID: string) {
    var mLogin = this.login;
    if (
      mLogin != undefined &&
      mLogin.Succes != undefined &&
      mLogin.Succes == true
    ) {
      var SearchAddBasketItem = new mSearchAddBasketItemID(
        mLogin,
        this.Basket,
        ArtikelID,
        1
      );
      this.APIaddBasketID(SearchAddBasketItem).subscribe((_basket) => {
        this.Basket = _basket;
        this.AssignCurrentBasketItems(_basket.Artikels);
        this.BasketChangeTrigger(true);
      });
    }
  }
  onGetAfleveradressen(mLogin?: mLogin) {
    var mLogin = this.login;
    if (
      mLogin != undefined &&
      mLogin.Succes != undefined &&
      mLogin.Succes == true
    ) {
      this.APIgetAfleveradressen(mLogin).subscribe((_afleverencap) => {
        this.AssignAfleverAdressen(_afleverencap);
      });
    }
  }
  onOrder(img: any, isNL: boolean) {
    var mLogin = this.login;
    if (
      mLogin != undefined &&
      mLogin.Succes != undefined &&
      mLogin.Succes == true
    ) {
      if (this.LeverenEncap != undefined) {
        this.APIconfirmOrder(mLogin, this.LeverenEncap, img, isNL).subscribe(
          (_order) => {
            if (_order.Succes) this.OrderSucceeded = true;

            this.Basket = _order.Basket;
            this.AssignCurrentBasketItems(_order.Basket!.Artikels);
            this.Order = _order;
            this.AssignCurrentOrderItems(_order.Order!.Artikels);
          }
        );
      }
    }
  }

  private APIzoekBarcode(mSearchBarcode: mSearchBarcode): Observable<mBasket> {
    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mBasket>(
      this.apiUrlBasket_BARCODE,
      mSearchBarcode,
      httpOptions
    );
  }
  private APIgetBasket(mLogin: mLogin): Observable<mBasket> {
    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mBasket>(this.apiUrlBasket_GET, mLogin, httpOptions);
  }
  private APIdeleteBasket(
    mSearch: mSearchDeleteBasketItem
  ): Observable<mBasket> {
    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mBasket>(this.apiUrlBasket_DEL, mSearch, httpOptions);
  }
  private APIeditBasket(mSearch: mSearchDeleteBasketItem): Observable<mBasket> {
    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mBasket>(
      this.apiUrlBasket_EDIT,
      mSearch,
      httpOptions
    );
  }
  private APIaddBasket(mSearch: mSearchAddBasketItem): Observable<mBasket> {
    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mBasket>(this.apiUrlBasket_ADD, mSearch, httpOptions);
  }
  private APIaddBasketID(mSearch: mSearchAddBasketItemID): Observable<mBasket> {
    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mBasket>(
      this.apiUrlBasket_IDADD,
      mSearch,
      httpOptions
    );
  }
  private APIgetAfleveradressen(mLogin: mLogin): Observable<mLeverenEncap> {
    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mLeverenEncap>(
      this.apiUrlBasket_AFL,
      mLogin,
      httpOptions
    );
  }
  private APIconfirmOrder(
    mLogin: mLogin,
    afleveradres: mLeverenEncap,
    img: any,
    isNL: boolean
  ): Observable<mOrder> {
    var item = new mSearchConfirmOrder(mLogin, afleveradres, img, isNL);

    httpOptions.headers = httpOptions.headers.set(
      'authorization',
      this.login!.Hash
    );

    return this.http.post<mOrder>(this.apiUrlBasket_ORDER, item, httpOptions);
  }

  AssignCurrentBasketItems(items: mBasketItem[]) {
    if (this.BasketItems == undefined) this.BasketItems = [];
    while (this.BasketItems.length > 0) {
      this.BasketItems.pop();
    }
    items.forEach((element) => {
      if (element.Artikel != undefined && element.Artikel.Prijs != undefined) {
        var aantal = 0;
        var prijs = 0;
        if (
          element.Artikel.Prijs.Aantal1 <= element.Aantal &&
          aantal < element.Artikel.Prijs.Aantal1
        ) {
          aantal = element.Artikel.Prijs.Aantal1;
          prijs = element.Artikel.Prijs.Korting1;
        }
        if (
          element.Artikel.Prijs.Aantal2 <= element.Aantal &&
          aantal < element.Artikel.Prijs.Aantal2
        ) {
          aantal = element.Artikel.Prijs.Aantal2;
          prijs = element.Artikel.Prijs.Korting2;
        }
        if (prijs == 0) {
          prijs = element.Artikel.Prijs.Prijs;
        }
        element.Totaal = Math.round(element.Aantal * prijs * 100) / 100;
      }

      this.BasketItems.push(element);
    });
  }
  AssignCurrentOrderItems(items: mBasketItem[]) {
    if (this.OrderItems == undefined) this.OrderItems = [];
    while (this.OrderItems.length > 0) {
      this.OrderItems.pop();
    }
    items.forEach((element) => {
      if (element.Artikel?.Prijs == undefined) return;

      var aantal = 0;
      var prijs = 0;
      if (
        element.Artikel.Prijs.Aantal1 <= element.Aantal &&
        aantal < element.Artikel.Prijs.Aantal1
      ) {
        aantal = element.Artikel.Prijs.Aantal1;
        prijs = element.Artikel.Prijs.Korting1;
      }
      if (
        element.Artikel.Prijs.Aantal2 <= element.Aantal &&
        aantal < element.Artikel.Prijs.Aantal2
      ) {
        aantal = element.Artikel.Prijs.Aantal2;
        prijs = element.Artikel.Prijs.Korting2;
      }
      if (prijs == 0) {
        prijs = element.Artikel.Prijs.Prijs;
      }
      element.Totaal = Math.round(element.Aantal * prijs * 100) / 100;

      this.OrderItems.push(element);
    });
  }
  AssignAfleverAdressen(item: mLeverenEncap) {
    this.LeverenEncap = item;

    if (this.AfleverAdressen == undefined) this.AfleverAdressen = [];
    while (this.AfleverAdressen.length > 0) {
      this.AfleverAdressen.pop();
    }
    item.Adressen.forEach((element) => {
      this.AfleverAdressen.push(element);
    });
  }
  EmptyCurrent() {
    if (this.BasketItems != undefined) {
      var prices = this.BasketItems.slice();
      if (this.BasketItems == undefined) this.BasketItems = [];

      while (this.BasketItems.length > 0) {
        this.BasketItems.pop();
      }
    }
    if (this.AfleverAdressen != undefined) {
      if (this.AfleverAdressen == undefined) this.AfleverAdressen = [];

      while (this.AfleverAdressen.length > 0) {
        this.AfleverAdressen.pop();
      }
    }
  }
  BasketChangeTrigger(newValue: boolean) {
    this.BasketChangedSubject.next(newValue);
  }
}
