import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { mSearchOutput, mSearchInput, mSearchExact } from '../../models/search/search';
import { LoginService } from '../../services/login.service';
import { TypeKlant } from '../../models/enum';
import { LanguageService } from '../../services/language.service';

declare var $: any;

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent implements OnInit {

  constructor(private SearchService: SearchService, private loginService: LoginService, private languageService: LanguageService) { }

  inputdata: string = "";

  get FoundResults(): mSearchOutput[] {
    return this.SearchService.getResult();
  }
  get ExactResults(): mSearchExact[] {
    return this.SearchService.getExacteResult();
  }

  ngOnInit() {

  }

  onkeypress(event: KeyboardEvent) {
    if (this.inputdata.length > 2) {
      document.getElementById("dspopupboxh")!.style.opacity = "1";
      document.getElementById("dspopupboxh")!.style.visibility = "visible";
      if (this.loginService.login != undefined && this.loginService.login.Succes == true)
        this.SearchService.onGetSearch(new mSearchInput(this.languageService.TaalEnumString, this.inputdata, this.loginService.login.TypeKlant));
      else
        this.SearchService.onGetSearch(new mSearchInput(this.languageService.TaalEnumString, this.inputdata, TypeKlant.Beide));
    }
    else {
      document.getElementById("dspopupboxh")!.style.opacity = "0";
      document.getElementById("dspopupboxh")!.style.visibility = "hidden";
    }

    if (event.key == "Enter") {
      document.getElementById("dspopupboxh")!.style.opacity = "1";
      document.getElementById("dspopupboxh")!.style.visibility = "visible";
      if (this.loginService.login != undefined && this.loginService.login.Succes == true)
        this.SearchService.onGetSearch(new mSearchInput(this.languageService.TaalEnumString, this.inputdata, this.loginService.login.TypeKlant));
      else
        this.SearchService.onGetSearch(new mSearchInput(this.languageService.TaalEnumString, this.inputdata, TypeKlant.Beide));
    }
  }
  onleaveinput() {
    document.getElementById('dspopupboxh')!.focus();
  }
  onleave() {
    document.getElementById("dspopupboxh")!.style.opacity = "0";
    document.getElementById("dspopupboxh")!.style.visibility = "hidden";
  }
  onenter() {
    if (this.inputdata.length > 2) {
      document.getElementById("dspopupboxh")!.style.opacity = "1";
      document.getElementById("dspopupboxh")!.style.visibility = "visible";
    }
    else {
      document.getElementById("dspopupboxh")!.style.opacity = "0";
      document.getElementById("dspopupboxh")!.style.visibility = "hidden";
    }
  }

  click() {
    this.onleave();
  }

  translate(val: string){
    return this.languageService.translate(val);
  }
}
