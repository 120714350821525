
    <main role="main">
      
      <article>
        <header class="section background-transparent text-center text-blue" data-parallax="scroll" data-image-src="../../assets/images/parallax-01.jpg">
          <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1"><strong>Contact</strong></h1>
        </header>
        <div class="section background-white padding"> 
          <div class="line">
            <div class="margin">
              
              <!-- Company Information -->
              <div class="s-12 m-12 l-6">
                <h2 class="text-uppercase text-strong">Bedrijfs-Info</h2>
                <hr class="break break-small background-primary">
                <div class="float-left">
                  <i class="icon-placepin background-primary icon-circle-small text-size-20"></i>
                </div>
                <div class="margin-left-80 margin-bottom">
                  <h4 class="text-strong margin-bottom-0">Adres</h4>
                  <p>Muizenstraat 12A<br>
                    2060 Antwerpen<br>
                  Belgium</p>
                </div>
                <div class="float-left">
                  <i class="icon-paperplane_ico background-primary icon-circle-small text-size-20"></i>
                </div>
                <div class="margin-left-80 margin-bottom">
                  <h4 class="text-strong margin-bottom-0">E-mail</h4>
                  <p>order@providental.com<br>
                    info@providental.be</p>
                </div>
                <div class="float-left">
                  <i class="icon-smartphone background-primary icon-circle-small text-size-20"></i>
                </div>
                <div class="margin-left-80">
                  <h4 class="text-strong margin-bottom-0">Tel/Openingsuren</h4>
                  <p>T / 03 226 00 81</p>
                  <p><strong>Maandag tot vrijdag: 8.30 - 17.00u</strong></p>
                </div>
              </div>
              
              <!-- Contact Form -->
              <div class="s-12 m-12 l-6" [hidden]="send">
                <h2 class="text-uppercase text-strong margin-m-top-50">Een vraagje?</h2>
                <hr class="break break-small background-primary">
                <!-- If you want to make a field required, add the "required" class to the input. -->
                <form name="contactForm" class="customform ajax-form" method="post" action="email.php">
                  <div class="line">
                    <div class="margin">
                      <div class="s-12 m-12 l-6">
                        <input name="email" class="required email border-radius" placeholder="Uw e-mail" title="Uw e-mail" type="text"  [(ngModel)]="mContact.Email"/>
                        <p class="email-error form-error">Plaats hier uw e-mail.</p>
                      </div>
                      <div class="s-12 m-12 l-6">
                        <input name="name" class="name border-radius" placeholder="Uw naam" title="Uw naam" type="text"  [(ngModel)]="mContact.Naam"/>
                        <p class="name-error form-error">Vul hier uw naam.</p>
                      </div>
                    </div>
                  </div>
                  <div class="s-12"> 
                    <input name="subject" class="subject border-radius" placeholder="Onderwerp" title="Onderwerp" type="text"  [(ngModel)]="mContact.Onderwerp"/>
                    <p class="subject-error form-error">Plaats hier uw onderwerp.</p>
                  </div>
                  <div class="s-12">
                    <textarea name="message" class="required message border-radius" placeholder="Uw bericht" rows="3"  [(ngModel)]="mContact.Bericht"></textarea>
                    <p class="message-error form-error">Plaats hier uw bericht.</p>
                  </div>
                  <div class="s-12 m-12 l-4"><button class="submit-form button background-primary border-radius text-white" type="submit" (click)="click()">Verzenden</button></div>
                  
                  <div class="line">
                    <p class="mail-success form-success">Uw bericht werd succesvol verzonden.</p>
                    <p class="mail-fail form-error">Sorry, error occured this time sending your message.</p>
                  </div>  
                </form>
              </div>  
              <div  [hidden]="!send">uw bericht is verzonden.</div>
            </div>  
          </div> 
        </div> 
      </article>
        <div class="background-dark padding text-center">
			<h1>Route</h1>                                                                       
      </div>
      <!-- MAP -->
      <div class="s-12 grayscale center">  	  
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2498.832042430689!2d4.4191876159171395!3d51.22216963933855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f7aa7097a65f%3A0xf51d3ec7096563b8!2sMuizenstraat%2012%2C%202060%20Antwerpen!5e0!3m2!1snl!2sbe!4v1633615225518!5m2!1snl!2sbe" width="100%" height="450" frameborder="0" style="border:0" een></iframe> 
      </div> 
        
         
  </main>