<div class="s-12 m-12 l-9 padded-left">
    <div class="line">
        <div>
            <a class="backlink text-aqua-hover" *ngFor="let fol of BackHelpers" (click)="onBack(fol)">{{fol.Naam}}/ </a>
        </div>
        <div [hidden]="!checktypes()">
            <div *ngFor="let menuitem of Menu" class="s-12 m-6 l-3 margin-top-60 inhouditem">
                <div class="inhoud1">
                    <app-supergroepcontent [hidden]="checkstringtype('supergroep')" (onClicked)="onclicksupergroepitem($event)" [Menu]="menuitem"></app-supergroepcontent>
                    <app-hoofdgroepcontent [hidden]="checkstringtype('hoofdgroep')" (onClicked)="onclickhoofdgroepitem($event)" [Menu]="menuitem"></app-hoofdgroepcontent>
                    <app-groepcontent [hidden]="checkstringtype('groep')" (onClicked)="onclickgroepitem($event,false)" [Menu]="menuitem"></app-groepcontent>
                </div>
            </div>
        </div>
        <div [hidden]="checkstringtype('artikel')">
            <div class="s-12">
                <app-artikelfilter (onFilter)="onFilter($event)" (onSort)="onSort($event)" (onPromo)="onPromo($event)" (onStyle)="onStyle($event)"></app-artikelfilter>
            </div>
            <div [hidden]="!GetSquared()">
                <div *ngFor="let artikel of Artikels">
                    <div [hidden]="!checkvisible(artikel)">
                        <app-artikelcontent (onClicked)="onclickartikelitem($event)" [ArtikelPrijs]="artikel"></app-artikelcontent>
                    </div>
                </div>
            </div>
            <div [hidden]="GetSquared()">
               <app-artikellinecontent [Artikels]="Artikels" [filterinput]="filterinput" [filterpromo]="filterpromo"></app-artikellinecontent>
            </div>
        </div>
    </div>
    <div [hidden]="checkstringtype('singleartikel')">
        <div *ngFor="let artikel of Artikels">
            <div>
                <app-singleartikelcontent [Artikel]="artikel"></app-singleartikelcontent>
            </div>
        </div>
    </div>
</div>
