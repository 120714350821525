<!-- Midden -->
<main role="main">
  <section>
    <article>
      <header class="section background-green-hightlight background-transparent text-center background-blue"
        data-parallax="scroll" data-image-src="../../../assets/images/parallax-06.jpg">
        <h1 class="text-white margin-bottom-0 text-size-50 text-thin text-line-height-1 text-s-size-16">
          Aanbiedingen fabrikanten&nbsp;
        </h1>
      </header>
      <div class="section background-white padding">
        <div class="line">
          <!-- Catalogus -->

          <div class="line">
            <p class="icon-home text-blue"><a routerLink="/NL/home"> Home</a></p>
            <h4 class="text-size-16 text-s-size-12 icon-mfg_icon_circle text-red icon-eye">
              <a href="https://view.publitas.com/providental/promo-web-nl-providental" target="_blank">
                <strong><em> Promotie Providental</em></strong>
              </a>
            </h4>
            <hr class="break break-small background-primary" />
            <div class="box margin-bottom">
              <div class="margin">
                <!-- CONTENT -->

                <!-- ASIDE NAV -->
                <div class="s-12 m-8 l-12">
                  <div class="line">
                    <div class="s-12 m-6 l-3 margin-top-30"> <a
                        href="../../../assets/images/Aanbiedingen fabrikanten/Voco Promo 2024 NL vanaf april.pdf"
                        target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/voco afb web nl.png"
                          alt="Voco" width="156" height="220" class="center"></a>
                      <div>
                        <h5 class="text-uppercase text-strong text-center">Voco</h5>
                        <a class="text-more-info text-aqua-hover text-center"
                          href="../../../assets/images/Aanbiedingen fabrikanten/Voco Promo 2024 NL vanaf april.pdf"
                          target="_blank">tot 30/09/2024</a>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-30"> <a
                        href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promotie 2024 NL juli tot sept.pdf"
                        target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/coltene afb web nl.png"
                          alt="coltene" width="157" height="220" class="center"></a>
                      <div>
                        <h5 class="text-uppercase text-strong text-center">Coltene</h5>
                        <p> <a class="text-more-info text-aqua-hover text-center"
                            href="../../../assets/images/Aanbiedingen fabrikanten/Coltene promotie 2024 NL juli tot sept.pdf"
                            target="_blank">tot 30/09/2024</a>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-30"> <a
                        href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 NL.pdf" target="_blank"><img
                          src="../../../assets/images/Aanbiedingen fabrikanten/Durr dental web nl.png" alt="Durr dental"
                          width="157" height="220" class="center"></a>
                      <div>
                        <h5 class="text-uppercase text-strong text-center">Dürr dental</h5>
                        <p> <a class="text-more-info text-aqua-hover text-center"
                            href="../../../assets/images/Aanbiedingen fabrikanten/My Lunos 2024 NL.pdf"
                            target="_blank">Nieuw in assortiment 2024</a>
                      </div>
                    </div>
                    <div class="s-12 m-6 l-3 margin-top-30"> <a
                        href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf"
                        target="_blank"><img src="../../../assets/images/Aanbiedingen fabrikanten/3m afb web engels.png"
                          alt="3M" width="157" height="220" class="center"></a>
                      <div>
                        <h5 class="text-uppercase text-strong text-center">3M</h5>
                        <p> <a class="text-more-info text-aqua-hover text-center"
                            href="../../../assets/images/Aanbiedingen fabrikanten/3M brochure Filtek Easy Match Engels.pdf"
                            target="_blank">Nieuw in assortiment 2024</a>
                      </div>
                    </div>
                  </div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Kleurbalk -->
      <div class="background-dark padding text-center"></div>
    </article>
  </section>
</main>
