import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-hoebestellenFR',
  templateUrl: '../../../assets/html/hoebestellenFR.html',
  styleUrls: ['./hoebestellenFR.component.css']
})
export class HoebestellenFRComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var t = $('[data-parallax="scroll"]').data('imageSrc');
    $('[data-parallax="scroll"]').parallax({imageSrc: t});
  }

}
